import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { Helpers } from './../../Helpers';
import { MessagingService } from './../../messaging.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-single',
  templateUrl: './blog-single.component.html',
  styleUrls: ['./blog-single.component.scss']
})
export class BlogSingleComponent implements OnInit {

  private page_id: number;
  
  public  page   : any;

  constructor(private Activatedroute: ActivatedRoute, public helpers: Helpers, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router, private readonly translateService: TranslateService) { 
    this.Activatedroute.queryParamMap.subscribe(params => {
      this.page_id = Number(params.get('p')) || 0;
      this.loadPage();
    });
  }

  ngOnInit(): void {
  }

  loadPage() {
    this.profileApi.blogDetails({
      page_id: this.page_id
    })
    .subscribe((res) => {
      console.log(res);
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'fail') {
        alert('No Internet');
      }
      else if (res['message'] == 'success') {
        this.page = res['data']['content'] || null;
      }
    });
  }
}
