import { CartService } from './../../models/cart.service';
import { MealDetailsService } from './../../services/meal-details.service';
import { ActivatedRoute } from '@angular/router';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-meal-details',
  templateUrl: './meal-details.component.html',
  styleUrls: ['./meal-details.component.scss']
})

export class MealDetailsComponent implements OnInit {

  faHeart = faHeart;

  public mealObject: any = {};

  public meal: any;

  public note: string;

  public branch_id: number;

  public dish_id: number;

  public quantity: number = 1;

  public options = {};

  public attributes = [];

  constructor(public helpers: Helpers, private Activatedroute: ActivatedRoute, private mealApi: MealDetailsService, private cart: CartService) { }

  ngOnInit(): void {
    this.branch_id = this.Activatedroute.snapshot.params.branch_id;
    this.dish_id   = this.Activatedroute.snapshot.params.id;
    this.loadData();
  }

  favoriteAction(event) {
    if (event['message'] == 'success') {
      if (event['data']['result'] == 'removed_from_favorite_successfully') {
        this.meal['data']['dish']['is_favorite'] = '0';
      }
      else {
        this.meal['data']['dish']['is_favorite'] = '1';
      }
    }
  }

  onValueChangeHandlerFun(attributes) {
    this.attributes = attributes.id ? [attributes] : attributes;
    this.calculateTotal();
  }

  toggleOption(option) {
    if (this.options[ option.id ]) {
      delete this.options[ option.id ];
      return;
    }
    this.options[ option.id ] = option;
    this.calculateTotal();
  }

  increaseQuantity() {
    this.quantity++;
    this.calculateTotal();
  }

  decreseQuantity() {
    if (this.quantity == 1) { return; }
    this.quantity--;
    this.calculateTotal();
  }

  prepareObject() {
    let options = [];
    Object.keys(this.options).forEach((key) => {
      options.push(this.options[ key ]);
    });
    let mealObject = {
      note             : this.note || null,
      restaurant_id    : this.meal.data.dish.restaurant.id,
      restaurant_title : this.meal.data.dish.restaurant.title,
      restaurant_logo  : this.meal.data.dish.restaurant.logo,
      restaurant_rating: this.meal.data.dish.restaurant.rating,

      //Restaurnat Delivery Conditions
      delivery_service : this.meal.data.dish.restaurant.delivery_service,
      delivery_fee     : this.meal.data.dish.restaurant.delivery_fee,
      minimun_order    : Number(Number(this.meal.data.dish.restaurant.minimun_order || 0).toFixed(2)),
      
      //App Delivery Conditions
      app_delivery_service : this.meal.data.dish.restaurant.app_delivery_service,
      app_delivery_fee     :  Number(Number(this.meal.data.dish.restaurant.app_delivery_fee  || 0).toFixed(2)),
      app_minimun_order    : this.meal.data.dish.restaurant.app_minimun_order,

      branch_id        : this.branch_id,
      branch_title     : this.meal.data.dish.branch_title || null,
      dish_id          : this.meal.data.dish.id,
      dish_image       : this.meal.data.dish.image,
      dish_title       : this.meal.data.dish.title,
      dish_type        : this.meal.data.dish.type,
      dish_rating      : this.meal.data.dish.rating,
      dish_rating_count: this.meal.data.dish.rating_count,
      dish_category    : this.meal.data.dish.dish_category,
      currency         : this.meal.data.dish.restaurant.currency,
      single_price     : Number(this.meal.data.dish.cost),
      attributes_price : 0,
      total_price      : 0,
      quantity         : this.quantity,
      options          : options,
      attributes       : this.attributes,
    };
    mealObject.total_price = Helpers.calculateTotalMealPrice(mealObject);
    this.mealObject = mealObject;
  }

  calculateTotal() {
    this.prepareObject();
  }

  private addToFavorite() {
    this.mealApi.addToFavorite({
      branch_id: this.branch_id,
      dish_id  : this.dish_id,
    }).subscribe((data) => {
    });
  }

  private loadData() {
    this.mealApi.loadData({
      branch_id: this.branch_id,
      dish_id  : this.dish_id,
    })
    .subscribe((data) => {
      this.meal = data;
      this.calculateTotal();
    });
  }

  addToCart() {
    this.prepareObject();
    this.cart.add(this.mealObject);
  }
}
