import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { CartService } from './../../models/cart.service';
import { MealDetailsService } from './../../services/meal-details.service';
import { ActivatedRoute } from '@angular/router';
import { Helpers } from './../../Helpers';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-slider-meal-popup',
  templateUrl: './slider-meal-popup.component.html',
  styleUrls: ['./slider-meal-popup.component.scss']
})
export class SliderMealPopupComponent implements OnInit {

  faHeart = faHeart;

  public mealObject : any = {};

  public meal       : any;

  public note       : string;

  public branch_id  : number;

  public dish_id    : number;

  public quantity   : number = 1;

  public cartKey    : any;

  public options    = {};

  public attributes = {};

  @Output() onAddToCart : EventEmitter<any> = new EventEmitter();

  constructor(public helpers: Helpers, private Activatedroute: ActivatedRoute, private mealApi: MealDetailsService, private cart: CartService) { }

  ngOnInit(): void {
    // this.loadData();
  }

  triggerAddedToCart(item) {
    this.onAddToCart.emit(item);
  }

  startLoading(data: any) {
    this.branch_id  = data.branch_id;
    this.dish_id    = data.dish_id;
    this.quantity   = data.quantity   || 1;
    this.options    = data.options    || {};
    this.attributes = data.attributes || [];
    this.cartKey    = data.cartKey == 0 ? 0 : ( data.cartKey ? Number(data.cartKey) : null );
    this.loadData();
  }

  favoriteAction(event) {
    if (event['message'] == 'success') {
      if (event['data']['result'] == 'removed_from_favorite_successfully') {
        this.meal['data']['dish']['is_favorite'] = '0';
      }
      else {
        this.meal['data']['dish']['is_favorite'] = '1';
      }
    }
  }

  onValueChangeHandlerFun(attributes, att_id, att_title) {
    // console.log(attributes, att_id);
    // this.attributes = attributes.id ? [attributes] : (attributes ? attributes : []);
    delete this.attributes[ att_id ];
    if (attributes.id) {
      attributes.base_title     = att_title;
      this.attributes[ att_id ] = [attributes];
    }
    else {
      attributes.forEach(element => {
        element.base_title = att_title;
      });
      this.attributes[ att_id ] = attributes;
    }
    // console.log(this.attributes);
    this.calculateTotal();
  }

  toggleOption(option) {
    if (this.options[ option.id ]) {
      delete this.options[ option.id ];
      return;
    }
    this.options[ option.id ] = option;
    this.calculateTotal();
  }

  increaseQuantity() {
    this.quantity++;
    this.calculateTotal();
  }

  decreseQuantity() {
    if (this.quantity == 1) { return; }
    this.quantity--;
    this.calculateTotal();
  }

  prepareObject() {
    let options = [];
    let attr    = [];
    Object.keys(this.options).forEach((key) => {
      if (this.options[ key ]) {
        options.push(this.options[ key ]);
      }
    });
    Object.keys(this.attributes).forEach((key) => {
      if (this.attributes[ key ]) {
        attr.push(this.attributes[ key ]);
      }
    });
    let mealObject = {
      note             : this.note || null,
      restaurant_id    : this.meal.data.dish.restaurant.id,
      restaurant_title : this.meal.data.dish.restaurant.title,
      restaurant_logo  : this.meal.data.dish.restaurant.logo,
      restaurant_rating: this.meal.data.dish.restaurant.rating,

      //Restaurnat Delivery Conditions
      delivery_service : this.meal.data.dish.restaurant.delivery_service,
      delivery_fee     : this.meal.data.dish.restaurant.delivery_fee,
      minimun_order    : Number(Number(this.meal.data.dish.restaurant.minimun_order || 0).toFixed(2)),
      
      //App Delivery Conditions
      app_delivery_customer_appearing: this.meal.data.dish.restaurant.app_delivery_customer_appearing,
      app_delivery_service : this.meal.data.dish.restaurant.app_delivery_service,
      app_delivery_fee     :  Number(Number(this.meal.data.dish.restaurant.app_delivery_fee  || 0).toFixed(2)),
      app_minimun_order    : this.meal.data.dish.restaurant.app_minimun_order,

      branch_id        : this.branch_id,
      branch_title     : this.meal.data.dish.branch_title || null,
      dish_id          : this.meal.data.dish.id,
      dish_image       : this.meal.data.dish.image,
      dish_title       : this.meal.data.dish.title,
      dish_type        : this.meal.data.dish.type,
      dish_rating      : this.meal.data.dish.rating,
      dish_rating_count: this.meal.data.dish.rating_count,
      dish_category    : this.meal.data.dish.dish_category,
      currency         : this.meal.data.dish.restaurant.currency,
      single_price     : Number(this.meal.data.dish.cost),
      attributes_price : 0,
      total_price      : 0,
      quantity         : this.quantity,
      options          : options.length == 0         ? [] : options,
      // attributes       : this.attributes.length == 0 ? [] : this.attributes,
      attributes       : attr,
    };
    mealObject.total_price = Helpers.calculateTotalMealPrice(mealObject);
    this.mealObject = mealObject;
    // console.log(this.mealObject);
    
  }

  calculateTotal() {
    this.prepareObject();
  }

  private addToFavorite() {
    this.mealApi.addToFavorite({
      branch_id: this.branch_id,
      dish_id  : this.dish_id,
    }).subscribe((data) => {
    });
  }

  private loadData() {
    this.mealApi.loadData({
      branch_id: this.branch_id,
      dish_id  : this.dish_id,
    })
    .subscribe((data) => {
      this.meal = data;
      this.calculateTotal();
    });
  }

  addToCart() {
    this.prepareObject();
    if (this.cartKey == 0 || this.cartKey > 0) {
      this.cart.remove(this.cartKey);
    }
    this.cart.add(this.mealObject);
    this.triggerAddedToCart(this.mealObject);
  }
}
