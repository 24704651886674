import { environment } from './../../environments/environment';
import { Router, UrlSerializer } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResturantService {

  constructor(private http: HttpClient, private router: Router, private serializer: UrlSerializer) { }

  loadData(branch_id?: number, filter?: any) {
    filter = ! filter ? {} : filter;
    filter.branch_id = ! branch_id ? 1 : branch_id;
    let params = this.router.createUrlTree([''], { queryParams: filter });
    let urlString = this.serializer.serialize(params).substring(1);
    return this.http.get( environment.API_BASE_URL + '/restaurants/get_branch' + urlString);
  }

  toggleFavorite(Data) {
    return this.http.post( environment.API_BASE_URL + '/favorites/store', Data);
  }
}
