import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Helpers } from 'src/app/Helpers';
import { CartService } from 'src/app/models/cart.service';
import { UserService } from 'src/app/models/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';
import { CartResponse, Address, Coupon, CartDish } from '../../types/all.types';
import { MatDialog } from '@angular/material/dialog';
import { MealPopupComponent } from 'src/app/includes/meal-popup/meal-popup.component';

@Component({
  selector: 'app-v2-cart',
  templateUrl: './v2-cart.component.html',
  styleUrls: ['./v2-cart.component.scss'],
})
export class V2CartComponent implements OnInit {

  public is_logged_in  : boolean;

  public addresses     : Address[];

  public coupons       : Coupon[];

  public selected_coupon : Coupon | null;

  public notes           : string;

  public _cartResponse : CartResponse;

  public isCartEmpty   : any;

  public isLoading     : any;

  public error         : any;

  public errors        : any = [];

  public address       : Address;

  public success       : any;

  public delivery_type : any = 'DELIVERY';

  public selected_delivery_type : any;

  public currency      : string = environment.currency;

  public payment_method: string = 'CASH';

  protected deleteCoupon: boolean = false;

  @ViewChild(MealPopupComponent) MealPopup: MealPopupComponent;

  constructor(
    public helpers: Helpers, 
    public cart: CartService, 
    private UserServiceStorage: UserService, 
    private profileApi: ProfileService, 
    private router: Router, 
    private readonly translateService: TranslateService,
    private dialog: MatDialog
  ) {
    
  }

  ngOnInit(): void 
  {
    this.is_logged_in = this.UserServiceStorage.isLoggedIn();
    this.translateService.get(['']).subscribe(translations => {
      if (this.is_logged_in) {
        this.address = this.UserServiceStorage.user.address;
        this.loadAddresses();
        this.loadCoupons();
      }
    });
  }

  loggedInSuccessfully(user) {
    this.is_logged_in = true;
    this.loadAddresses();
    this.loadCoupons();
  }

  addressSelected(event) {
    this.address = event;
    this.changeDefaultAddress(this.address);
  }

  changeDefaultAddress(address) {
    this.profileApi.setAddressAsDefault({
      address_id: address.id
    })
    .pipe(take(1))
    .subscribe((res: any) => {
      if (res.message == 'success') {
        address.default = true;
        this.UserServiceStorage.user.address = address;
        this.UserServiceStorage.save(this.UserServiceStorage.user);
      }
    });
  }

  loadCart() : void 
  {
    this.isLoading   = true;
    this.error       = null;
    this.errors      = [];
    let data = {
      delivery_type        : this.delivery_type   || 'DELIVERY',
    };

    if (this.deleteCoupon) {
      data['discount_method_type'] = 'NONE';
      data['discount_method_id']   = null;
      this.deleteCoupon            = false;
    }
    else if (this.selected_coupon) {
      data['discount_method_type'] = 'COUPON';
      data['discount_method_id']   = this.selected_coupon.id;
    }
    
    this.profileApi.getCart(data)
    .pipe(take(1))
    .subscribe((res: any) => {
      this.isLoading     = false;
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res.message == 'success') {
        this.isCartEmpty   = false;
        this._cartResponse = res.data;
        this.delivery_type = this._cartResponse.cart.delivery_type;
        this._cartResponse.restaurant.types.forEach((type) => {
          if (type.key == this.delivery_type) {
            this.selected_delivery_type = type;
          }
        });
        this.selected_coupon = this._cartResponse.coupon;
      }
      else if(res.errors.global == 'the_provided_coupon_is_not_valid') {
        this.isCartEmpty   = false;
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      else {
        this.isCartEmpty   = true;
      }
    });
  }

  private loadCoupons() {
    this.isLoading   = true;
    this.error       = null;
    this.errors      = [];
    this.profileApi.getCartCoupons({
      page: 1
    })
    .pipe(take(1))
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        // res['data']['coupons'].unshift({
        //   id       : 0,
        //   title    : '--------',
        //   suitable : true,
        // });
        this.coupons = res['data']['coupons'];
      }
      else {
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      this.isLoading     = false;
      this.loadCart();
    });
  }

  private loadAddresses() {
    this.profileApi.addressesList({
      page: 1,
      source: 'CART',
    })
    .pipe(take(1))
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        res['data']['addresses'].unshift({
          id  : 0,
          text: '--------'
        });
        this.addresses = res['data']['addresses'];
      }
    });
  }

  removeCartItem(item) {
    this.isLoading   = true;
    this.profileApi.removeItemFromCart({
      cart_dish_id: item.cart_dish_id,
      delete_type : 'single',
    })
    .pipe(take(1))
    .subscribe((res) => {
      this.isLoading   = false;
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        this.selected_coupon = null;
        this.loadCart();
      }
    });
  }

  updateCartItem(item) {

  }

  delivery_type_changed(event) {
    this.delivery_type = event;
    this._cartResponse.restaurant.types.forEach((type) => {
      if (type.key == this.delivery_type) {
        this.selected_delivery_type = type;
      }
    });
    this.loadCart();
  }

  coupon_changed(coupon_id) {
    this.selected_coupon = null;
    this.coupons.forEach((coupon) => {
      if (Number(coupon.id) == Number(coupon_id)) {
        this.selected_coupon = coupon;
      }
    });
    this.loadCart();
  }

  public updatePaymentMethod(method) {
    this.payment_method = method;
  }

  save() {
    this.isLoading   = true;
    this.error       = null;
    this.errors      = [];
    this.profileApi.createOrderV2({
      payment_method: this.payment_method,
      customer_notes: this.notes || null,
    })
    .pipe(take(1))
    .subscribe((res) => {
      this.isLoading   = false;
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        this.router.navigate(['/', this.helpers.localeObject.prefix, 'order-detials'], {queryParams: {
          order_id: res['data']['order_id'],
        }});
      }
      else {
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
    });
  }

  openUpdate(cart_dish: CartDish) {
    this.MealPopup.startLoading({
      item  : cart_dish,
    });
  }

  refreshData(event) {
    this.loadCoupons();
  }

  get dishesCount() {
    let count_ = 0;
    this._cartResponse.cart.dishes.forEach(dish => {
      count_ = count_ + Number(dish.quantity);
    });
    return count_;
  }

  get diliveryFee() {
    let fee_ = 0;
    this._cartResponse.restaurant.types.forEach(deliveryType => {
      if (deliveryType.key == this._cartResponse.cart.delivery_type) {
        fee_ = deliveryType.fee;
      }
    });
    return fee_;
  }

  deleteCouponFromCart() {
    this.deleteCoupon = true;
    this.loadCart();
  }
}
