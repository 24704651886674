import { Injectable } from '@angular/core';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private static totalRequests = 0;

  private static isLoading: boolean = false;

  constructor() { }

  setLoading(status: boolean) {
    // if (status) {
    //   if (LoadingService.totalRequests == 0) {
    //     $('.loader').fadeIn('slow');
    //   }
    //   LoadingService.totalRequests++;
    //   LoadingService.isLoading = true;
    // }
    // else {
      // LoadingService.totalRequests--;
      if (LoadingService.totalRequests == 0) {
        $('.loader').fadeOut('slow');
        window.scrollTo(0, 0);
        LoadingService.isLoading = false;
      }
    // }
    // console.log(LoadingService.totalRequests);
  }
}
