<footer class="footer-area-two pt-100 pb-30">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3 col-6">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a [routerLink]="['/', helpers.localeObject.prefix]"><img src="assets/img/logo-two.png" alt="Logo"></a>
                        <p>{{ 'App Footer Slug' | translate }}</p>
                       
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 col-6">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>{{ 'Services' | translate }}</h3>

                        <ul>
                            <li><a [routerLink]="['/', helpers.localeObject.prefix, 'resturants']"><i class='bx bx-chevron-right'></i> {{ 'Resturants' | translate }}</a></li>
                            <li><a [routerLink]="['/', helpers.localeObject.prefix, 'meals-offer']"><i class='bx bx-chevron-right'></i> {{ 'Special Offers' | translate }}</a></li>
                            <li><a [routerLink]="['/', helpers.localeObject.prefix, 'suggest-resturant']"><i class='bx bx-chevron-right'></i> {{ 'Suggest Resturant' | translate }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-3 col-6">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>{{ 'Quick Links' | translate }}</h3>

                        <ul>
                            <!-- <li><a [routerLink]="['/', helpers.localeObject.prefix]"><i class='bx bx-chevron-right'></i> {{ 'Home' | translate }}</a></li> -->
                            <li><a [routerLink]="['/', helpers.localeObject.prefix, 'page', 'about-us']"><i class='bx bx-chevron-right'></i> {{ 'About Us' | translate }}</a></li>
                            <li><a [routerLink]="['/', helpers.localeObject.prefix, 'page', 'privacy-policy']"><i class='bx bx-chevron-right'></i> {{ 'Privacy Policy' | translate }}</a></li>
                            <li><a [routerLink]="['/', helpers.localeObject.prefix, 'contact-us']"><i class='bx bx-chevron-right'></i> {{ 'Contact Us' | translate }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 col-6">
                <div class="footer-item">
                    <div class="footer-services contact-us">
                        <h3>{{ 'Contact Us' | translate }}</h3>

                        <ul>
                            <li><a href="tel:00908508889806"><i class='bx bx-phone-call'></i> +90 8508 8898 06</a></li>
                            <li><a href="mailto:support@joaan.app"><i class='bx bx-message-detail'></i> support@joaan.app</a></li>
                            <li><i class='bx bx-location-plus'></i>  Büyükdere cad. (Akabe iş merkezi) Likör yanı sk. No:1 K:1 - Şişli / İstanbul </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area copyright-area-two">
    <div class="container">
        <div class="copyright-item">
            <p>
                © {{ year }} {{ 'Copyrights' | translate }} {{ 'App Name' | translate }}
                <br>
                <small>
                    {{ 'Developed By' | translate }} <a href="https://{{ 'Developer Url' | translate }}" target="_blank">{{ 'Developer Name' | translate }}</a>
                </small>
            </p>
        </div>
    </div>
</div>