<div class="blog-item">
    <div class="blog-top">
        <a [routerLink]="['/', helpers.localeObject.prefix, 'blog']" [queryParams]="{ p: blog.id }">
            <img [src]="blog.image" alt="Blog">
        </a>
        <span>{{ blog.created_at }}</span>
    </div>
    <div class="blog-bottom">
        <h3>
            <a [routerLink]="['/', helpers.localeObject.prefix, 'blog']" [queryParams]="{ p: blog.id }">{{ blog.title }}</a>
        </h3>
        <p>
            {{ trim(blog.description, 220) }}
        </p>
        <a class="cmn-btn" [routerLink]="['/', helpers.localeObject.prefix, 'blog']" [queryParams]="{ p: blog.id }">{{ 'More Details' | translate }}</a>
    </div>
</div>