import { MealPopupComponent } from './../../includes/meal-popup/meal-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { CartService } from './../../models/cart.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  @ViewChild(MealPopupComponent) MealPopup: MealPopupComponent;

  public is_logged_in : boolean;

  public addresses    : any[];

  public coupons      : any[] = [];

  public order        : any;

  public error        : any;

  public errors       : any = [];

  public total        : any = 0;

  public address      : any = {id: 0};

  public success      : any;

  constructor(public helpers: Helpers, public cart: CartService, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router, private readonly translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.is_logged_in = this.UserServiceStorage.isLoggedIn();
    this.translateService.get(['']).subscribe(translations => {
      this.refreshData();

      if (this.is_logged_in) {
        this.loadAddresses();
        this.loadCoupons();
      }
    });
  }

  updateCartItem(dish, item, cartKey) {
    this.MealPopup.startLoading({
      branch_id  : item.branch.branch_id,
      dish_id    : dish.dish_id,
      quantity   : dish.quantity    || 1,
      options    : dish.options     || {},
      attributes : dish.side_dishes || {},
      cartKey    : cartKey == 0 ? 0 : ( cartKey ? Number(cartKey) : null ),
    });
  }

  removeCartItem(itemIndex) {
    this.cart.remove(itemIndex);
    this.refreshData();
  }

  refreshData(data = null) {
    let orderItems = this.buildOrderFromCart(this.cart.cart);
    orderItems.forEach((item) => {
      this.calculateItemTotal(item);
    });
    // console.log(orderItems);
    this.order = orderItems;
    // console.log(this.cart.cart);
    // console.log(this.order);
    this.calculateOrderTotal();
  }

  loggedInSuccessfully(user) {
    this.is_logged_in = true;
    this.loadAddresses();
    this.loadCoupons();
  }

  private loadCoupons() {
    this.profileApi.listCoupons({
      page: 1
    })
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        res['data']['coupons'].unshift({
          id: 0,
          text: '--------'
        });
        this.coupons = res['data']['coupons'];
        this.order.forEach((item) => {
          item['coupons'] = Object.assign([], res['data']['coupons']);
        });
      }
    });
  }

  private loadAddresses() {
    this.profileApi.addressesList({
      page: 1
    })
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        res['data']['addresses'].unshift({
          id  : 0,
          text: '--------'
        });
        this.addresses = res['data']['addresses'];
      }
    });
  }

  buildOrderFromCart(collection: any[]) {
    let result = {}, finalResult = [];
    collection.forEach((item, key) => {
      if (! result[ item['branch_id'] ]) {
        result[ item['branch_id'] ]                     = {};
        result[ item['branch_id'] ]['coupons']          = this.coupons;
        result[ item['branch_id'] ]['total_with_all']   = 0;
        result[ item['branch_id'] ]['payment_method']   = 'CASH';
        result[ item['branch_id'] ]['order_type']       = 'DELIVERY';
        result[ item['branch_id'] ]['delivery_type']    = 'RESTAURANT';
        result[ item['branch_id'] ]['dishes']           = result[ item['branch_id'] ]['dishes'] || [];
        result[ item['branch_id'] ]['customer_notes']   = null;
        result[ item['branch_id'] ]['branch']           = { 
          branch_id        : Number(item['branch_id']),
          name             : item['restaurant_title'],
          logo             : item['restaurant_logo'],
          // delivery_fee     : item['delivery_fee'],
          // delivery_service : item['delivery_service'],
          minimun_order    : item['minimun_order'],
          currency         : item['currency'],
        };
        result[ item['branch_id'] ]['discount_method_type'] = null;
        result[ item['branch_id'] ]['discount_method_id']   = null;
        result[ item['branch_id'] ]['discount_amount']      = '0.00';
        result[ item['branch_id'] ]['discount_item']        = null;
        let options = this.loadOptions(item);
        result[ item['branch_id'] ]['delivery_options']     = options.DeliveryOptions;
        result[ item['branch_id'] ]['delivery_option']      = {
          id   : 0,
          text : '--------',
          fee  : 0
        };
        result[ item['branch_id'] ]['payment_options']      = options.PaymentOptions;
        result[ item['branch_id'] ]['payment_option']       = {
          id   : 0,
          text : '--------',
          fee  : 0
        };
      }
      result[ item['branch_id'] ]['dishes'].push({
        cartKey          : key,
        full_data        : item,
        total            : Number(item['total_price']),
        cost             : Number(item['single_price']),
        side_dishes_price: item['attributes_price'],
        dish_id          : item['dish_id'],
        quantity         : item['quantity'],
        options          : this.arrayFromObject(item['options']    || [], 'id'),
        side_dishes      : this.arrayFromObjectOfArray(item['attributes'] || [], 'id'),
      });
      // this.calculateItemTotal(result[ item['branch_id'] ]);
    });
    Object.keys(result).forEach((BranchId) => {
      finalResult.push(result[BranchId]);
    });
    result = {};
    return finalResult;
  }

  loadOptions(orderItem) {
    let 
    DeliveryOptions = [], 
    PaymentOptions  = [];
    DeliveryOptions.push({
      id   : 0,
      text : '--------',
      fee  : 0
    });
    if (orderItem['app_delivery_service'] == '1' && orderItem['app_delivery_customer_appearing'] == '1') {
      DeliveryOptions.push({
        id   : 'APP',
        text : this.translateService.instant('delivery.type.APP'),
        fee  : Number(orderItem.app_delivery_fee).toFixed(2)
      });
    }
    if (orderItem['delivery_service'] == '1') {
      DeliveryOptions.push({
        id   : 'RESTAURANT',
        text : this.translateService.instant('delivery.type.DELIVERY'),
        fee  : Number(orderItem.app_delivery_fee).toFixed(2)
      });
    }
    // DeliveryOptions.push({
    //   id   : 'TAKEAWAY',
    //   text : this.translateService.instant('delivery.type.RESTAURANT'),
    //   fee  : 0,
    // });

    PaymentOptions = [
      {
        id   : 0,
        text : '--------',
        fee  : 0
      },
      {
        id   : 'CASH',
        text : this.translateService.instant('payment.type.CASH'),
        fee  : 0,
      },
      {
        id   : 'BANKCARD',
        text : this.translateService.instant('payment.type.CREDIT_CARD'),
        fee  : 0, //0,
      }
    ];

    return {
      DeliveryOptions: DeliveryOptions,
      PaymentOptions : PaymentOptions,
    };
  }

  arrayFromObject(collection: any[], key) {
    let result = [];
    collection.forEach((item) => {
      if (item) {
        result.push( Number(item[ key ]) );
      }
    });
    return result;
  }

  arrayFromObjectOfArray(collection: any[], key) {
    let result = [];
    collection.forEach((item) => {
      item.forEach((subitem) => {
        if (subitem) {
          result.push( Number(subitem[ key ]) );
        }
      });
    });
    return result;
  }

  addressSelected(event) {
    this.address = event;
  }

  deliverySelected(event, orderItem) {
    orderItem['delivery_option'] = event;
    orderItem['delivery_type']   = event.id;
    this.calculateItemTotal(orderItem);
    this.calculateOrderTotal();
  }

  paymentSelected(event, orderItem) {
    orderItem['payment_option'] = event;
    orderItem['payment_method'] = event.id;
    this.calculateItemTotal(orderItem);
    this.calculateOrderTotal();
  }

  couponSelected(event, orderItem) {
    let couponsAvailable = Object.assign([], this.coupons);
    if (Number(orderItem['discount_item']['id']) != 0) {
      couponsAvailable.splice(couponsAvailable.indexOf(orderItem['discount_item']), 1);

      orderItem['discount_method_type'] = 'COUPON';
      orderItem['discount_method_id']   = orderItem['discount_item']['id'];
    }
    else {
      orderItem['discount_method_type'] = null;
      orderItem['discount_method_id']   = null;
    }
    this.order.forEach((item) => {
      if (Number(item.branch.branch_id) != Number(orderItem.branch.branch_id)) {
        item['coupons'] = couponsAvailable;
      }
    });
    this.calculateItemTotal(orderItem);
    this.calculateOrderTotal();
  }

  calculateItemTotal(orderItem) {
    let totalWithAll = 0;
    orderItem.dishes.forEach((Dish) => {
      totalWithAll = totalWithAll + Number(Dish['total']);
    });

    let discountAmount = 0;
    if (orderItem['discount_item']) {
      if (orderItem['discount_item']['id'] != 0) {
        if (orderItem['discount_item']['type'] == 'GLOBAL') {
          if (orderItem['discount_item']['discount_type'] == 'PERCENTAGE') {
            discountAmount = Number((totalWithAll / 100) * Number(orderItem['discount_item']['discount']));
          }
          else if(orderItem['discount_item']['discount_type'] == 'AMOUNT') {
            discountAmount = Number(orderItem['discount_item']['discount']);
          }
        }
        else {
          orderItem['dishes'].forEach((dish) => {
            let dishDiscount = 0;
            if (this.isDiscountSuported(orderItem['discount_item'], dish) == 'OK') {
              if (orderItem['discount_item']['discount_type'] == 'PERCENTAGE') {
                dishDiscount = Number((dish['total'] / 100) * Number(orderItem['discount_item']['discount']));
              }
              else if(orderItem['discount_item']['discount_type'] == 'AMOUNT') {
                dishDiscount = Number(orderItem['discount_item']['discount']);
              }
              dish['discount_amount'] = Number(dishDiscount);
              discountAmount = discountAmount + dish['discount_amount'];
            }
          });
        }
      }
    }
    orderItem['discount_amount']  = discountAmount;
    totalWithAll = totalWithAll + Number(orderItem['delivery_option']['fee']);
    totalWithAll = totalWithAll + Number(orderItem['payment_option']['fee']);
    
    orderItem['total_with_all']   = Number(totalWithAll - orderItem['discount_amount']) < 0 ? 0 : Number(totalWithAll - orderItem['discount_amount']);
  }

  isDiscountSuported(coupon, ItemDish) {
    if (coupon['type'] == 'GLOBAL') {
      return 'OK';
    }
    else if(coupon['type'] == 'SPECIFIED_DISHES_TO_EARN') {
      let check = 'NOT_SUPPORTED';
      coupon['dishes'].forEach((dish) => {
        if (Number(dish['id']) == Number(ItemDish['dish_id'])) {
          check = 'OK';
          return false;
        }
      });
      return check;
    }
  }

  calculateOrderTotal() {
    let total = 0;
    this.order.forEach(orderItem => {
      total = total + Number(orderItem['total_with_all']);
    });
    this.total = total;
  }

  save() {
    this.error       = null;
    this.errors      = [];
    // let order = Object.assign([], this.order);
    // order.forEach((item) => {
    //   delete item.coupons;
    //   delete item.delivery_options;
    //   delete item.payment_options;
    //   delete item.discount_item;
    //   item['dishes'].forEach((dish) => {
    //     delete dish.full_data;
    //   });
    // });
    let data  = {
      basket        : JSON.stringify(this.order),
      address_id    : this.address['id'],
      scheduled_date: null,
    };
    this.profileApi.createOrder(data)
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        console.log(res);
        // this.success = res['data']['orders'];
        this.router.navigate(['/', this.helpers.localeObject.prefix, 'order-detials'], {queryParams: {
          order_id: res['data']['orders'][0]['id'],
        }});
      }
      else {
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
    });
  }
}
