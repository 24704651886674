<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">
    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Account Settings' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Account Settings' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div class="book-table-area pt-70 pb-70">
                    <div class="container">
                        <div class="book-table-wrap">
                            <div class="section-title" style="margin-bottom: 20px;">
                                <h2 class="text-warning">{{ 'Account Settings' | translate }}</h2>
                                <p>{{ 'Enter Your Personal Details' | translate }}</p>
                                <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
                                <app-alert [type]="'success'" [title]="success"></app-alert>
                            </div>
                            <form (ngSubmit)="updateProfileData()">
                                <div class="row" dir="ltr" *ngIf="user">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Full Name' | translate }}" name="full_name" [(ngModel)]="full_name">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Email' | translate }}" name="email" [(ngModel)]="email">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Phone Number' | translate }}" name="phone_number" [(ngModel)]="phone_number" disabled="">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <input type="password" class="form-control" placeholder="{{ 'Old Password' | translate }}" name="old_password" [(ngModel)]="old_password" autocomplete="false">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <input type="password" class="form-control" placeholder="{{ 'New Password' | translate }}" name="new_password" [(ngModel)]="new_password" autocomplete="false">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <input type="password" class="form-control" placeholder="{{ 'New Password Confirmation' | translate }}" name="new_password_confirmation" [(ngModel)]="new_password_confirmation" autocomplete="false">
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <button type="submit" class="btn btn-warning btn-block">
                                                <strong>{{ 'Save' | translate }}</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
    

    <app-footer></app-footer>
</div>
