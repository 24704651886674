<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Coupons' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Coupons' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>  

    <section class="cart-area pb-70 pt-70">
        <div class="container">

            <div class="row" style="margin-bottom: 20px;">
                <div class="col-md-12">
                    <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
                    <app-alert [type]="'success'" [title]="success"></app-alert>
                    <form (ngSubmit)="reserveCoupon()">
                        <div class="row">
                            <div class="col-lg-9 col-md-8 col-sm-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="{{ 'Coupon Code' | translate }}" name="name" [(ngModel)]="coupon_code" required="">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-12 text-center">
                                <button type="submit" class="btn btn-outline-warning btn-block btn-md">
                                    <strong>{{ 'Reserve Coupon' | translate }}</strong>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            
            <div class="cart-wrap addresses">
                <table class="table">
                    <thead class="thead">
                        <tr>
                            <th class="table-head" scope="col">{{ 'Title' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Coupon Code' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'disscount' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'max_allowed_discount' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'status' | translate }}</th>
                        </tr>
                    </thead>
    
                    <tbody *ngIf="coupons">
                        <tr *ngFor="let item of coupons;index as itemIndex;">
                            <td><strong>{{ item.title }}</strong></td>
                            <td><strong class="text-danger" dir="ltr">{{ item.coupon_code }}</strong></td>
                            <td><strong dir="ltr">{{ item.discount }} {{ 'discount_type.' + item.discount_type | translate }}</strong></td>
                            <td><strong>{{ item.max_allowed_discount || '-------' }}</strong></td>
                            <td [ngClass]="{'text-success': item.use_stage == 'AVAILABLE', 'text-danger': item.use_stage != 'AVAILABLE'}">
                                <strong>{{ 'use_stage.' + item.use_stage | translate }}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>  
            </div>
        </div>
    </section>

    <app-footer></app-footer>

</div>