import { environment } from './../../environments/environment.prod';
import { Router, UrlSerializer } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseDataService {

  constructor(private http: HttpClient, private router: Router, private serializer: UrlSerializer) { }

  private buildUrlString(data?: any) {
    data           = ! data      ? {} : data;
    data.page      = ! data.page ? 1  : data.page;

    let params = this.router.createUrlTree([''], { queryParams: data });
    return this.serializer.serialize(params).substring(1);
  }

  loadCountries(data?: any) {
    return this.http.get( environment.API_BASE_URL + '/get_countries' + this.buildUrlString(data));
  }

  loadCountryCities(data?: any) {
    return this.http.get( environment.API_BASE_URL + '/get_country_cities' + this.buildUrlString(data));
  }

  loadCityMunicipalities(data?: any) {
    return this.http.get( environment.API_BASE_URL + '/get_city_municipalities' + this.buildUrlString(data));
  }

  loadMunicipalityNeighborhoods(data?: any) {
    return this.http.get( environment.API_BASE_URL + '/get_municipality_neighborhoods' + this.buildUrlString(data));
  }
}
