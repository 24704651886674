import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { ProfileService } from './../../services/profile.service';
import { GoogleMapComponent } from './../../includes/google-map/google-map.component';
import { UserService } from './../../models/user.service';
import { DishesService } from './../../services/dishes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ResturantService } from './../../services/resturant.service';
import { IndexService } from './../../services/index.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-resturant-details',
  templateUrl: './resturant-details.component.html',
  styleUrls: ['./resturant-details.component.scss']
})
export class ResturantDetailsComponent implements OnInit {

  @ViewChild(GoogleMapComponent) ChildMap: GoogleMapComponent;

  faHeart = faHeart;

  public tab             : string;

  public resturant_id    : number;

  public resturant       : any;

  public resturant_dishes: any;
  
  public index           : any;

  public keyword         : string = '';

  public category_id     : any = {id: 0, text: '--------'};

  public city_id         : any = {id: 0, text: '--------'};

  public page            : number  = 1;

  public order_page      : number  = 1;

  public has_more        : string  = '0';

  public has_more_orders : string  = '0';

  public is_favorite     : string  = '0';

  public isLoggedIn      : boolean = false;

  public user            : any;

  public orders          : any[];

  public dishes_categories : any[] = [];

  public show_only_by_category: any = 'all';

  public dishes_by_categories : any = {};

  constructor(private profileApi: ProfileService, public helpers: Helpers, private resturantApi: ResturantService, private dishesApi: DishesService, private indexApi: IndexService, private activatedRoute: ActivatedRoute, private UserServiceStorage: UserService) {
    this.resturant_id = this.activatedRoute.snapshot.params.id;

    if (this.UserServiceStorage.isLoggedIn()) {
      this.isLoggedIn = this.UserServiceStorage.isLoggedIn();
      this.user = this.UserServiceStorage.user;
    }
  }

  ngOnInit(): void {
    this.loadIndex();
    this.loadRestaurant();
    this.openMeals();
  }

  toggleFavorite() {
    this.resturantApi.toggleFavorite({
      entity_type: 'BRANCH',
      entity_id  : this.resturant_id,
    })
    .subscribe((data) => {
      if (data['message'] == 'success') {
        if (data['data']['result'] == 'removed_from_favorite_successfully') {
          this.is_favorite = '0';
        }
        else {
          this.is_favorite = '1';
        }
      }
    });
  }

  openMeals() {
    this.tab = 'MEALS';
    this.loadRestaurantDishes();
  }

  openMyOrders() {
    this.tab = 'MY_ORDERS';
    this.loadRestaurantMyOrders();
  }

  openAbout() {
    this.tab = 'ABOUT';
    this.loadRestaurantAbout();
  }

  keywordSearch() {
    this.loadRestaurantDishes();
  }
  
  search() {
    this.loadRestaurantDishes();
  }

  reset() {
    this.category_id = {id: 0, text: '--------'};
    this.city_id     = {id: 0, text: '--------'};
    this.keyword     = '';
    this.page        = 1;
    this.loadRestaurantDishes();
  }

  nextPage() {
    this.page++;
    this.loadRestaurantDishes();
  }

  previousPage() {
    this.page--;
    this.loadRestaurantDishes();
  }

  nextOrderPage() {
    this.order_page++;
    this.loadRestaurantMyOrders();
  }

  previousOrderPage() {
    this.order_page--;
    this.loadRestaurantMyOrders();
  }

  markerControll(event) {
    this.ChildMap.refreshMarker(this.resturant.data.branch.latitude, this.resturant.data.branch.longitude);
  }

  loadIndex() {
    this.indexApi.loadData().subscribe((data) => { 
      data['data']['categories'].unshift({
        id: 0,
        text: '--------'
      });
      data['data']['cities'].unshift({
        id: 0,
        text: '--------'
      });
      this.index = data;
    });
  }

  loadRestaurant() {
    let self = this;
    this.resturantApi.loadData(this.resturant_id).subscribe((data) => {
      this.resturant = data;
      // console.log(this.resturant);
      this.is_favorite = data['data']['branch']['favorite'];
      Helpers.make('GoogleMaps', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCiBmR5kDnIiGGfDp6CHfIcnLsCSdu8w2c', {
        success: function() {
          // self.ChildMap.addMarker(self.resturant.data.branch.latitude, self.resturant.data.branch.longitude);
        }
      });
    });
  }

  filterByCategory(cat) {
    this.show_only_by_category = cat;
  }

  loadRestaurantDishes() {
    this.dishesApi.loadData({
      page        : this.page,
      branch_id   : this.resturant_id,
      search      : this.keyword         || null,
      category_id : [this.category_id.id || 0],
    })
    .subscribe((data) => {
      this.resturant_dishes = data;
      this.has_more         = data['data']['more_available'];
      this.loadDishesCategories();
      this.buildDishesByCategories();
    });
  }
  buildDishesByCategories() {
    this.dishes_by_categories
    this.resturant_dishes['data']['dishes'].forEach(dish => {
      if (! this.dishes_by_categories[dish.dish_category]) {
        this.dishes_by_categories[dish.dish_category] = [];
      }
      this.dishes_by_categories[dish.dish_category].push(dish);
    });
    console.log(this.dishes_by_categories);
  }
  loadDishesCategories() {
    this.dishes_categories
    this.resturant_dishes['data']['dishes'].forEach(dish => {
      let Exists = false;
      this.dishes_categories.forEach(category => {
        if (category == dish.dish_category) { Exists = true; }
      });
      if (! Exists) {
        this.dishes_categories.push(dish.dish_category);
      }
    });
  }
  loadRestaurantAbout() {

  }
  loadRestaurantMyOrders() {
    let params = {
      page      : this.order_page,
      branch_id : this.resturant_id,
    };
    this.profileApi.listOrders(params).subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'fail') {
        alert('No Internet');
      }
      else if (res['message'] == 'success') {
        this.orders   = res['data']['orders'];
        this.has_more_orders = res['data']['more_available'];
      }
    });
  }
}
