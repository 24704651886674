import { Injectable } from '@angular/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private data: any;

  public itemsCount: number = 0;

  @LocalStorage('cart') cart;

  constructor(private storage: LocalStorageService) {
    if (! this.cart) {
      this.cart = [];
    }
    this.data = new Subject<any>();

    this.data.subscribe((cart) => {
      this.itemsCount = cart.length
    });

    this.updated();
  }

  get dataStorage() {
    return this.data;
  }

  add(data: any) {
    let item   = null;
    let _alert = false;
    this.cart.forEach((cartItem) => {
      if (data.branch_id != cartItem.branch_id && !_alert) {
        alert('لايمكن اضافة طلب من مطاعم مختلفة يرجى اتمام الطلب الموجود بالسلة او الغاءه لاتمام هذه العملية');
        _alert = true;
        return false;
      }
      else if (cartItem.dish_id == data.dish_id) {
        item = cartItem;
        cartItem.quantity = data.quantity;
        return false;
      }
    });
    if (! item && !_alert) {
      this.cart.push( data );
    }
    this.updated();
  }

  empty() {
    this.cart = [];
    this.updated();
  }

  remove(itemIndex) {
    this.cart.splice(itemIndex, 1);
    this.updated();
  }

  updated() {
    this.cart = this.cart;
    this.data.next( this.cart );
  }

  total() {
    var total = 0;
      for ( var i = 0, _len = this.cart.length; i < _len; i++ ) {
        total += Number(Number(this.cart[i]['total_price']));
      }
      return total
  }
}
