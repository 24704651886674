// export const environment = {
//   production: false,
//   API_BASE_URL: 'https://dev.tr.hungry-app.com/api',
//   // API_BASE_URL: 'https://tr.hungry-app.com/api',

//   FACEBOOK  : null,
//   TWITTER   : null,
//   INSTAGRAM : null,
//   YOUTUBE   : null,

//   currency: 'TL',

//   firebase : {
//     apiKey: "AIzaSyAXfG7MBAHJ3jxEREVuOQDEXATwHA2GFTc",
//     authDomain: "tip-top-afe01.firebaseapp.com",
//     databaseURL: "https://tip-top-afe01.firebaseio.com",
//     projectId: "tip-top-afe01",
//     storageBucket: "tip-top-afe01.appspot.com",
//     messagingSenderId: "621775670165",
//     appId: "1:621775670165:web:43b51f51a870ef65e0049c",
//     // measurementId: "G-TXCQQD2B5E"
//   },
// };

export const environment = {
  production: false,
  API_BASE_URL: 'https://tr.hungry-app.com/api',
  // API_BASE_URL: 'https://tr.hungry-app.com/api',

  FACEBOOK  : 'https://google.com',
  TWITTER   : null,
  INSTAGRAM : null,
  YOUTUBE   : null,

  currency: 'TL',

  firebase : {
    apiKey: "AIzaSyDdiDkfnZ16DWdZFPUNkyz6Ut5lN6wGEh0",
    authDomain: "jo3aan-c3733.firebaseapp.com",
    databaseURL: "https://jo3aan-c3733.firebaseio.com",
    projectId: "jo3aan-c3733",
    storageBucket: "jo3aan-c3733.appspot.com",
    messagingSenderId: "388971019517",
    appId: "1:388971019517:web:db6ff87785b68e69ca5294",
    measurementId: "G-TG2HXXQ77V"
  },
};
