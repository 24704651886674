<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">
    <app-navbar></app-navbar>
    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Resturants' | translate }}</h2>
                <ul>
                    <li><a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a></li>
                    <li><i class='bx bx-chevron-right' ></i></li>
                    <li>{{ 'Resturants' | translate }}</li>
                </ul>
            </div>
        </div>
    </div>

    

    <div class="services-details-area pb-70">
        <div class="container">
            <div class="row">
                <div class="col-md-12" *ngIf="!hasLatLng">
                    <app-location-required></app-location-required>
                </div>
                <div class="col-md-12" *ngIf="hasLatLng">
                    <div class="sorting-menu">
                        <ul > 
                            <li class="filter" (click)="toggleTakeaway()" [ngClass]="{'active': listType == 'DELIVERY'}">
                               {{ 'Delivery To Address' | translate }}
                            </li>
                            <li class="filter" (click)="toggleTakeaway()" [ngClass]="{'active': listType == 'TAKEAWAY'}">
                                {{ 'Take From Restaurant' | translate }}
                            </li>
                        </ul>
                    </div>  
                </div>
                <div class="col-lg-12" *ngIf="hasLatLng">
                    <div class="row" *ngIf="resturants">
                        <div *ngFor="let resturant of resturants.data.restaurants" class="col-lg-6">
                            <app-resturant-card [restaurant]="resturant"></app-resturant-card>
                        </div>
                        <div class="col-lg-12" *ngIf="resturants.data.restaurants.length == 0">
                            <h3 class="text-center">{{ 'No Results' | translate }}</h3>
                        </div>
                    </div>
                    
                    <div class="collection-area" *ngIf="resturants">
                        <div class="more-collection">
                            <a href="javascript:void(0)" (click)="previousPage()" *ngIf="page > 1" class="text-danger">{{ 'Previous' | translate }}</a>

                            <a href="javascript:void(0)" (click)="nextPage()" *ngIf="has_more == '1'">{{ 'Load More' | translate }}</a>
                        </div>
                    </div>

                    <mat-progress-spinner *ngIf="!resturants" mode="indeterminate" style="margin: auto;"></mat-progress-spinner>

                </div>
                <!-- <div class="col-md-3">
                    <div class="header-filter">
                        <div class="header-filter-item">
                            <div class="header-filter-body">
                                <div class="form-group search">
                                    <input type="text" placeholder="{{ 'Keyword' | translate }}" class="form-control" (change)="keyword=$event.target.value">
                                    <button type="button" class="btn search-btn" (click)="keywordSearch()"><i class='bx bxs-search'></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-filter">
                        <div class="header-filter-item">
                            <div class="header-filter-body">
                                <button class="btn btn-block" [ngClass]="{'btn-outline-warning': !takeaway, 'btn-warning': takeaway}" (click)="toggleTakeaway()">
                                    {{ 'takeaway' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="header-filter">
                        <div class="header-filter-item">
                            <div class="header-filter-body">
                                <div class="form-group search">
                                    <div class="faq-area">
                                        <div class="faq-item">
                                            <ul class="accordion">
                                                <li>
                                                    <a>{{ 'Sort By' | translate }}</a>
                                                    <p>
                                                        <span>
                                                            <div class="form-group search" (change)="sort_by=$event.target.value">
                                                                <select name="sort_by" style="width: 100%;">
                                                                    <option value="">------</option>
                                                                    <option value="rating">{{ 'Ratings' | translate }}</option>
                                                                    <option value="delivery_time">{{ 'Delivery Time' | translate }}</option>
                                                                    <option value="delivery_fee">{{ 'Delivery Fee' | translate }}</option>
                                                                    <option value="minimun_order">{{ 'Min Order' | translate }}</option>
                                                                </select>
                                                            </div> 
                                                        </span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-filter">
                        <div class="header-filter-item">
                            <div class="header-filter-body">
                                <div class="form-group search">
                                    <div class="faq-area">
                                        <div class="faq-item">
                                            <ul class="accordion">
                                                <li>
                                                    <a>{{ 'Categories' | translate }}</a>
                                                    <p>
                                                        <span>
                                                            <div class="form-group search" *ngIf="index">
                                                                <app-select2-transformer [data]="index.data.categories" [options]="{}" [disabled]="false" (onValueChangeHandler)="onValueChangeHandlerFun($event)"></app-select2-transformer>
                                                            </div> 
                                                        </span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-filter">
                        <div class="header-filter-item">
                            <div class="header-filter-body">
                                <div class="form-group search">
                                    <div class="faq-area">
                                        <div class="faq-item">
                                            <ul class="accordion">
                                                <li>
                                                    <a>{{ 'Delivery Time' | translate }} <small>({{ 'Minutes' | translate }})</small></a>
                                                    <p>
                                                        <span>
                                                            <div class="form-group search">
                                                                <input type="text" placeholder="{{ 'From' | translate }}" class="form-control" (change)="delivery_time_from=$event.target.value">
                                                            </div> 
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <span>
                                                            <div class="form-group search">
                                                                <input type="text" placeholder="{{ 'To' | translate }}" class="form-control" (change)="delivery_time_to=$event.target.value">
                                                            </div> 
                                                        </span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-filter">
                        <div class="header-filter-item">
                            <div class="header-filter-body">
                                <div class="form-group search">
                                    <div class="faq-area">
                                        <div class="faq-item">
                                            <ul class="accordion">
                                                <li>
                                                    <a>{{ 'Min Order' | translate }}</a>
                                                    <p>
                                                        <span>
                                                            <div class="form-group search">
                                                                <input type="text" placeholder="{{ 'From' | translate }}" class="form-control" (change)="min_order_from=$event.target.value">
                                                            </div> 
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <span>
                                                            <div class="form-group search">
                                                                <input type="text" placeholder="{{ 'To' | translate }}" class="form-control" (change)="min_order_to=$event.target.value">
                                                            </div> 
                                                        </span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-filter">
                        <div class="header-filter-item">
                            <div class="header-filter-body">
                                <div class="form-group search">
                                    <div class="faq-area">
                                        <div class="faq-item">
                                            <ul class="accordion">
                                                <li>
                                                    <a>{{ 'Ratings' | translate }}</a>
                                                    <p>
                                                        <span>
                                                            <div class="form-group search" (change)="ratings=$event.target.value">
                                                                <select name="ratings" style="width: 100%;">
                                                                    <option value="">------</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </select>
                                                            </div> 
                                                        </span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-sm btn-block btn-outline-success" (click)="search()">{{ 'Search' | translate }}</button>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-sm btn-block btn-outline-danger" (click)="reset()">{{ 'Reset' | translate }}</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <app-footer></app-footer>

</div>