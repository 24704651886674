import { Subject } from 'rxjs';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private data: any;

  @LocalStorage('user') user;

  constructor(private storage: LocalStorageService) { 
    if (! this.user) {
      this.user = {};
    }
    this.data = new Subject<any>();

    // this.data.subscribe((user) => {
    //   console.log(user);
    // });
    this.updated();
  }

  save(user) {
    this.user = user;
    this.updated();
  }

  logout() {
    this.user = {};
    localStorage.removeItem('user');
    this.updated();
  }

  updated() {
    this.user = this.user;
    localStorage.setItem('user', JSON.stringify(this.user));
    this.data.next( this.user );
  }

  token() {
    return this.user.token ? this.user.token : null;
  }

  isLoggedIn() {
    return this.user.token ? true : false;
  }

  UserData() {
    return this.data;
  }

  setDeliveryType(type: 'DELIVERY' | 'TAKEAWAY' = 'DELIVERY') {
    localStorage.setItem('DELIVERY_TYPE', type);
  }

  getDeliveryType() {
    return localStorage.getItem('DELIVERY_TYPE') || 'DELIVERY';
  }
}
