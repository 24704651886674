import { environment } from './../../../environments/environment';
import { UserService } from './../../models/user.service';
import { ProfileService } from './../../services/profile.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public socialProfiles : any = {};

  public contactData    : any = {locale: 'ar'};

  public isLoggedIn     : boolean;

  public error          : any;

  public errors         : any = [];

  public success        : any;

  constructor(public helpers: Helpers, private profileApi: ProfileService, private UserServiceStorage: UserService) { 
    this.isLoggedIn = this.UserServiceStorage.isLoggedIn();
    this.socialProfiles = {
      FACEBOOK  : environment.FACEBOOK,
      TWITTER   : environment.TWITTER,
      INSTAGRAM : environment.INSTAGRAM,
      YOUTUBE   : environment.YOUTUBE,
    };

    if (this.isLoggedIn) {
      this.contactData['name']         = UserServiceStorage.user.name;
      this.contactData['email']        = UserServiceStorage.user.email;
      this.contactData['phone_number'] = UserServiceStorage.user.phone_number;
    }
  }

  ngOnInit(): void {
  }

  sendContactRequest() {
    this.success = null;
    this.error   = null;
    this.errors  = [];
    if (this.isLoggedIn) {
      this.profileApi.sendContactFromLoggedUser(this.contactData).subscribe((res) => {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        if (res['message'] == 'success') {
          this.success = res['data']['result'];
        }
        else {
          this.error  = ParsedResponse['global'];
          this.errors = ParsedResponse['validations'];
          console.log(this.errors);
        }
      });
    }
    else {
      this.profileApi.sendContactFromGuest(this.contactData).subscribe((res) => {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        if (res['message'] == 'success') {
          this.success = res['data']['result'];
        }
        else {
          this.error  = ParsedResponse['global'];
          this.errors = ParsedResponse['validations'];
          console.log(this.errors);
        }
      });
    }
  }
}
