<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

  

    <div class="resturant-section"  *ngIf="resturant">
        <div class="container">
            <div class="row">
                <div class="col-md-12" *ngIf="resturant.data.branch.open == 0">
                    <div class="rest_close2">
                        <div class="row">
                            <div class="col-md-10 ">
                                <h3>{{ 'restaurant_close' | translate }}</h3>
                                <p>{{ 'restaurant_close_det' | translate }}</p>
                            </div>
                            <div class="col-md-2" style="text-align: left;">
                                <span class="view_rest" (click)="openAbout()">{{ 'View' | translate }}</span>
                            </div>
                        </div>
                        

                       
                    </div>
                </div>

                <div class="col-md-12 ">
                    <div class="rest_card clearfix">
                        <div class="row">
                            <div  class="col-sm-12 col-lg-1" style="    padding-left: 0;">
                                <img [src]="resturant.data.branch.logo" class="img-thumbnail2 img-profile3">
                            </div>
                            <div  class="col-sm-12 col-lg-10">
                                <h6 class="rest_name">
                                    <a (click)="openAbout()">{{resturant.data.branch.title}}</a>
                                    <p>{{resturant.data.branch.description}}</p>
                                </h6>
                                <span class="dish_min_time"><span class="red_color">{{ 'Min Order2' | translate }} {{ resturant.data.branch.minimun_order }} </span> . {{ resturant.data.branch.delivery_time }} {{ 'time_min' | translate }} </span>
                            </div>
                            <div  class="col-sm-12 col-lg-1" style="padding-top: 5px;">
                                <app-rating [rating]="resturant.data.branch.rating"></app-rating>
                                <button class="btn btn-default text-success rest_open" *ngIf="resturant.data.branch.open == 1">{{ 'Opened' | translate }}</button>
                                <button class="btn btn-default text-danger rest_close" *ngIf="resturant.data.branch.open != 1"> {{ 'Closed' | translate }}</button>
                            </div>
                        </div>                             
                    </div>
                </div>
            </div>
        </div>
    </div>


    <section class="collection-area pb-70">
        <div class="container">

    
            <div id="Container" class="row" *ngIf="tab == 'ABOUT' && resturant">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <table class="about-resturant-table">
                        <tr>
                            <th width="50%">
                                <div>{{ 'Minimum Order Amount' | translate }}</div>
                            </th>
                            <th width="50%">
                                <div>{{ helpers.number(resturant.data.branch.minimun_order).toFixed(2) }} {{ resturant.data.branch.currency }}</div>
                            </th>
                        </tr>
                        <tr>
                            <th width="50%">
                                <div>{{ 'Delivery Time' | translate }}</div>
                            </th>
                            <th width="50%">
                                <div>{{ resturant.data.branch.delivery_time }} {{ 'Minutes' | translate }}</div>
                            </th>
                        </tr>
                        <tr>
                            <th width="50%">
                                <div>{{ 'Delivery Fee' | translate }}</div>
                            </th>
                            <th width="50%">
                                <div *ngIf="resturant.data.branch.app_delivery_customer_appearing && resturant.data.branch.app_delivery_service">{{ helpers.number(resturant.data.branch.app_delivery_fee).toFixed(2) }} {{ resturant.data.branch.currency }}</div>
                                <div *ngIf="!resturant.data.branch.app_delivery_customer_appearing && resturant.data.branch.delivery_service">{{ helpers.number(resturant.data.branch.delivery_fee).toFixed(2) }} {{ resturant.data.branch.currency }}</div>
                            </th>
                        </tr>
                    </table>
                    <h4 class="address-title">{{ 'Adress' | translate }}</h4>
                    <table class="address-resturant-table" style="width: 100%;">
                        <tr>
                            <th width="50%">
                                <div>
                                    {{ resturant.data.branch.address }}
                                </div>
                            </th>
                            <th width="50%">
                                <div>
                                    <!-- <img src="assets/img/logo.png" alt=""> -->
                                    <app-google-map
                                        [marginBottom]="0"
                                        [elemId]="'restaurant_address_map_div'" 
                                        [zoom]="14" 
                                        [lat]="resturant.data.branch.latitude" 
                                        [lng]="resturant.data.branch.longitude" 
                                        [DefaultMarkerLat]="resturant.data.branch.latitude" 
                                        [DefaultMarkerLng]="resturant.data.branch.longitude" 
                                        (onAddMarker)="markerControll($event)">
                                    </app-google-map>
                                </div>
                            </th>
                        </tr>
                    </table>
                    <h4 class="hours-title">{{ 'Working Houres' | translate }}</h4>
                    <table class="hours-resturant-table">
                        <tr>
                            <th width="15%">
                                <div>{{ 'days.SAT' | translate }}</div>
                            </th>
                            <th width="70%">
                                <div class="resturant-border"> </div>
                            </th>
                            <th width="15%">
                                <div>{{ resturant.data.branch.sat_opening_time || '----' }} -> {{ resturant.data.branch.sat_closing_time || '----' }}</div>
                            </th>
                        </tr>
                        <tr>
                            <th width="15%">
                                <div>{{ 'days.SUN' | translate }}</div>
                            </th>
                            <th width="70%">
                                <div class="resturant-border"> </div>
                            </th>
                            <th width="15%">
                                <div>{{ resturant.data.branch.sun_opening_time || '----' }} -> {{ resturant.data.branch.sun_closing_time || '----' }}</div>
                            </th>
                        </tr>
                        <tr>
                            <th width="15%">
                                <div>{{ 'days.MON' | translate }}</div>
                            </th>
                            <th width="70%">
                                <div class="resturant-border"> </div>
                            </th>
                            <th width="15%">
                                <div>{{ resturant.data.branch.mon_opening_time || '----' }} -> {{ resturant.data.branch.mon_closing_time || '----' }}</div>
                            </th>
                        </tr>
                        <tr>
                            <th width="15%">
                                <div>{{ 'days.TUE' | translate }}</div>
                            </th>
                            <th width="70%">
                                <div class="resturant-border"> </div>
                            </th>
                            <th width="15%">
                                <div>{{ resturant.data.branch.tue_opening_time || '----' }} -> {{ resturant.data.branch.tue_closing_time || '----' }}</div>
                            </th>
                        </tr>
                        <tr>
                            <th width="15%">
                                <div>{{ 'days.WED' | translate }}</div>
                            </th>
                            <th width="70%">
                                <div class="resturant-border"> </div>
                            </th>
                            <th width="15%">
                                <div>{{ resturant.data.branch.wed_opening_time || '----' }} -> {{ resturant.data.branch.wed_closing_time || '----' }}</div>
                            </th>
                        </tr>
                        <tr>
                            <th width="15%">
                                <div>{{ 'days.THU' | translate }}</div>
                            </th>
                            <th width="70%">
                                <div class="resturant-border"> </div>
                            </th>
                            <th width="15%">
                                <div>{{ resturant.data.branch.thu_opening_time || '----' }} -> {{ resturant.data.branch.thu_closing_time || '----' }}</div>
                            </th>
                        </tr>
                        <tr>
                            <th width="15%">
                                <div>{{ 'days.FRI' | translate }}</div>
                            </th>
                            <th width="70%">
                                <div class="resturant-border"> </div>
                            </th>
                            <th width="15%">
                                <div>{{ resturant.data.branch.fri_opening_time || '----' }} -> {{ resturant.data.branch.fri_closing_time || '----' }}</div>
                            </th>
                        </tr>
                    </table>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div id="Container" class="row" *ngIf="tab == 'MEALS' && resturant_dishes">
                <div class="col-md-12" *ngIf="index && resturant_dishes.data.dishes.length > 0">
                    <div class="sorting-menu ">
                        <ul > 
                            <li class="filter" [ngClass]="{'active': show_only_by_category == 'all'}" (click)="filterByCategory('all')">
                               {{ 'All' | translate }}
                            </li>
                            <li class="filter" *ngFor="let category of dishes_categories" [ngClass]="{'active': show_only_by_category == category}" (click)="filterByCategory(category)">
                               {{ category }}
                            </li>
                        </ul>
                    </div>  
                </div>
                
                <!-- <div class="col-sm-12 col-lg-4"  *ngFor="let dish of resturant_dishes.data.dishes" [ngClass]="{'hide': show_only_by_category != dish.dish_category && show_only_by_category != 'all'}">
                    <app-product-item-v2 [item]="dish"></app-product-item-v2>  
                </div> -->

                <ng-container *ngFor="let category of dishes_categories">
                    <div class="col-md-12" [ngClass]="{'hide': show_only_by_category != category && show_only_by_category != 'all'}">
                        <h4 class="text-left">{{ category }}</h4>
                    </div>
                    <div class="col-sm-12 col-lg-4"  *ngFor="let dish of dishes_by_categories[category]" [ngClass]="{'hide': show_only_by_category != dish.dish_category && show_only_by_category != 'all'}">
                        <app-product-item-v2 [item]="dish"></app-product-item-v2>  
                    </div>
                </ng-container>

                <div class="col-lg-12" *ngIf="resturant_dishes.data.dishes.length == 0">
                    <h3 class="text-center">{{ 'No Results' | translate }}</h3>
                </div>
            </div>

            <div id="Container" class="row" *ngIf="tab == 'MEALS' && !resturant_dishes">
                <div class="col-md-12 pt-70 pb-70">
                    <mat-progress-spinner mode="indeterminate" style="margin: auto;"></mat-progress-spinner>
                </div>
            </div>

            <div id="Container" class="row" *ngIf="tab == 'MY_ORDERS' && orders">
                <div class="col-sm-12">
                    <div *ngFor="let order of orders;index as orderIndex;">
                        <app-order-item [order]="order"></app-order-item>
                    </div>
                </div>
            </div>
            <div id="Container" class="row" *ngIf="tab == 'MY_ORDERS' && !orders">
                <div class="col-md-12 pt-70 pb-70">
                    <mat-progress-spinner mode="indeterminate" style="margin: auto;"></mat-progress-spinner>
                </div>
            </div>
            
            <div class="more-collection" *ngIf="tab == 'MEALS'">
                <a href="javascript:void(0)" (click)="previousPage()" *ngIf="page > 1" class="text-danger">{{ 'Previous' | translate }}</a>
                <a href="javascript:void(0)" (click)="nextPage()" *ngIf="has_more == '1'">{{ 'Load More' | translate }}</a>
            </div>

            <div class="more-collection" *ngIf="tab == 'MY_ORDERS'">
                <a href="javascript:void(0)" (click)="previousOrderPage()" *ngIf="order_page > 1" class="text-danger">{{ 'Previous' | translate }}</a>
                <a href="javascript:void(0)" (click)="nextOrderPage()" *ngIf="has_more_orders == '1'">{{ 'Load More' | translate }}</a>
            </div>

        </div>
    </section>

    <app-footer></app-footer>

</div>