
<div class="order-item">
    <div class="order-item-header">
        <div class="order-item-header-item">
            <div class="order-item-header-restaurant-logo">
                <img alt="Logo" [src]="order.restaurant.logo">
            </div>
            <div class="order-item-header-restaurant-details">
                <h4>
                    <a [routerLink]="['/', helpers.localeObject.prefix, 'order-detials']" [queryParams]="{ order_id: order.id }" style="color: #fff;">{{ order.restaurant.title }}</a>
                </h4>
                <p *ngIf="order.restaurant.categories.length > 0">
                    <span *ngFor="let category of order.restaurant.categories;index as catIndex;">
                        {{ catIndex > 0 ? ' - ' : '' }}{{ category.title }}
                    </span>
                </p>
            </div>
        </div>
        <div class="order-item-header-item">
            <h4>{{ 'Date' | translate }}</h4>
            <strong>{{ order.created_date }}</strong>
        </div>
        <div class="order-item-header-item">
            <h4>{{ 'Order Code' | translate }}</h4>
            <strong>{{ order.code }}</strong>
        </div>
        <div class="order-item-header-item status-item">
            <span class="status" [ngClass]="{'success': order.status == 'DELIVERED', 'danger': order.status == 'DECLINED', 'warning': (order.status != 'DECLINED' && order.status != 'DELIVERED')}">
                <i *ngIf="order.status == 'DELIVERED'" class="bx bx-check-circle"></i> 
                <i *ngIf="order.status == 'DECLINED'" class="bx bx-x-circle"></i>
                <i *ngIf="order.status != 'DECLINED' && order.status != 'DELIVERED'" class="bx bx-time"></i>
                {{ 'order.status.' + order.status | translate }}
            </span>
        </div>
    </div>
    <div class="order-item-body">
        <div class="order-item-body-item">
            <div class="order-item-body-item-col">
                <img src="assets/img/delivery.png" alt="" class="img-icon">
                <strong class="icon-title">{{ 'Delivery' | translate }}</strong>
                <p class="colored">{{ 'order.types.' + order.order_type | translate }}</p>
            </div>
            <div class="order-item-body-item-col">
                <img src="assets/img/card.png" alt="" class="img-icon">
                <strong class="icon-title">{{ 'Payment' | translate }}</strong>
                <p class="colored">{{ 'order.payment.types.' + order.payment_method | translate }}</p>
            </div>
            <div class="order-item-body-item-col">
                <strong class="icon-title">{{ 'Address' | translate }}</strong>
                <p>{{ order.address.address }}</p>
            </div>
        </div>
        <div class="order-item-body-item" *ngFor="let dish of order.dishes;index as dishIndex;">
            <div class="order-item-body-item-col">
                <img [src]="dish.dish.image" alt="" class="img-meal">
                <div class="meal-details">
                    <strong class="meal-title">{{ dish.dish.title }}</strong>
                </div>
                <div class="meal-categories" *ngIf="dish.side_dishes.length > 0">
                    <div class="blog-details-tags" *ngFor="let side_dish of dish.side_dishes;index as sideDishIndex;">
                        <ul>
                            <ng-container *ngFor="let side_dish_value of side_dish.values">
                                <li *ngIf="side_dish_value.status">
                                    <a href="javascript:;" class="active">
                                        <span>{{ side_dish.title }}: {{ side_dish_value.title }} - [{{ side_dish_value.price }} {{ order.restaurant.currency }}]</span>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="order-item-body-item-col">
                <strong class="icon-title meal-quantity">x {{ dish.quantity }}</strong>
            </div>
            <div class="order-item-body-item-col">
                <strong class="icon-title text-warning">{{ dish.cost }} {{ order.restaurant.currency }}</strong>
            </div>
        </div>
        <div class="order-item-body-item">
            <div class="order-item-body-item-col">
                <strong class="icon-title order-total meal-quantity">
                    <span class="total">{{ 'Total' | translate }}: </span> {{ order.cost }} {{ order.restaurant.currency }}
                </strong>
            </div>
        </div>
    </div>
</div>