import { ProfileService } from './../../services/profile.service';
import { GoogleMapComponent } from './../google-map/google-map.component';
import { Router } from '@angular/router';
import { UserService } from './../../models/user.service';
import { CartService } from './../../models/cart.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit, Input, ViewChild, AfterViewInit, Inject, Renderer2 } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { CartResponse } from 'src/app/types/all.types';
import { DOCUMENT } from '@angular/common';
declare let $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  @ViewChild(GoogleMapComponent) ChildMap: GoogleMapComponent;

  @Input() nav_type: string = 'main-nav-two';

  public isLoggedIn: boolean;

  public user: any;

  public GPS = {lat: 0, lng: 0};

  public addresses: any[] | null;

  public auto_location_loading : boolean = false;

  public _cartResponse : CartResponse;

  public dishes_count: number = 0;

  public nav_data: any;

  public hasLatLng: boolean = true;

  constructor(
    public helpers: Helpers, 
    public cart: CartService, 
    private UserServiceStorage: UserService, 
    private router: Router, 
    private profileApi: ProfileService, 
    private readonly translateService: TranslateService,
    @Inject(DOCUMENT) document, 
    private renderer: Renderer2,
  ) { 
    renderer.setAttribute(document.body, 'locale', this.helpers.localeObject.prefix);
    if (this.UserServiceStorage.isLoggedIn()) {
      this.isLoggedIn = this.UserServiceStorage.isLoggedIn();
      this.user = this.UserServiceStorage.user;
      if (this.isLoggedIn) {
        this.loadAddresses();
      }
    }
    this.UserServiceStorage
    .UserData()
    .subscribe((User) => {
      this.isLoggedIn = this.UserServiceStorage.isLoggedIn();
      this.user = User;
      if (this.isLoggedIn) {
        this.loadAddresses();
      }
    });

    if (this.UserServiceStorage.isLoggedIn()) {
      this.profileApi
      .getNavData()
      .subscribe((res) => {
        console.log(res);
        if (res['message'] == 'success') {
          this.nav_data = res['data'];
        }
      });
    }
  }

  ngOnInit(): void {
    let self = this;
    if (this.UserServiceStorage.isLoggedIn()) {
      if (! this.UserServiceStorage.user.address['completed']) {
        // $('#openAddressCompletionModal').modal('toggle');
        // $('#close-navbar-address-completion-modal').click();
        // $('#openAddressCompletionModal').click();
        this.translateService.get(['']).subscribe(translations => {

          Swal.fire({
            title: self.translateService.instant('Please Complete Your Address'),
            // text: 'This process is irreversible.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: self.translateService.instant('My Addresses'),
            // cancelButtonText: 'No, let me think'
          }).then((result) => {
            if (result.value) {
              self.router.navigate(['/', self.helpers.localeObject.prefix, 'addresses']);
            } 
            else if (result.dismiss === Swal.DismissReason.cancel) {
              
            }
          });

        });
      }
    }
    this.auto_location_loading = true;
    Helpers.make('GoogleMaps', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCiBmR5kDnIiGGfDp6CHfIcnLsCSdu8w2c', {
      success: function() {
        Helpers.getLocation({
          success: function(lat, lng) {
            self.hasLatLng = true;
            self.auto_location_loading = false;
            self.GPS = {
              lat: lat,
              lng: lng,
            };
            // self.ChildMap.refreshMarker(lat, lng);
          },
          fail: function(err) {
            self.hasLatLng = false;
          },
          denied: function(err) {
            self.hasLatLng = false;
          },
        });
      }
    });
  }

  ngAfterViewInit(): void {
    
  }

  loadAddresses() {
    this.profileApi.addressesList({
      page: 1
    })
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        res['data']['addresses'].unshift({
          id  : 0,
          text: '--------'
        });
        // this.has_more  = res['data']['more_available'];
        this.addresses = res['data']['addresses'];
      }
    });
  }

  logout() {
    this.UserServiceStorage.logout();
    this.router.navigate(['/', this.helpers.localeObject.prefix]);
    window.location.reload();
  }

  markerControll(data) {
    if (data.latitude && data.longitude) {
      Helpers.setPostion(data.latitude, data.longitude);
      this.changeDefaultAddress(data);
      this.ChildMap.refreshMarker(data.latitude, data.longitude);
    }
    else if(data.event) {
      Helpers.setPostion(data.event.latLng.lat(), data.event.latLng.lng());
    }
    else if(data.target.value) {
      let add = null;
      this.addresses.forEach((address) => {
        if (Number(address.id) == Number(data.target.value)) {
          add = address;
        }
      });
      if (add) {
        if (add.latitude && add.longitude) {
          Helpers.setPostion(add.latitude, add.longitude);
          this.changeDefaultAddress(add);
          this.ChildMap.refreshMarker(add.latitude, add.longitude);
        }
      }
      else {
        this.setAutoLocation();
      }
    }
    else {
      this.setAutoLocation();
    }
  }

  okClicked() {
    window.location.reload();
  }

  setAutoLocation() {
    this.auto_location_loading = true;
    let self = this;
    Helpers.getLocation({
      success: function(lat, lng) {
        self.auto_location_loading = false;
        self.GPS = {
          lat: lat,
          lng: lng,
        };
        self.ChildMap.refreshMarker(lat, lng);
        Helpers.setPostion(lat, lng);
        window.location.reload();
      }
    }, true);
  }

  changeDefaultAddress(address) {
    this.profileApi.setAddressAsDefault({
      address_id: address.id
    })
    .pipe(take(1))
    .subscribe((res: any) => {
      if (res.message == 'success') {
        address.default = true;
        this.UserServiceStorage.user.address = address;
        this.UserServiceStorage.save(this.UserServiceStorage.user);
        this.addresses = null;
        this.loadAddresses();
        window.location.reload();
      }
    });
  }

  setDishesCount() {
    this._cartResponse.cart.dishes.forEach(dish => {
      this.dishes_count = this.dishes_count + Number(dish.quantity);
    });
  }
}