<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Order Details' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix, 'my-orders']">{{ 'My Orders' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Order Details' | translate }}: <strong class="text-warning" *ngIf="order">{{ order.code }}</strong>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container pt-70 pb-70">
        <div class="row">
            <div class="col-md-12" *ngIf="order">
                <app-order-item [order]="order"></app-order-item>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>