import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {

  public coupon_code : string;

  public coupons     : any[];

  public error       : any;

  public errors      : any = [];
  
  public success     : any;

  public page        : number = 1;

  public has_more    : string = '0';

  constructor(public helpers: Helpers, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router, private activatedRoute: ActivatedRoute) { 
    
  }

  ngOnInit(): void {
    if (! this.UserServiceStorage.isLoggedIn()) {
      this.router.navigate(['/', this.helpers.localeObject.prefix]);
      return;
    }
    
    this.activatedRoute.queryParams.subscribe(params => {
      if (!params['code']) { return; }
      this.coupon_code = params['code'] || '';
      if (this.coupon_code.length > 0) {
        this.reserveCoupon();
      }
    });
    
    this.loadCoupons();
  }

  loadMore() {
    this.page++;
    this.loadCoupons();
  }

  private loadCoupons() {
    this.profileApi.listCoupons({
      page: this.page
    })
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        this.has_more = res['data']['more_available'];
        this.coupons  = res['data']['coupons'];
      }
      else {
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
    });
  }

  reserveCoupon() {
    this.error       = null;
    this.errors      = [];
    this.success     = null;
    this.profileApi.addCoupon({
      code: this.coupon_code
    })
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        this.success = ParsedResponse['result'];
        this.coupon_code = null;
        this.page = 1;
        this.loadCoupons();
      }
      else {
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
    });
  }
}
