import { Router } from '@angular/router';

import { SliderMealPopupComponent } from '../../includes/slider-meal-popup/slider-meal-popup.component';
import { IndexService } from './../../services/index.service';
import { Helpers } from './../../Helpers';
import { Component, OnDestroy, OnInit, ViewChild, EventEmitter , Output } from '@angular/core';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
declare let $: any;


@Component({
  selector: 'app-v2-home',
  templateUrl: './v2-home.component.html',
  styleUrls: ['./v2-home.component.scss']
})
export class V2HomeComponent implements OnInit, OnDestroy {

  private _unsubscribeAll : Subject<any | null>;

  public meal_keyword: string;

  public hasLatLng: boolean = false;

  public index: any;

  public full_number: string  = '';

  public code: string = '';

  public country_code = 90;

  public phone_number = '';

  public step: number;

  public error: any;
  public errors: string[] = [];

  public index_v2: any;

  public addresses: any[];

  public isLoggedIn: boolean;

  private latitude;

  private longitude;

  public defaultAddress: any;

  @Output() onSuccessfullLogin : EventEmitter<any> = new EventEmitter();

  @ViewChild(SliderMealPopupComponent) MealPopup: SliderMealPopupComponent;

  constructor(public helpers: Helpers, private indexApi: IndexService, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router) {

    this._unsubscribeAll = new Subject();

    if (this.UserServiceStorage.isLoggedIn()) {
      this.isLoggedIn = this.UserServiceStorage.isLoggedIn();
      if (this.isLoggedIn) {
        this.loadAddresses();
      }
    }

    this.UserServiceStorage.UserData()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((User) => {
      this.isLoggedIn = this.UserServiceStorage.isLoggedIn();
      if (this.isLoggedIn) {
        this.loadAddresses();
      }
    });
  }

  markerControll(data) {
    Helpers.setPostion(data.latitude, data.longitude);
    // this.loadIndex();
  }


  changeDefaultAddress(address) {
    this.profileApi.setAddressAsDefault({
      address_id: address.id
    })
    .pipe(take(1))
    .subscribe((res: any) => {
      if (res.message == 'success') {
        address.default = true;
        this.UserServiceStorage.user.address = address;
        this.UserServiceStorage.save(this.UserServiceStorage.user);
        this.addresses = null;
        this.loadAddresses();
        window.location.reload();
      }
    });
  }
  
  ngOnDestroy() : void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    let self = this;
    Helpers.getLocation({
      success: function(lat, lng) {
        self.hasLatLng = true;
        self.loadIndex();
        self.loadIndexV2();

        self.latitude  = Helpers.locationObject ? Helpers.locationObject.lat : '0';
        self.longitude = Helpers.locationObject ? Helpers.locationObject.lng : '0';

        
      },
      fail: function(err) {
        self.hasLatLng = false;
      },
      denied: function(err) {
        self.hasLatLng = false;
      },
    });    
    this.step  = 1;
  }

  stepTwo() {
    this.error  = null;
    this.errors = [];
    this.profileApi.loginByNumberAndCodeV2({
      phone_number: this.parseNumber(), 
      code        : this.code,
      latitude    : this.latitude,
      longitude   : this.longitude,
    })
    .subscribe((res) => {
      if (res['message'] == 'fail') {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      else if (res['data']['result'] == 'logged_in_successfully') {
        this.saveUserData(res['data']['user']);
        this.sendSuccessfullLogin(res['data']['user']);
        window.location.reload();
      }
    });
  }

  sendSuccessfullLogin(user) {
    this.onSuccessfullLogin.emit({user: user});
  }

  saveUserData(ApiUser) {
    this.error  = null;
    this.errors = [];
    this.UserServiceStorage.save(ApiUser);
  }

  returnBack(step?) {
    this.error  = null;
    this.errors = [];
    if (step) {
      this.step = step;
      return;
    }
    this.step--;
  }

  loadIndexV2() {
    this.indexApi.loadIndexV2({
      latitude  : Helpers.locationObject.lng,
      longitude : Helpers.locationObject.lat,
      number_near_by_restaurants: 6,
    })
    .pipe(take(1))
    .subscribe((data) => { 
      this.index_v2 = data;
      setTimeout(() => {
        $.getScript('../assets/js/custom.js').then(() => {
          // console.log('Script Loaded');
        });
      }, 500);
    });
  }

  loadAddresses() {
    this.profileApi.addressesList({
      page: 1
    })
    .pipe(take(1))
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        var lang_text = '';
        if(this.helpers.localeObject.prefix == 'en'){
          lang_text = 'Select Location';
        }else{
          lang_text = 'اختر الموقع';
        }
        res['data']['addresses'].unshift({
          id  : 0,
          text: lang_text
        });
        res['data']['addresses'].forEach((address) => {
          if (address.default) {
            this.defaultAddress = address;
          }
        });
        // this.has_more  = res['data']['more_available'];
        this.addresses = res['data']['addresses'];
      }
    });
  }

  parseNumber() : string {
    this.full_number = `(+${this.country_code}) ${this.phone_number}`;
    return this.full_number;
  }

  stepOne() {
    this.error  = null;
    this.errors = [];
    this.profileApi.loginByNumberV2({
      phone_number: this.parseNumber()
    })
    .subscribe((res) => {
      if (res['message'] == 'fail') {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      else if (res['message'] == 'success') {
        if (res['data']['result'] == 'code_sent_successfully') {
          this.step = 2;
        }
        // else if(res['data']['result'] == 'user_can_login_now_call_login_service') {
        //   this.step = 4;
        // }
        else {
          this.step = 2;
        }
      }
    });
  }

  loadIndex() {
    this.indexApi.loadData({
      latitude  : Helpers.locationObject.lng,
      longitude : Helpers.locationObject.lat,
    })
    .pipe(take(1))
    .subscribe((data) => { 
      this.index = data;
      setTimeout(() => {
        $.getScript('../assets/js/custom.js').then(() => {
          // console.log('Script Loaded');
        });
      }, 500);
    });
  }

  openDishSlider(dish_id, branch_id) {
    this.MealPopup.startLoading({
      branch_id  : branch_id,
      dish_id    : dish_id,
      quantity   : 1,
      options    : {},
      attributes : [],
      cartKey    : null,
    });
  }

  openRestaurantSlider(resturant_id) {
    this.router.navigate(['/', this.helpers.localeObject.prefix, 'resturant', resturant_id]);
  }

  openCouponSlider(code) {
    if (this.isLoggedIn) {
      this.router.navigate(['/', this.helpers.localeObject.prefix, 'coupons'], {
        queryParams: {
          code: code
        }
      });
    }
  }
}
