<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Cart' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Cart' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>  

    <section class="cart-area pt-70 pb-70">
        <div class="container">
            <div class="cart-wrap">
                <table class="table">
                    <thead class="thead">
                        <tr>
                            <th class="table-head" scope="col">{{ 'Resturant' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Images' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Dishes' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Prices' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Quantity' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Total' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Remove' | translate }}</th>
                        </tr>
                    </thead>
    
                    <tbody>
                        <tr *ngFor="let item of cart.cart;index as itemIndex;">
                            <td>{{ item.restaurant_title }}</td>
                            <th class="table-item" scope="row"><img [src]="item.dish_image" alt="Menu"></th>
                            <td class="text-align-left">
                                {{ item.dish_title }}
                                <small *ngFor="let attribute of item.attributes; index as attributeIndex;" class="text-warning"> 
                                    <br> + {{ attribute.title }} 
                                </small>
                            </td>
                            <td class="text-align-left">
                                {{ item.currency }} {{ item.single_price }}
                                <small *ngFor="let attribute of item.attributes; index as attributeIndex;" class="text-warning"> 
                                    <br> + {{ item.currency }} {{ attribute.price }} 
                                </small>
                            </td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.currency }}{{ helpers.number(item.total_price).toFixed(2) }}</td>
                            <td>
                                <a href="javascript:;" (click)="cart.remove(itemIndex)">
                                    <i class='bx bx-trash text-danger'></i>
                                </a>
                                <a href="javascript:;" (click)="updateCartItem(item, itemIndex)" data-toggle="modal" data-target="#meal-cart-modal">
                                    <i class='bx bx-edit'></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="modal fade" tabindex="-1" role="dialog" id="meal-cart-modal">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">{{ 'Meal Details' | translate }}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" style="padding: 2rem;" *ngIf="itemToUpdate">
                                <div *ngIf="meal">
                                    <div class="row">
                                        <div class="col-md-12">
                                    
                                            <div class="meal-detail-card">
                                                <div class="meal-image">
                                                    <img [src]="itemToUpdate.dish_image" alt="" class="meal-img">
                                                </div>

                                                <!-- <app-favorite-toggle [entity_type]="'DISH'" [entity_id]="itemToUpdate.dish_id" [branch_id]="itemToUpdate.branch_id" [active]="helpers.number(itemToUpdate.is_favorite)" (onToggle)="favoriteAction($event)"></app-favorite-toggle> -->
                                    
                                                <div class="meal-info">
                                                    
                                                    <h2 class="meal-name">
                                                        {{ itemToUpdate.dish_title }}
                                                    </h2>
                                                    <div class="meal-rating">
                                                        <app-rating [rating]="itemToUpdate.dish_rating"></app-rating>
                                                    </div>
                                    
                                                    <h2 class="meal-price">
                                                        <strong>{{ itemToUpdate.currency }} {{ itemToUpdate.single_price }}</strong>
                                                    </h2>
                                    
                                                    <div class="meal-categories">
                                                        <div class="blog-details-tags" *ngIf="meal.data.dish.options.length > 0">
                                                            <ul>
                                                                <li *ngFor="let option of meal.data.dish.options">
                                                                    <a href="javascript:;" (click)="toggleOption(option)" [ngClass]="{'active' : (options[option.id] ? true : false)}">
                                                                        {{ option.text }}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                        </div>
                                    </div>
                                    <div class="row meal-attributes-row">
                                        <div class="col-sm-12" [ngClass]="{'col-lg-4': meal.data.dish.side_dishes.length >= 3, 'col-lg-6': meal.data.dish.side_dishes.length == 2, 'col-lg-12': meal.data.dish.side_dishes.length < 2}" *ngFor="let attribute of meal.data.dish.side_dishes">
                                            <div class="form-group meal-attributes">
                                                <label for="attr_{{ attribute.type }}">
                                                    {{ attribute.title }}
                                                </label>
                                                <app-select2-transformer id="attr_{{ attribute.type }}" [data]="attribute.values" [currency]="meal.data.dish.restaurant.currency" [options]="{multiple: attribute.select_type != 'single_choice'}" [disabled]="false" (onValueChangeHandler)="onValueChangeHandlerFun($event)"></app-select2-transformer>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group meal-attributes">
                                                <label for="attr_notes">
                                                    {{ 'Notes' | translate }}
                                                </label>
                                                <textarea id="attr_notes" class="form-control" name="notes" rows="3" [(ngModel)]="note"></textarea>
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div class="row" style="margin-top: 30px">
                                        <div class="col-md-8">
                                            <div class="btn-group meal-quantity number btn-group-sm" role="group">
                                                <button type="button" class="btn btn-outline-warning" (click)="decreseQuantity()">
                                                    <span>-</span>
                                                </button>
                                                <button type="button" class="btn btn-outline-warning btn-center">
                                                    <input type="text" class="form-control" [(ngModel)]="itemToUpdate.quantity" readonly="" />
                                                </button>
                                                <button type="button" class="btn btn-outline-warning" (click)="increaseQuantity()">
                                                    <span>+</span>
                                                </button>
                                            </div>
                                            <div class="meal-total">
                                                <h4>
                                                    {{ 'Total' | translate }}: <span class="text-warning">{{meal.data.dish.restaurant.currency}} {{ helpers.number(itemToUpdate.total_price || '0.00').toFixed(2) }}</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <button type="button" class="btn btn-warning btn-block btn-lg" (click)="addToCart()" data-dismiss="modal" aria-label="Close">
                                                <strong>{{ 'Add To Cart' | translate }}</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-warning" data-dismiss="modal">{{ 'Close' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
    
                <!-- <div class="shop-back">
                    <a routerLink="/checkout">Go for Order?</a>
                </div> -->
    
                <div class="total-shopping pt-70">
                    <h2>{{ 'Total Order' | translate }}</h2>
                    <h3>{{ 'Total' | translate }}: <span>{{cart.cart[0] ? cart.cart[0].currency : 'TL'}} {{ helpers.number(cart.total()).toFixed(2) }}</span></h3>
                    <a [routerLink]="['/', helpers.localeObject.prefix, 'checkout']">{{ 'Checkout Items' | translate }}</a>
                </div>
            </div>
        </div>
    </section>

    <app-footer></app-footer>

</div>