import { Helpers } from './Helpers';
import { UserService } from './models/user.service';
import { LoadingService } from './services/loading.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingHttpInterceptor implements HttpInterceptor {

    private token: string = '';

    constructor(private loadingService: LoadingService, private UserServiceStorage: UserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>{
        this.loadingService.setLoading(true);

        if (this.UserServiceStorage.isLoggedIn()) {
            this.token = this.UserServiceStorage.token() || '';
            if (this.token) {
                const clonedRequest = request.clone({ 
                    headers: request.headers.set('Authorization', 'Bearer ' + this.token),
                    setParams: {
                        locale: Helpers.staticLocaleObject.prefix,
                    }    
                });
                return next.handle(clonedRequest).pipe(
                    finalize(() => {
                        this.loadingService.setLoading(false);
                    })
                );
            }
        }

        const clonedRequest = request.clone({ 
            setParams: {
                locale: Helpers.staticLocaleObject.prefix,
            }    
        });
        return next.handle(request).pipe(
            finalize(() => {
                this.loadingService.setLoading(false);
            })
        );
    }
}