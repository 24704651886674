import { BlogSingleComponent } from './pages/blog-single/blog-single.component';
import { BlogComponent } from './pages/blog/blog.component';
import { SpecialOffersComponent } from './pages/special-offers/special-offers.component';
import { OrdersListComponent } from './pages/orders-list/orders-list.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { FavoritesComponent } from './pages/favorites/favorites.component';
import { CouponsComponent } from './pages/coupons/coupons.component';
import { AddressesComponent } from './pages/addresses/addresses.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { SuggestResturantComponent } from './pages/suggest-resturant/suggest-resturant.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { MealDetailsComponent } from './pages/meal-details/meal-details.component';
import { MealsComponent } from './pages/meals/meals.component';
import { ResturantDetailsComponent } from './pages/resturant-details/resturant-details.component';
import { ResturantsComponent } from './pages/resturants/resturants.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { CustomWebPageComponent } from './pages/custom-web-page/custom-web-page.component';
import { HomeComponent } from './pages/home/home.component';
import { V2HomeComponent } from './pages/v2-home/v2-home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartListComponent } from './pages/cart-list/cart-list.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { V2CartComponent } from './pages/v2-cart/v2-cart.component';

const routes: Routes = [
  // {path: ':locale'            , component: HomeComponent},
  {path: ':locale'            , component: V2HomeComponent},
  {path: ':locale/page/:id'   , component: CustomWebPageComponent},
  {path: ':locale/contact-us' , component: ContactUsComponent},
  {path: ':locale/blogs'      , component: BlogComponent},
  {path: ':locale/blog'       , component: BlogSingleComponent},

  {path: ':locale/resturants'        , component: ResturantsComponent},
  {path: ':locale/resturant/:id'     , component: ResturantDetailsComponent},
  
  {path: ':locale/meals'               , component: MealsComponent},
  {path: ':locale/meals-offer'         , component: SpecialOffersComponent},
  {path: ':locale/meal/:id/:branch_id' , component: MealDetailsComponent},

  // {path: ':locale/cart'     , component: CartListComponent},
  // {path: ':locale/checkout' , component: CheckoutComponent},

  // {path: ':locale/cart-old' , component: CheckoutComponent},
  {path: ':locale/cart'     , component: V2CartComponent},

  {path: ':locale/login'          , component: LoginComponent},
  {path: ':locale/reset-password' , component: ForgotPasswordComponent},
  // {path: ':locale/resgiter'         , component: RegisterComponent},

  {path: ':locale/my-orders'         , component: OrdersListComponent},
  {path: ':locale/order-detials'     , component: OrderDetailsComponent},
  {path: ':locale/suggest-resturant' , component: SuggestResturantComponent},
  {path: ':locale/coupons'           , component: CouponsComponent},
  {path: ':locale/favorites'         , component: FavoritesComponent},
  {path: ':locale/addresses'         , component: AddressesComponent},
  {path: ':locale/account-settings'  , component: AccountSettingsComponent},

  {path: ":locale/**", pathMatch: "full", redirectTo: "/ar"}, //Redirect Not Found To 404
  {path: "**"        , pathMatch: "full", redirectTo: "/ar"}, //Redirect None Locale To Home With Locale
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
