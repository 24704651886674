<div class="rest_card clearfix">
    <div class="row">
        <div  class="col-sm-12 col-lg-2 col-3">
            <img [src]="restaurant.logo" class="img-thumbnail2 img-profile3">
        </div>
        <div  class="col-sm-12 col-lg-7 col-5 rest_det_mob">
            <h6 class="rest_name">
                <a [routerLink]="['/', helpers.localeObject.prefix, 'resturant', restaurant.id]">{{restaurant.title}}</a>
                <p>{{restaurant.description}}</p>
            </h6>
            <span class="dish_min_time"><span class="red_color">{{ 'Min Order2' | translate }} {{ restaurant.minimun_order }} </span> . {{ restaurant.delivery_time }} {{ 'time_min' | translate }} </span>
        </div>
        <div  class="col-sm-12 col-lg-3 col-4" style="padding-top: 5px;">
            <app-rating [rating]="restaurant.rating"></app-rating>
            <button class="btn btn-default text-success rest_open" *ngIf="restaurant.open == 1">{{ 'Opened' | translate }}</button>
            <button class="btn btn-default text-danger rest_close" *ngIf="restaurant.open != 1"> {{ 'Closed' | translate }}</button>
        </div>
    </div>                         
</div>