import { Router, UrlSerializer } from '@angular/router';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; 

@Injectable({
  providedIn: 'root'
})
export class ResturantsService {

  constructor(private http: HttpClient, private router: Router, private serializer: UrlSerializer) { }

  loadData(page?: number, filter?: any) { 
    page   = ! page   ? 1  : page;
    filter = ! filter ? {} : filter;
    filter.page = page;
    let params = this.router.createUrlTree([''], { queryParams: filter });
    let urlString = this.serializer.serialize(params).substring(1);
    return this.http.get( environment.API_BASE_URL + '/restaurants' + urlString);
  }

  loadDataV2(page?: number, filter?: any) { 
    page   = ! page   ? 1  : page;
    filter = ! filter ? {} : filter;
    filter.page = page;
    let params = this.router.createUrlTree([''], { queryParams: filter });
    let urlString = this.serializer.serialize(params).substring(1);
    return this.http.get( environment.API_BASE_URL + '/restaurants/v2' + urlString);
  }
}
