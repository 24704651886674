import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/models/user.service';
import { MealDetailsService } from 'src/app/services/meal-details.service';
import { ProfileService } from 'src/app/services/profile.service';
import { FullDish } from 'src/app/types/all.types';

@Component({
  selector: 'app-cart-item-update',
  templateUrl: './cart-item-update.component.html',
  styleUrls: ['./cart-item-update.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class CartItemUpdateComponent implements OnInit, OnDestroy {

  public item: FullDish;

  public helper: any;

  attributes: any = [];

  options: any = {};

  quantity: number = 1;

  mealObject: any;

  note : string;

  constructor(
    public matDialogRef: MatDialogRef<CartItemUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private profileApi: ProfileService,
    private mealApi: MealDetailsService, 
    private UserServiceStorage: UserService,
  ) {
    this.helper = data.helper;
  }

  ngOnInit(): void {
    console.log(this.data);
    this.loadDishFromCart();
  }

  ngOnDestroy(): void {
    console.log(2);
  }

  loadDishFromCart(): void {
    this.profileApi.getCartItem({
      cart_dish_id: this.data.cart_dish.cart_dish_id
    })
    .pipe(take(1))
    .subscribe((res: any) => {
      // console.log(res);
      this.item = res.data.dish;
    });
  }

  closeEdit() {
    this.matDialogRef.close();
  }

  public addToFavorite() {
    this.mealApi.addToFavorite({
      branch_id: this.item.branch_id,
      dish_id  : this.item.dish_id,
    }).subscribe((data) => {
    });
  }

  favoriteAction(event) {
    if (event['message'] == 'success') {
      if (event['data']['result'] == 'removed_from_favorite_successfully') {
        this.item['is_favorite'] = '0';
      }
      else {
        this.item['is_favorite'] = '1';
      }
    }
  }

  onValueChangeHandlerFun(attributes, att_id, att_title) {
    delete this.attributes[ att_id ];
    if (attributes.id) {
      attributes.base_title     = att_title;
      this.attributes[ attributes.id ] = [attributes];
    }
    else {
      attributes.forEach(element => {
        element.base_title = att_title;
      });
      this.attributes[ att_id ] = attributes;
    }
    this.calculateTotal();
  }

  toggleOption(option) {
    if (this.options[ option.id ]) {
      delete this.options[ option.id ];
      return;
    }
    this.options[ option.id ] = option;
    this.calculateTotal();
  }

  increaseQuantity() {
    this.quantity++;
    this.calculateTotal();
  }

  decreseQuantity() {
    if (this.quantity == 1) { return; }
    this.quantity--;
    this.calculateTotal();
  }

  calculateTotal() {
    this.prepareObject();
  }

  prepareObject() {
    let options = [];
    let attr    = [];
    Object.keys(this.options).forEach((key) => {
      if (this.options[ key ]) {
        options.push(this.options[ key ]);
      }
    });
    Object.keys(this.attributes).forEach((key) => {
      if (this.attributes[ key ]) {
        attr.push(this.attributes[ key ]);
      }
    });
    let mealObject = {
      note             : this.note || null,
      restaurant_id    : this.item.restaurant.id,
      restaurant_title : this.item.restaurant.title,
      restaurant_logo  : this.item.restaurant.logo,
      restaurant_rating: this.item.restaurant.rating,
      
      branch_id        : this.item.branch_id,
      branch_title     : this.item.branch_title || null,
      dish_id          : this.item.dish_id,
      dish_image       : this.item.dish_image,
      dish_title       : this.item.dish_title,
      dish_type        : this.item.type,
      dish_rating      : this.item.rating,
      dish_rating_count: this.item.rating_count,
      dish_category    : this.item.dish_category,
      currency         : this.item.restaurant.currency,
      single_price     : Number(this.item.cost),
      attributes_price : 0,
      total_price      : 0,
      quantity         : this.quantity,
      options          : options.length == 0 ? [] : options,
      attributes       : attr,
    };
    mealObject.total_price = this.data.helpers.calculateTotalMealPrice(mealObject);
    this.mealObject = mealObject;
    // console.log(this.mealObject);
  }
}
