<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">
    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'About Us' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'About Us' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    
    <section class="story-area pt-100 pb-0">
        <div class="story-shape"><img src="assets/img/about/story3.png" alt="Shape"></div>
    
        <div class="container">
            <div class="story-head">
                <h2>{{ 'Who We Are And What Do We Offer' | translate }}</h2>
                <p>
                    {{ 'about_us_text' | translate }}
                </p>
            </div>
    
            <!-- <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <div class="story-item">
                        <img src="assets/img/r-service.jpg" alt="Story">
                        <h3>{{ 'Restaurant Service' | translate }}</h3>
                    </div>
                </div>
    
                <div class="col-sm-6 col-lg-6">
                    <div class="story-item">
                        <img src="assets/img/d-service.jpg" alt="Story">
                        <h3>{{ 'Delivery Service' | translate }}</h3>
                    </div>
                </div>
            </div> -->
        </div>
    </section>
    
    <!-- <section class="services-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>What Spiz Services</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
    
            <div class="services-slider owl-theme owl-carousel">
                <div class="services-item">
                    <a routerLink="/services-details">
                        <img src="assets/img/home-one/services1.png" alt="services-image">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                        <h3>Fresh Healthy Food</h3>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                    </a>
                </div>
    
                <div class="services-item">
                    <a routerLink="/services-details">
                        <img src="assets/img/home-one/services2.png" alt="services-image">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                        <h3>Free Fast Home Delivery</h3>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                    </a>
                </div>
    
                <div class="services-item">
                    <a routerLink="/services-details">
                        <img src="assets/img/home-one/services3.png" alt="services-image">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                        <h3>Discount Voucher</h3>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                    </a>
                </div>
    
                <div class="services-item">
                    <a routerLink="/services-details">
                        <img src="assets/img/home-one/services3.png" alt="services-image">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                        <h3>Discount Voucher</h3>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                    </a>
                </div>
            </div>
        </div>
    </section> -->
    
    <!-- <section class="download-area-two pt-100 pb-70">
        <div class="download-shape">
            <img src="assets/img/home-one/reservation-shape.png" alt="Download">
            <img src="assets/img/about/download2.png" alt="Download">
        </div>
    
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="download-item">
                        <h2>Download Our Mobile App That Make You More Easy to Order</h2>
                        <ul>
                            <li><a href="#"><img src="assets/img/home-two/google-store.png" alt="Google"></a></li>
                            <li><a href="#"><img src="assets/img/about/app.png" alt="App"></a></li>
                        </ul>
                    </div>
                </div>
    
                <div class="col-lg-6">
                    <div class="download-img">
                        <img src="assets/img/home-one/subscribe-main.png" alt="Download">
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    
    <app-mobile-app></app-mobile-app>

    <app-footer></app-footer>
</div>
