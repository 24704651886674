<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'My Orders' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'My Orders' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container pt-70 pb-70">
        <div class="row">
            <div class="col-md-12" *ngIf="orders">
                <div *ngFor="let order of orders;index as orderIndex;">
                    <app-order-item [order]="order"></app-order-item>
                </div>
                <div class="collection-area" style="margin-top: 35px;">
                    <div class="more-collection">
                        <a href="javascript:void(0)" (click)="previousPage()" *ngIf="page > 1" class="text-danger">{{ 'Previous' | translate }}</a>
                        <a href="javascript:void(0)" (click)="nextPage()" *ngIf="has_more == '1'">{{ 'Load More' | translate }}</a>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>