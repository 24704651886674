<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar [nav_type]="'one'"></app-navbar>

    <section class="pb-100" *ngIf="!hasLatLng">
        <h3>{{ 'For Better Results Please Allow Location Service' | translate }}</h3>
    </section>

    <section class="banner-area pb-100" *ngIf="hasLatLng">
        <div class="banner-shape">
            <img src="assets/img/home-one/banner/shape1.png" alt="Shape">
            <img src="assets/img/home-one/banner/shape2.png" alt="Shape">
            <img src="assets/img/home-one/banner/shape3.png" alt="Shape">
            <img src="assets/img/home-one/banner/banner-shape.png" alt="Shape">
        </div>
        
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12" *ngIf="index">
                    <div class="banner-slider owl-theme owl-carousel">
                        <div class="slider-item" *ngFor="let slider of index.data.sliders">
                            <img [src]="slider.image" alt="Slider" *ngIf="slider.related_type == 'DISH'" (click)="openDishSlider(slider.dish_id, slider.branch_id)" data-toggle="modal" data-target="#meal-slider-modal">
                            <img [src]="slider.image" alt="Slider" *ngIf="slider.related_type == 'RESTAURANT'" (click)="openRestaurantSlider(slider.branch_id)" data-toggle="modal" data-target="#meal-slider-modal">
                            <img [src]="slider.image" alt="Slider" *ngIf="slider.related_type == 'NORMAL'">
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" tabindex="-1" role="dialog" id="meal-slider-modal">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ 'Meal Details' | translate }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" style="padding: 2rem;">
                            <app-meal-popup></app-meal-popup>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-warning" data-dismiss="modal">{{ 'Close' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row home-search" *ngIf="isLoggedIn">
                <div class="col-md-2 col-sm-1"></div>
                <div class="col-md-8 col-sm-10">
                    <div class="banner-content">
                        <form class="home-top-input">
                            <div *ngIf="addresses">
                                <app-select2-transformer 
                                    [data]="addresses" 
                                    [options]="{}" 
                                    [disabled]="false"
                                    (onValueChangeHandler)="markerControll($event)">
                                </app-select2-transformer>
                            </div>
                            <a [routerLink]="['/', helpers.localeObject.prefix, 'resturants']" [queryParams]="{ keyword: meal_keyword }" class="btn banner-form-btn">{{ 'Search' | translate }}</a>
                        </form>
                    </div>
                </div>
                <div class="col-md-2 col-sm-1"></div>
            </div>
        </div>
    </section>
    
    <section class="services-area services-area-two pb-100" *ngIf="hasLatLng">
        <div class="container">
            <div class="section-title">
                <h1>{{ 'Categories' | translate }}</h1>
                <p>{{ 'Categories Descriptipn' | translate }}</p>
                <p>{{ 'Most Delicios Categories Choosen For You' | translate }}</p>
            </div>
            <div class="services-slider owl-theme owl-carousel" *ngIf="index">
                <div class="services-item" *ngFor="let category of index.data.categories; index as itemIndex;" >
                    <a [routerLink]="['/', helpers.localeObject.prefix, 'meals']">
                        <img [src]="category.image" alt="services-image">
                    </a>
                    <h3>{{ category.title }}</h3>
                </div>
            </div>
        </div>
    </section>
    
    <!-- <section class="feature-area pb-70">
        <div class="container">
            <div class="section-title">
                <h4>{{ 'Featured Categories' | translate }} <small>{{ 'Most Delicios Categories Choosen For You' | translate }}</small></h4> 
            </div>
            <div class="row" *ngIf="index">
                <div class="col-sm-6 col-lg-4" *ngFor="let category of index.data.categories; index as itemIndex;">
                    <div class="feature-item" *ngIf="itemIndex <= 5">
                        <img [src]="category.image" alt="Feature" class="img-cover">
    
                        <div class="feature-inner">
                            <ul>
                                <li><img src="assets/img/home-one/feature3.png" alt="Feature"></li>
                                <li><span>{{ category.title }}</span></li>
                                <li><a [routerLink]="['/', helpers.localeObject.prefix, 'meals']" [queryParams]="{ category: category.id }"><i class='bx bx-right-arrow-alt'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="feature-item">
                        <img src="assets/img/home-one/feature2.jpg" alt="Feature">
                        <div class="feature-inner">
                            <ul>
                                <li><img src="assets/img/home-one/feature2.png" alt="Feature"></li>
                                <li><span>Hot Platter</span></li>
                                <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="feature-item">
                        <img src="assets/img/home-one/feature3.jpg" alt="Feature">
    
                        <div class="feature-inner">
                            <ul>
                                <li><img src="assets/img/home-one/feature3.png" alt="Feature"></li>
                                <li><span>Dessert</span></li>
                                <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </section> -->
    
    <!-- <div class="about-area">
        <div class="about-shape">
            <img src="assets/img/home-two/about3.png" alt="Shape">
            <img src="assets/img/home-two/about4.png" alt="Shape">
            <img src="assets/img/home-two/about5.png" alt="Shape">
            <img src="assets/img/home-two/about6.png" alt="Shape">
            <img src="assets/img/home-two/about7.png" alt="Shape">
        </div>
    
        <div class="container-fluid p-0">
            <div class="row m-0 align-items-center">
                <div class="col-lg-6 p-0">
                    <div class="about-img">
                        <img src="assets/img/home-two/about1.png" alt="About">
                        <img src="assets/img/home-two/about2.png" alt="About">
                    </div>
                </div>
    
                <div class="col-lg-6">
                    <div class="about-content">
                        <div class="section-title">
                            <span class="sub-title">About Us</span>
                            <h2>Spiz is One Of The Most Hygienic & Trusted Food Service</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby.</p>
                        </div>
                        <a class="cmn-btn" routerLink="/services-details">Know More</a>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    
    <section class="collection-area pb-100" *ngIf="index && hasLatLng">
        <div class="container">
            <div class="section-title">
                <h1>{{ 'Special Meals' | translate }}</h1>
                <p>{{ 'Special Meals Description' | translate }}</p>
                <p>{{ 'Some Of Meals You May Like' | translate }}</p>
            </div>
            <div id="Container" class="row" >
                <div class="col-sm-12 col-lg-3 mix web ui" *ngFor="let meal of index.data.meals[0].popular_meals; index as itemIndex;">
                    <app-product-item [item]="meal" *ngIf="itemIndex <= 7"></app-product-item>
                </div>
            </div>
    
            <div class="more-collection">
                <a [routerLink]="['/', helpers.localeObject.prefix, 'meals']">{{ 'Load More' | translate }}</a>
            </div>
        </div>
    </section>

    <section class="collection-area pb-70" *ngIf="hasLatLng">
        <div class="container">
            <div class="section-title">
                <h1>{{ 'Special Restaurants' | translate }}</h1>
                <p>{{ 'Some Of Restaurants You May Like' | translate }}</p>
            </div>
        
            <div id="Container" class="row" *ngIf="index">
                <div class="col-sm-12 col-md-6 col-lg-4 mix web ui" *ngFor="let restaurant of index.data.popular_restaurants; index as itemIndex;">
                    <app-resturant-card [restaurant]="restaurant" *ngIf="itemIndex <= 5"></app-resturant-card>
                </div>
            </div>
    
            <div class="more-collection">
                <a [routerLink]="['/', helpers.localeObject.prefix, 'resturants']">{{ 'Load More' | translate }}</a>
            </div>
        </div>
    </section>

    <div class="join-area" *ngIf="hasLatLng">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="join-img">
                        <img src="assets/img/home-two/join1.png" alt="Join">
                    </div>
                </div>
    
                <div class="col-lg-6">
                    <div class="join-content">
                        <div class="section-title">
                            <h2>{{ 'Join Us And Enjoy With Our Delivery Services' | translate }}</h2>
                            <p>{{ 'Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.' | translate }}</p>
                        </div>
                        <a class="cmn-btn" [routerLink]="['/', helpers.localeObject.prefix, 'login']">{{ 'Join Now' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <app-mobile-app *ngIf="hasLatLng"></app-mobile-app>

    <section class="blog-area ptb-100" *ngIf="index && hasLatLng">
        <div class="container">
            <div class="section-title">
                <h2>{{ 'Our Regular Blogs' | translate }}</h2>
                <p>{{ 'Most Viewd Blogs and Articals That Exists' | translate }}</p>
            </div>
            <div class="row">
                <div class="col-sm-12 col-lg-4" *ngFor="let blog of index.data.blogs">
                    <app-blog-item [blog]="blog"></app-blog-item>
                </div>    
            </div>
            <div class="text-center">
                <a class="read-blog-btn" [routerLink]="['/', helpers.localeObject.prefix, 'blogs']">{{ 'Load More' | translate }}</a>
            </div>
        </div>
    </section>
    
    <!-- <section class="chef-area chef-area-two pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Our Special Chefs</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
    
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="chef-item">
                        <div class="chef-top">
                            <img src="assets/img/home-one/chef/1.jpg" alt="Chef">
                            <div class="chef-inner">
                                <h3>John Doe</h3>
                                <span>Head of Chef</span>
                            </div>
                        </div>
                        <div class="chef-bottom">
                            <ul>
                                <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                                <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                                <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
    
                <div class="col-sm-6 col-lg-3">
                    <div class="chef-item">
                        <div class="chef-top">
                            <img src="assets/img/home-one/chef/6.jpg" alt="Chef">
                            <div class="chef-inner">
                                <h3>John Smith</h3>
                                <span>Assistant Chef</span>
                            </div>
                        </div>
                        <div class="chef-bottom">
                            <ul>
                                <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                                <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                                <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
    
                <div class="col-sm-6 col-lg-3">
                    <div class="chef-item active">
                        <div class="chef-top">
                            <img src="assets/img/home-one/chef/3.jpg" alt="Chef">
                            <div class="chef-inner">
                                <h3>Evanaa</h3>
                                <span>Intern Chef</span>
                            </div>
                        </div>
                        <div class="chef-bottom">
                            <ul>
                                <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                                <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                                <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
    
                <div class="col-sm-6 col-lg-3">
                    <div class="chef-item">
                        <div class="chef-top">
                            <img src="assets/img/home-one/chef/7.jpg" alt="Chef">
                            <div class="chef-inner">
                                <h3>Knot Doe</h3>
                                <span>Asst. Chef</span>
                            </div>
                        </div>
                        <div class="chef-bottom">
                            <ul>
                                <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                                <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                                <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    
    <!-- <div class="review-area review-area-two">
        <div class="review-shape"><img src="assets/img/home-two/review2.png" alt="Review"></div>
    
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="review-img">
                        <img src="assets/img/home-two/review1.jpg" alt="Review">
                    </div>
                </div>
    
                <div class="col-lg-6">
                    <div class="review-item">
                        <div class="section-title">
                            <h2>What People Say About Us</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
    
                        <div class="slider-nav" dir="rtl">
                            <div class="item" dir="rtl">
                                <img src="assets/img/home-one/review3.png" alt="image"  draggable="false"/>
                            </div>
    
                            <div class="item" dir="rtl">
                                <img src="assets/img/home-one/review4.png" alt="image" draggable="false"/>
                            </div>
    
                            <div class="item" dir="rtl">
                                <img src="assets/img/home-one/review5.png" alt="image" draggable="false"/>
                            </div>
    
                            <div class="item" dir="rtl">
                                <img src="assets/img/home-one/review6.png" alt="image" draggable="false"/>
                            </div>
    
                            <div class="item" dir="rtl">
                                <img src="assets/img/home-one/review7.png" alt="image" draggable="false"/>
                            </div>
    
                            <div class="item" dir="rtl">
                                <img src="assets/img/home-one/review8.png" alt="image" draggable="false"/>
                            </div>
                        </div>
    
                        <div class="slider-for" dir="rtl">
                            <div class="item" dir="rtl">
                                <h3>John Doe</h3>
                                <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                            </div>
    
                            <div class="item" dir="rtl">
                                <h3>Jac Jackson</h3>
                                <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                            </div>
    
                            <div class="item" dir="rtl">
                                <h3>Tom Henry</h3>
                                <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                            </div>
    
                            <div class="item" dir="rtl">
                                <h3>John Mic</h3>
                                <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                            </div>
    
                            <div class="item" dir="rtl">
                                <h3>Stark Arey</h3>
                                <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                            </div>
    
                            <div class="item" dir="rtl">
                                <h3>Stark Arey</h3>
                                <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    
    <!-- <section class="blog-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Our Regular Blogs</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
    
            <div class="row">
                <div class="col-sm-6 col-lg-4">
                    <div class="blog-item">
                        <div class="blog-top">
                            <a routerLink="/blog-detailhtml">
                                <img src="assets/img/home-one/blog1.jpg" alt="Blog">
                            </a>
                            <span>01 May 2020</span>
                        </div>
                        <div class="blog-bottom">
                            <h3><a routerLink="/blog-detailhtml">Brief About How to Make Pasta</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                            <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>
                        </div>
                    </div>
                </div>
    
                <div class="col-sm-6 col-lg-4">
                    <div class="blog-item">
                        <div class="blog-top">
                            <a routerLink="/blog-detailhtml">
                                <img src="assets/img/home-one/blog2.jpg" alt="Blog">
                            </a>
                            <span>02 May 2020</span>
                        </div>
                        <div class="blog-bottom">
                            <h3><a routerLink="/blog-detailhtml">Brief About How to Make Pizza</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                            <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>
                        </div>
                    </div>
                </div>
    
                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="blog-item">
                        <div class="blog-top">
                            <a routerLink="/blog-detailhtml">
                                <img src="assets/img/home-one/blog3.jpg" alt="Blog">
                            </a>
                            <span>03 May 2020</span>
                        </div>
                        <div class="blog-bottom">
                            <h3><a routerLink="/blog-detailhtml">Brief About How to Make Burger</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                            <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="text-center">
                <a class="read-blog-btn" routerLink="/blog">Read More Blogs</a>
            </div>
        </div>
    </section> -->

    <app-footer></app-footer>
    
</div>
