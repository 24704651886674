import { UserService } from './../../models/user.service';
import { ProfileService } from './../../services/profile.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-favorite-toggle',
  templateUrl: './favorite-toggle.component.html',
  styleUrls: ['./favorite-toggle.component.scss']
})
export class FavoriteToggleComponent implements OnInit {

  faHeart = faHeart;

  @Input() active     : number = 0;

  @Input() entity_type: string;

  @Input() entity_id  : number;

  @Input() branch_id  : number;

  @Output() onToggle  : EventEmitter<any> = new EventEmitter();

  constructor(private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router) { }

  ngOnInit(): void {
  }

  toggle() {
    this.profileApi.toggleFavorite({
      entity_type: this.entity_type,
      entity_id  : this.entity_id,
      branch_id  : this.branch_id,
    })
    .subscribe((res) => {
      this.onToggle.emit(res);
    });
  }
}
