import { UserService } from './models/user.service';
import { ProfileService } from './services/profile.service';
// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class MessagingService {

//   constructor() { }
// }

import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
 
@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  private static listeners = {};

  private user: any;
 
  currentMessage = new BehaviorSubject(null);
 
  constructor(private angularFireMessaging: AngularFireMessaging, private profileApi: ProfileService, private UserServiceStorage: UserService) {

    this.UserServiceStorage.UserData().subscribe((User) => {
      this.user = User;
    });
  
    this.angularFireMessaging.onMessage((msgings) => {
      console.log('onMessage');
      console.log(msgings);
    });

    // this.angularFireMessaging.getToken.subscribe((token) => {
    //   console.log('getToken');
    //   console.log(token);
    // });

    this.angularFireMessaging.onTokenRefresh((payload) => {
      console.log('onTokenRefresh');
      console.log(payload);
    });
  }
 
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe((token) => {
      console.log('requestToken');
      console.log(token);
      if (token && this.UserServiceStorage.isLoggedIn()) {
        this.profileApi.sendFirebaseToken({
          token       : token,
          device_type : 'IOS',
          device_id   : this.UserServiceStorage.user.id ? this.UserServiceStorage.user.id + '_WEB_DEVICE' : '123',
          locale      : 'ar'
        })
        .subscribe((res) => {
          console.log(res);
        });
      }
    });
  }
 
  receiveMessage() {
    // this.angularFireMessaging.onBackgroundMessage((msg) => {
    //   console.log("onBackgroundMessage!", msg);
    //   this.currentMessage.next(msg);
    // });

    this.angularFireMessaging.messages.subscribe((msg) => {
      // console.log(msg);
      this.currentMessage.next(msg);
      Object.keys( MessagingService.listeners ).forEach((name) => {
        MessagingService.listeners[name].apply(this, [msg]);
      });
      if (! msg['from_background']) {
        this.showNotification({
          title : msg['data']['title'],
          body  : msg['data']['body'],
          icon  : msg['data']['icon'],
        });
      }
    });
  }

  addListener(name: string, handeler) {
    MessagingService.listeners[name] = handeler;
  }

  showNotification(data) {
    var notification = new Notification(data['title'], { 
      icon: data['icon'], 
      body: data['body'],
      dir :'auto'
    });
    setTimeout(function() {
      notification.close();
    }, 9000);
  }
}

