import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { Helpers } from './../../Helpers';
import { MessagingService } from './../../messaging.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  public page  : number = 1;

  public blogs : any[];

  public has_more   : string = '0';

  constructor(private messagingService: MessagingService, public helpers: Helpers, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router, private readonly translateService: TranslateService) { }

  ngOnInit(): void {
    this.loadBlogs();
  }

  nextPage() {
    this.page++;
    this.loadBlogs();
  }

  previousPage() {
    this.page--;
    this.loadBlogs();
  }

  loadBlogs() {
    let params = {
      page      : this.page,
    };
    this.profileApi.listBlogs(params).subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'fail') {
        alert('No Internet');
      }
      else if (res['message'] == 'success') {
        this.blogs    = res['data']['contents'];
        this.has_more = res['data']['more_available'];
      }
    });
  }
}
