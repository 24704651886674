import { Helpers } from './../../Helpers';
import { UserService } from './../../models/user.service';
import { ProfileService } from './../../services/profile.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {

  public keyword     : string = '';

  public favorites   : any[];

  public error       : any;

  public errors      : any = [];
  
  public success     : any;

  public page        : number = 1;

  public has_more    : string = '0';

  public type        : string; //(DISH, BRANCH)

  constructor(public helpers: Helpers, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router) { }

  ngOnInit(): void {
    if (! this.UserServiceStorage.isLoggedIn()) {
      this.router.navigate(['/', this.helpers.localeObject.prefix]);
      return;
    }
    this.opneDishs();
  }

  opneDishs() {
    this.favorites = [];
    this.type      = 'DISH';
    this.page      = 1;
    this.loadFavorites();
  }

  opneBranchs() {
    this.favorites = [];
    this.type      = 'BRANCH';
    this.page      = 1;
    this.loadFavorites();
  }

  nextPage() {
    this.page++;
    this.loadFavorites();
  }

  previousPage() {
    this.page--;
    this.loadFavorites();
  }

  keywordSearch() {

  }

  private loadFavorites() {
    this.profileApi.listFavorites({
      page: this.page,
      type: this.type,
    })
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        this.has_more  = res['data']['more_available'];
        this.favorites = res['data']['favorites'];
      }
      else {
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
    });
  }
}
