import { Helpers } from './../../Helpers';
import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
declare let $: any;
declare let GMaps: any;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, AfterViewInit {

  @Input() marginBottom: number = 30;

  @Input() elemId;

  @Input() lat: string = '00.0000';

  @Input() lng: string = '00.0000';

  @Input() DefaultMarkerLat: string;

  @Input() DefaultMarkerLng: string;

  @Input() zoom: number = 10;

  public marker: any = false;

  public map;

  @Output() onMarkerSet : EventEmitter<any> = new EventEmitter();

  @Output() onAddMarker : EventEmitter<any> = new EventEmitter();

  @Output() onDragMarker: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.initMap();
    // }, 2000);

    var self = this;
    Helpers.make('GoogleMaps', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCiBmR5kDnIiGGfDp6CHfIcnLsCSdu8w2c', {
      success: function() {
        setTimeout(() => {
          self.initMap();
        }, 250);
      }
    });
  }

  sendMarkerSetToParent(lat, lng, marker) {
    this.onMarkerSet.emit({LatLng: {lat: lat, lng: lng}, map: this.map, marker: marker});
  }

  sendAddedMarkerToParent(event) {
    this.onAddMarker.emit({event: event, map: this.map, marker: this.marker});
  }

  sendDragedMarkerToParent(event) {
    this.onDragMarker.emit({event: event, map: this.map, marker: this.marker});
  }

  initMap() {
    var self = this;
    self.map = new GMaps({
      el: '#' + self.elemId,
      lat: parseFloat(self.lat),
      lng: parseFloat(self.lng),
      zoom: self.zoom,
      zoomControl : true,
      zoomControlOpt: {
        style : 'SMALL',
        position: 'TOP_LEFT'
      },
      panControl : false,
      click: function(event){
        // if ( self.marker ) {
        //   if( !confirm("If You Continue The Old Marker Details Will Wiped Out..") ){
        //     self.map.setCenter( self.marker.position.lat(), self.marker.position.lng() );
        //     return;
        //   }
        // }
        self.map.removeMarkers();
        self.marker = self.addMarker(event.latLng.lat(), event.latLng.lng());
        self.sendAddedMarkerToParent(event);
      },
      dragend: function(e) {
        self.sendDragedMarkerToParent(event);
      }
    });
    self.map.setCenter( parseFloat(self.lat), parseFloat(self.lng) );
    if (this.DefaultMarkerLat && this.DefaultMarkerLng) {
      self.marker = this.addMarker(this.DefaultMarkerLat, this.DefaultMarkerLng);
    }
  }

  addMarker(lat, lng) {
    let marker = this.map.addMarker({
      lat: lat,
      lng: lng,
    });
    this.map.setCenter( lat, lng );
    this.sendMarkerSetToParent(lat, lng, marker);
    return marker;
  }

  refreshMarker(lat, lng) {
    this.map.removeMarkers();
    this.marker = this.addMarker(lat, lng);
  }
}
