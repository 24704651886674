import { MealDetailsService } from './../../services/meal-details.service';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { CartService } from './../../models/cart.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnInit {

  public is_logged_in : boolean;

  public itemToUpdate : any;
  
  public itemToUpdateIndex : number;

  public options = {};

  public attributes = [];

  public meal: any;

  public note: string;

  constructor(public helpers: Helpers, public cart: CartService, private UserServiceStorage: UserService, private profileApi: ProfileService, private mealApi: MealDetailsService) {
    this.is_logged_in = UserServiceStorage.isLoggedIn();
  }

  ngOnInit(): void {
    console.log(this.cart.cart);
  }

  updateCartItem(item, itemIndex) : void {
    this.itemToUpdateIndex = itemIndex;
    this.itemToUpdate = item;
    this.loadData();
  }

  onValueChangeHandlerFun(attributes) {
    this.attributes = attributes.id ? [attributes] : attributes;
    this.itemToUpdate.attributes = this.attributes;
    this.calculateTotal();
  }

  toggleOption(option) {
    if (this.options[ option.id ]) {
      delete this.options[ option.id ];
      return;
    }
    this.options[ option.id ] = option;
    let options = [];
    Object.keys(this.options).forEach((key) => {
      options.push(this.options[ key ]);
    });
    this.itemToUpdate.options = options;
    this.calculateTotal();
  }

  increaseQuantity() {
    this.itemToUpdate.quantity++;
    this.calculateTotal();
  }

  decreseQuantity() {
    if (this.itemToUpdate.quantity == 1) { return; }
    this.itemToUpdate.quantity--;
    this.calculateTotal();
  }

  calculateTotal() {
    this.itemToUpdate.total_price = Helpers.calculateTotalMealPrice(this.itemToUpdate);
    // this.itemToUpdate = this.itemToUpdate;
  }

  public loadData() {
    this.meal       = null;
    this.options    = {};
    this.attributes = [];
    this.note       = null;

    this.mealApi.loadData({
      branch_id: this.itemToUpdate.branch_id,
      dish_id  : this.itemToUpdate.dish_id,
    })
    .subscribe((data) => {
      this.meal = data;
      this.calculateTotal();
    });
  }

  addToCart() {
    this.cart.remove(this.itemToUpdateIndex);
    this.cart.add(this.itemToUpdate);
  }
}
