
<div class="location-alert ptb-100">
    <div class="text-center" style="padding-bottom: 20px;">
        <img src="./assets/img/pin.svg" style="max-height: 160px;">
    </div>
    <h2 class="text-center">
        خدمة الموقع يجب ان تكون متوفرة
    </h2>
    <h5 class="text-center">
        يرجى السماح بخدمة الموقع من اجل ضمان عمل النظام بشكل صحيح
    </h5>
</div>