<section class="download-area ">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="download-content">
                    <div class="section-title">
                        <span class="sub-title">{{ 'Download' | translate }}</span>
                        <h2>{{ 'Download Our Mobile App That Make You More Easy to Order' | translate }}</h2>
                        <p>{{ 'Enjoy Your Meal And Have Your Own Time By Only Using These Three Steps' | translate }}</p>
                    </div>

                    <ul>
                        <li><span>01</span> {{ 'Select Your Meal' | translate }}</li>
                        <li><span>02</span> {{ 'Add To Cart' | translate }}</li>
                        <li><span>03</span> {{ 'Order Your Meal' | translate }}</li>
                    </ul>

                    <div class="app-wrap">
                        <a href="https://joaan.app/download"><img src="assets/img/home-two/google-store.png" alt="Google"></a>
                        <a href="https://joaan.app/download"><img src="assets/img/home-two/app-store.png" alt="App"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-img">
                    <img src="assets/img/mobile-app.png" alt="Download">
                </div>
            </div>
        </div>
    </div>
</section>