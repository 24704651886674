import { ProfileService } from './../../services/profile.service';
import { Router } from '@angular/router';
import { UserService } from './../../models/user.service';
import { CartService } from './../../models/cart.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  public user                      : any;
  public error                     : any;
  public errors                    : any = [];
  public success                   : any;
  public full_name                 : string;
  public email                     : string;
  public phone_number              : string;
  public old_password              : string = '';
  public new_password              : string = '';
  public new_password_confirmation : string = '';

  constructor(public helpers: Helpers, public cart: CartService, private UserServiceStorage: UserService, private router: Router, private profileApi: ProfileService) { 
    if (! this.UserServiceStorage.isLoggedIn()) {
      this.router.navigate(['/', this.helpers.localeObject.prefix]);
      return;
    }
    this.UserServiceStorage.UserData().subscribe((User) => {
      this.user = User;
    });
  }

  ngOnInit(): void {
    this.error   = null;
    this.errors  = [];
    this.success = null;
    if (! this.UserServiceStorage.isLoggedIn()) {
      this.router.navigate(['/', this.helpers.localeObject.prefix]);
      return;
    }
    this.user         = this.UserServiceStorage.user;
    this.full_name    = this.user.name;
    this.email        = this.user.email;
    this.phone_number = this.user.phone_number;
  }

  updateProfileData() {
    this.error   = null;
    this.errors  = [];
    this.success = null;
    this.profileApi.saveUserNewData({
      registration_type         : 'NORMAL',
      name                      : this.full_name,
      email                     : this.email,
      old_password              : this.old_password.length > 0              ? this.old_password              : null,
      new_password              : this.new_password.length > 0              ? this.new_password              : null,
      new_password_confirmation : this.new_password_confirmation.length > 0 ? this.new_password_confirmation : null,
    })
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'fail') {
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      else if (res['message'] == 'success') {
        this.success = ParsedResponse['result'];
        this.old_password              = '';
        this.new_password              = '';
        this.new_password_confirmation = '';
      }
    });
  }
}
