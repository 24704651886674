import { Router } from '@angular/router';
import { UserService } from './../../models/user.service';
import { ProfileService } from './../../services/profile.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public helpers: Helpers, private UserServiceStorage: UserService, private router: Router) { }

  ngOnInit(): void {
    if (this.UserServiceStorage.isLoggedIn()) {
      this.router.navigate(['/', this.helpers.localeObject.prefix]);
      return;
    }
  }

  loggedInSuccessfully(user) {
    this.router.navigate(['/', this.helpers.localeObject.prefix]);
  }
}
