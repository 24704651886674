import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-web-page',
  templateUrl: './custom-web-page.component.html',
  styleUrls: ['./custom-web-page.component.scss']
})
export class CustomWebPageComponent implements OnInit {

  constructor(public helpers: Helpers) { }

  ngOnInit(): void {
  }

}
