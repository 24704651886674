// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_BASE_URL: 'https://dev.tr.hungry-app.com/api',
  API_BASE_URL: 'https://tr.hungry-app.com/api',

  FACEBOOK  : 'https://google.com',
  TWITTER   : null,
  INSTAGRAM : null,
  YOUTUBE   : null,

  currency: 'TL',

  firebase : {
    apiKey: "AIzaSyDdiDkfnZ16DWdZFPUNkyz6Ut5lN6wGEh0",
    authDomain: "jo3aan-c3733.firebaseapp.com",
    databaseURL: "https://jo3aan-c3733.firebaseio.com",
    projectId: "jo3aan-c3733",
    storageBucket: "jo3aan-c3733.appspot.com",
    messagingSenderId: "388971019517",
    appId: "1:388971019517:web:db6ff87785b68e69ca5294",
    measurementId: "G-TG2HXXQ77V"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
