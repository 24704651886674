<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">
    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Checkout Items' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix, 'cart']">{{ 'Cart' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Checkout Items' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="is_logged_in && !success && isCartEmpty == false">
        <div class="row">
            <div class="col-md-12">

                <div class="header-filter">
                    <div class="header-filter-item">
                        <div class="header-filter-title">
                            {{ 'Address' | translate }}
                        </div>
                        <div class="header-filter-body">
                            <!-- <div class="form-group search" *ngIf="addresses">
                                <input type="text" class="form-control" readonly="" [value]="address.address">
                            </div> -->
                            <div class="form-group search" *ngIf="addresses">
                                <app-select2-transformer 
                                    [selected]="address"
                                    [data]="addresses" 
                                    [options]="{}" 
                                    [disabled]="false" 
                                    (onValueChangeHandler)="addressSelected($event)">
                                </app-select2-transformer>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

   

    <div class="container" *ngIf="!success">
        <div class="container" *ngIf="!success">        
            <div class="row" *ngIf="is_logged_in">
                <ng-container *ngIf="_cartResponse && !isCartEmpty && !isLoading">

                    <div class="col-md-12"style="padding-right: 0px;" *ngIf="!_cartResponse.coupon">
                        <div class="rest_close3 ">
                            <div class="row">
                                <div class="col-md-10 ">
                                    <h3>{{ 'coupon_title' | translate }}</h3>
                                    <p>{{ 'coupon_title_det' | translate }}</p>
                                </div>
                                <div class="col-md-2" style="text-align: left;">
                                    <span class="view_rest3" data-toggle="modal" data-target="#coupon-selection-modal">
                                        {{ 'coupon_title_btn' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
    
    
                    <div class="col-md-12"style="padding-right: 0px;" *ngIf="_cartResponse.coupon">
                        <div class="rest_close3">
                            <div class="row">
                                <div class="col-md-10 ">
                                    <h3>{{ 'coupon_title' | translate }}</h3>
                                    <p>
                                        {{ 'You Got Disscount Amount' | translate }}
                                            <strong>{{ _cartResponse.discount }}</strong>
                                        {{ 'By Coupon' | translate }}
                                            <strong>{{ _cartResponse.coupon.code }}</strong>
                                    </p>
                                </div>
                                <div class="col-md-2" style="text-align: left;">
                                    <span class="view_rest3" (click)="deleteCouponFromCart()">
                                        {{ 'coupon_title_btn2' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" tabindex="-1" role="dialog" id="coupon-selection-modal">
                        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">{{ 'coupon_title' | translate }}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" style="padding: 0;">
                                    <div class="cart-wrap">
                                        <table class="table">
                                            <thead class="thead">
                                                <tr>
                                                    <th class="table-head" scope="col">{{ 'Title' | translate }}</th>
                                                    <th class="table-head" scope="col">{{ 'Coupon Code' | translate }}</th>
                                                    <th class="table-head" scope="col">{{ 'disscount' | translate }}</th>
                                                    <th class="table-head" scope="col">{{ 'max_allowed_discount' | translate }}</th>
                                                    <th class="table-head" scope="col">{{ 'status' | translate }}</th>
                                                    <th class="table-head" scope="col">#</th>
                                                </tr>
                                            </thead>
                            
                                            <tbody *ngIf="coupons">
                                                <tr *ngFor="let item of coupons;index as itemIndex;">
                                                    <td><strong>{{ item.title }}</strong></td>
                                                    <td><strong class="text-danger" dir="ltr">{{ item.coupon_code }}</strong></td>
                                                    <td><strong dir="ltr">{{ item.discount }} {{ 'discount_type.' + item.discount_type | translate }}</strong></td>
                                                    <td><strong>{{ item.max_allowed_discount || '-------' }}</strong></td>
                                                    <td [ngClass]="{'text-success': item.use_stage == 'AVAILABLE', 'text-danger': item.use_stage != 'AVAILABLE'}">
                                                        <strong>{{ 'use_stage.' + item.use_stage | translate }}</strong>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-block btn-success" (click)="coupon_changed(item.id)" data-dismiss="modal" [disabled]="!item.suitable">
                                                            {{ 'Add To Cart' | translate }}
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>  
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-warning" data-dismiss="modal">{{ 'Close' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8 mycart">
                        <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
                        <form (ngSubmit)="save()" class="checkout-form" *ngIf="_cartResponse && !isCartEmpty">
                            <div class="cart-wrap">
                                <table class="table" style="margin-bottom: 30px;">
                                    <thead class="thead">
                                        <tr>
                                            <th class="table-item" scope="col" style="width: 10%;">
                                                <img [src]="_cartResponse.restaurant.logo" alt="Logo">
                                            </th>
                                            <th class="table-head text-align-left" scope="col" colspan="5" style="width: 90%;">
                                                {{ _cartResponse.restaurant.title }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let dish of _cartResponse.cart.dishes;index as dishIndex">
                                            <th class="table-item" scope="row" style="width: 20%">
                                                <img [src]="dish.image" alt="Dish Image">
                                            </th>
                                            <td class="text-align-left" style="width: 30%">
                                                {{ dish.title }}
                                                <br>
                                                <small *ngIf="dish.narrative">{{ dish.narrative }}</small>
                                                <!-- <small *ngFor="let attribute of dish.full_data.attributes; index as attributeIndex;" class="text-warning"> 
                                                    <br> {{ attribute.length > 0 ? helpers.firstElemFromArray(attribute).base_title : 'N/A' }} 
                                                    <span *ngFor="let att_value of attribute; index as vi;">
                                                        + {{ att_value.title }} [{{ item.branch.currency }} {{ att_value.price }}]
                                                        {{ attribute[vi + 1] ? ',' : '' }}
                                                    </span>
                                                </small> -->
                                            </td>
                                            <td style="width: 15%">
                                                {{ dish.quantity }} x
                                            </td>
                                            <td style="width: 25%">
                                                <strong class="text-success">
                                                    {{ dish.item_total }}
                                                </strong>
                                            </td>
                                            <td>
                                                <a href="javascript:;" (click)="removeCartItem(dish)">
                                                    <i class='bx bx-trash text-danger'></i>
                                                </a>
                                                <a href="javascript:;" (click)="openUpdate(dish)" data-toggle="modal" data-target="#meal-cart-modal">
                                                    <i class='bx bx-edit'></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <!-- <td colspan="2">
                                                {{ 'Select Coupon' | translate }}
                                            </td>
                                            <td colspan="1">
                                                <ng-container *ngIf="coupons">
                                                    <select class="form-control" name="coupon" style="height: 45px; border-radius: 10px; direction: ltr;" (change)="coupon_changed($event.target.value)">
                                                        <option *ngFor="let coupon of coupons" [value]="coupon.id" [disabled]="!coupon.suitable">
                                                            {{ coupon.title || coupon.text }} - {{ coupon.coupon_code }} - [{{ coupon.discount || '0.00' }}{{ coupon.discount_type == 'PERCENTAGE' ? '%' : currency }}]
                                                        </option>
                                                    </select>
                                                </ng-container>
                                            </td> -->
                                            <!-- <td colspan="1">
                                                <strong class="text-danger" *ngIf="selected_coupon">[{{ selected_coupon.discount || '0.00' }}{{ selected_coupon.discount_type == 'PERCENTAGE' ? '%' : currency }}]</strong>
                                            </td> -->
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                {{ 'Delivery Type' | translate }}
                                            </td>
                                            <td colspan="3">
                                                <select class="form-control" name="delivery_type" class="delivery_type" (change)="delivery_type_changed($event.target.value)">
                                                    <option 
                                                        *ngFor="let type of _cartResponse.restaurant.types" 
                                                        [value]="type.key" 
                                                        [selected]="type.key == delivery_type"
                                                        [disabled]="!_cartResponse.restaurant.delivers_to_location && type.key == 'DELIVERY'">
                                                        {{ 'order.types.' + type.key | translate }} + {{ currency }}{{ type.fee }}
                                                    </option>
                                                </select>
                                            </td>
                                            <!-- <td colspan="1">
                                                <strong class="text-success" *ngIf="selected_delivery_type">
                                                    {{ currency }}{{ selected_delivery_type.fee }}
                                                </strong>
                                            </td> -->
                                        </tr>
    
                                        <tr>
                                            <td colspan="2">
                                                {{ 'Notes' | translate }}
                                            </td>
                                            <td colspan="3">
                                                <input type="text" class="form-control" placeholder="{{ 'Notes' | translate }}" name="notes" [(ngModel)]="notes">
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                                
                            </div>
        
                            <div class="modal fade" tabindex="-1" role="dialog" id="order-confirmation-modal">
                                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">{{ 'Confirm Order' | translate }}</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
        
                       
                                        <div class="modal-body">
                                            <div class="alert alert-primary" role="alert" *ngIf="helpers.number(address.id) > 0 && delivery_type != 'TAKEAWAY'">
                                                <strong>{{ address.title }}</strong><br>
                                                {{ address.address }}
                                            </div>
                                            <div class="alert alert-primary" role="alert" *ngIf="delivery_type == 'TAKEAWAY'">
                                                <strong>{{ 'takeaway_note' | translate }}</strong><br>
                                            </div>
    
                                            <div class="form-group" *ngIf="helpers.number(address.id) > 0">
                                                <label for="">{{ 'Payment Type' | translate }}</label>
                                                <select class="form-control" name="payment_method" class="delivery_type"  (change)="updatePaymentMethod($event.target.value)">
                                                    <option value="CASH" [selected]="payment_method == 'CASH'">
                                                        {{ 'payment.type.CASH' | translate }}
                                                    </option>
                                                    <option value="BANKCARD" [selected]="payment_method == 'BANKCARD'">
                                                        {{ 'payment.type.CREDIT_CARD' | translate }}
                                                    </option>
                                                </select>
                                            </div>
                                            
                                            <div class="alert alert-danger" role="alert" *ngIf="helpers.number(address.id) == 0">
                                                <strong>{{ 'Please Notice' | translate }}</strong><br>
                                                {{ 'Please Select your address from list at the top' | translate }}
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row">
                                                
                                                <div class="col-md-6">
                                                    <button type="button" class="btn btn-outline-warning btn-block" data-dismiss="modal" id="close-confirm-modal">{{ 'Back' | translate }}</button>
                                                </div>
    
                                                
                                                <div class="col-md-6">
                                                    
                                                    <button type="submit" class="btn btn-outline-danger btn-block confirm_button2" *ngIf="helpers.number(address.id) == 0" disabled="">
                                                        {{ 'total_final' | translate  }} {{ _cartResponse.total }}  {{currency}}
                                                    </button>
    
                                                    <button type="submit" class="btn btn-outline-danger btn-block confirm_button2" *ngIf="helpers.number(address.id) > 0" onclick="$('#close-confirm-modal').click()">
                                                        {{ 'total_final' | translate  }} {{ _cartResponse.total }}  {{currency}}
                                                    </button>
    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
    
                    <div class="col-md-4">
                        <div class="total-shopping">
                            <h3> {{ 'order_overview' | translate }}</h3>
                            <p>
                                {{ 'conf_message' | translate }}
                            </p>
                            <table class="total_table">
                                <tr>
                                    <td style="width: 60%;">{{ 'total_products' | translate  }}</td>
                                    <td style="width: 40%; direction: ltr;"> {{ dishesCount }} </td>
                                </tr>
    
                                <tr *ngIf="diliveryFee > 0">
                                    <td style="width: 60%;">{{ 'total_delivery' | translate  }}</td>
                                    <td style="width: 40%; direction: ltr;"> {{ diliveryFee }} {{currency}} </td>
                                </tr>
    
                                <tr>
                                    <td style="width: 60%;">{{ 'total_price_old' | translate  }}</td>
                                    <td style="width: 40%; direction: ltr;"> {{ _cartResponse.subtotal }} {{currency}} </td>
                                </tr>
    
                                <tr *ngIf="_cartResponse.discount != 0">
                                    <td style="width: 60%;">{{ 'total_discount' | translate  }}</td>
                                    <td style="width: 40%; direction: ltr;"> {{ _cartResponse.discount }} {{currency}} </td>
                                </tr>
    
                                <tr class="green_total">
                                    <td style="width: 60%;">{{ 'total_final' | translate  }}</td>
                                    <td style="width: 40%; direction: ltr;">{{ _cartResponse.total }}  {{currency}}</td>
                                </tr>
                                
                            </table>
                    
              
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#order-confirmation-modal" class="confirm_button">
                                {{ 'Confirm Order' | translate }}
                            </a>
                        </div>
                    </div>
                </ng-container>

                <div class="empty-cart col-md-12 pb-70 pt-70" *ngIf="!isLoading && isCartEmpty">
                    <h3 class="text-center">Your Cart Is Empty</h3>
                </div>

                <div class="col-md-12 pt-70 pb-70" *ngIf="isLoading">
                    <mat-progress-spinner mode="indeterminate" style="margin: auto;"></mat-progress-spinner>
                </div>

                <div class="modal fade" tabindex="-1" role="dialog" id="meal-cart-modal">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">{{ 'Meal Details' | translate }}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" style="padding: 2rem;">
                                <app-meal-popup (onAddToCart)="refreshData($event)"></app-meal-popup>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-warning" data-dismiss="modal">{{ 'Close' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="! is_logged_in">
                <div class="col-md-2"></div>
                <div class="col-md-8 pt-70 pb-70">
                    <app-v2-auth (onSuccessfullLogin)="loggedInSuccessfully($event)"></app-v2-auth>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        
        <div class="container">
            <div class="row">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <section class="error-area" *ngIf="is_logged_in && success">
                        <div class="error-item">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="error-text">
                                        <h1 class="text-success">
                                            <i class="bx bx-check-circle"></i>
                                        </h1>
                                        <p class="text-success">{{ 'New Orders Created' | translate }}</p>
                                        <span>{{ 'Created Orders Codes' | translate }}</span>
                                        <p class="text-success">
                                            <span style="font-size: 24px; margin-top: 20px;">
                                                <strong *ngFor="let order_data of success; index as orderIndex;">{{ orderIndex > 0 ? ' - ' : '' }}{{ order_data.code }}</strong>
                                            </span>
                                        </p>
                                        <a [routerLink]="['/', helpers.localeObject.prefix]" style="margin-left: 10px; margin-right: 10px; margin-top: 20px;">
                                            {{ 'Home' | translate }}
                                        </a>
                                        <a [routerLink]="['/', helpers.localeObject.prefix, 'my-orders']" style="margin-left: 10px; margin-right: 10px; margin-top: 20px;">
                                            {{ 'My Orders' | translate }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>

        <div class="row" *ngIf="! is_logged_in">
            <div class="col-md-2"></div>
            <div class="col-md-8 pt-70 pb-70">
                <app-v2-auth (onSuccessfullLogin)="loggedInSuccessfully($event)"></app-v2-auth>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>

    <app-footer></app-footer>
</div>
