<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">
    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Checkout Items' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix, 'cart']">{{ 'Cart' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Checkout Items' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="is_logged_in && !success">
        <div class="row">
            <div class="col-md-12">

                <div class="header-filter">
                    <div class="header-filter-item">
                        <div class="header-filter-title">
                            {{ 'Address' | translate }}
                        </div>
                        <div class="header-filter-body">
                            <div class="form-group search" *ngIf="addresses">
                                <app-select2-transformer 
                                    [data]="addresses" 
                                    [options]="{}" 
                                    [disabled]="false" 
                                    (onValueChangeHandler)="addressSelected($event)">
                                </app-select2-transformer>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="container" *ngIf="!success">
        <div class="row" *ngIf="is_logged_in">
            <div class="col-md-12 pb-70" *ngIf="order">
                <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
                <form (ngSubmit)="save()" class="checkout-form">
                    <div class="cart-wrap">
                        <table class="table" *ngFor="let item of order;index as itemIndex;" style="margin-bottom: 30px;">
                            <thead class="thead">
                                <tr>
                                    <th class="table-item" scope="col" style="width: 10%;">
                                        <img [src]="item.branch.logo" alt="Logo">
                                    </th>
                                    <th class="table-head text-align-left" scope="col" colspan="5" style="width: 90%;">
                                        {{ item.branch.name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dish of item.dishes;index as dishIndex;">
                                    <th class="table-item" scope="row">
                                        <img [src]="dish.full_data.dish_image" alt="Dish Image">
                                    </th>
                                    <td class="text-align-left" style="width: 30%">
                                        {{ dish.full_data.dish_title }} [{{ item.branch.currency }} {{ dish.full_data.single_price }}]
                                        <small *ngFor="let attribute of dish.full_data.attributes; index as attributeIndex;" class="text-warning"> 
                                            <br> {{ attribute.length > 0 ? helpers.firstElemFromArray(attribute).base_title : 'N/A' }} 
                                            <span *ngFor="let att_value of attribute; index as vi;">
                                                + {{ att_value.title }} [{{ item.branch.currency }} {{ att_value.price }}]
                                                {{ attribute[vi + 1] ? ',' : '' }}
                                            </span>
                                        </small>
                                    </td>
                                    <td style="width: 25%">
                                        {{ dish.full_data.quantity }} x
                                    </td>
                                    <td style="width: 25%">
                                        <strong class="text-success">
                                            {{ item.branch.currency }}{{ helpers.number(dish.full_data.total_price).toFixed(2) }}
                                        </strong>
                                    </td>
                                    <td>
                                        <a href="javascript:;" (click)="removeCartItem(dish.cartKey)">
                                            <i class='bx bx-trash text-danger'></i>
                                        </a>
                                        <a href="javascript:;" (click)="updateCartItem(dish, item, dish.cartKey)" data-toggle="modal" data-target="#meal-cart-modal">
                                            <i class='bx bx-edit'></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="2">
                                        {{ 'Select Coupon' | translate }}
                                    </td>
                                    <td colspan="1">
                                        <select class="form-control" name="item[{{itemIndex}}][coupon]" [(ngModel)]="item.discount_item" (change)="couponSelected($event, item)" style="height: 45px; border-radius: 10px; direction: ltr;">
                                            <option *ngFor="let coupon of item.coupons" [ngValue]="coupon">
                                                {{ coupon.title || coupon.text }} - {{ coupon.coupon_code }} - [{{ coupon.discount || '0.00' }}{{ coupon.discount_type == 'PERCENTAGE' ? '%' : item.branch.currency }}]
                                            </option>
                                        </select>
                                    </td>
                                    <td colspan="1">
                                        <strong class="text-danger">{{ item.branch.currency }}{{ helpers.number(item.discount_amount).toFixed(2) }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        {{ 'Delivery Type' | translate }}
                                    </td>
                                    <td colspan="1">
                                        <app-select2-transformer *ngIf="item.delivery_options.length > 0"
                                            [data]="item.delivery_options" 
                                            [options]="{}" 
                                            [disabled]="false" 
                                            (onValueChangeHandler)="deliverySelected($event, item)">
                                        </app-select2-transformer>
                                    </td>
                                    <td colspan="1">
                                        <strong class="text-success">
                                            {{ item.branch.currency }}{{ helpers.number(item.delivery_option.fee).toFixed(2) }}
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        {{ 'Payment Type' | translate }}
                                    </td>
                                    <td colspan="1">
                                        <app-select2-transformer *ngIf="item.payment_options.length > 0"
                                            [data]="item.payment_options" 
                                            [options]="{}" 
                                            [disabled]="false" 
                                            (onValueChangeHandler)="paymentSelected($event, item)">
                                        </app-select2-transformer>
                                    </td>
                                    <td colspan="1">
                                        <strong class="text-success">
                                            {{ item.branch.currency }}{{ helpers.number(item.payment_option.fee).toFixed(2) }}
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        {{ 'Notes' | translate }}
                                    </td>
                                    <td colspan="2">
                                        <input type="text" class="form-control" placeholder="{{ 'Notes' | translate }}" name="notes" [(ngModel)]="item.customer_notes">
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="total-shopping pt-70">
                            <h2>{{ 'Total Order' | translate }}</h2>
                            <h3>{{ 'Total' | translate }}: <span>{{cart.cart[0] ? cart.cart[0].currency : 'TL'}} {{ helpers.number(total).toFixed(2) }}</span></h3>
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#order-confirmation-modal">
                                {{ 'Confirm Order' | translate }}
                            </a>
                        </div>
                    </div>

                    <div class="modal fade" tabindex="-1" role="dialog" id="order-confirmation-modal">
                        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">{{ 'Confirm Order' | translate }}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <!-- <div class="modal-body" *ngIf="error">
                                    <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
                                </div> -->

                                <div class="modal-body">
                                    <div class="alert alert-primary" role="alert" *ngIf="helpers.number(address.id) > 0">
                                        <strong>{{ address.title }}</strong><br>
                                        {{ address.address }}
                                    </div>
                                    
                                    <div class="alert alert-danger" role="alert" *ngIf="helpers.number(address.id) == 0">
                                        <strong>{{ 'Please Notice' | translate }}</strong><br>
                                        {{ 'Please Select your address from list at the top' | translate }}
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button type="submit" class="btn btn-outline-danger btn-block" *ngIf="helpers.number(address.id) > 0" onclick="$('#close-confirm-modal').click()">
                                                {{cart.cart[0] ? cart.cart[0].currency : 'TL'}}{{ helpers.number(total).toFixed(2) }}
                                            </button>
                                            <button type="submit" class="btn btn-outline-danger btn-block" *ngIf="helpers.number(address.id) == 0" disabled="">
                                                {{cart.cart[0] ? cart.cart[0].currency : 'TL'}}{{ helpers.number(total).toFixed(2) }}
                                            </button>
                                        </div>
                                        <div class="col-md-6">
                                            <button type="button" class="btn btn-outline-warning btn-block" data-dismiss="modal" id="close-confirm-modal">{{ 'Back' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
        <div class="row" *ngIf="! is_logged_in">
            <div class="col-md-2"></div>
            <div class="col-md-8 pt-70 pb-70">
                <app-auth (onSuccessfullLogin)="loggedInSuccessfully($event)"></app-auth>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
    
    <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <section class="error-area" *ngIf="is_logged_in && success">
                    <div class="error-item">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="error-text">
                                    <h1 class="text-success">
                                        <i class="bx bx-check-circle"></i>
                                    </h1>
                                    <p class="text-success">{{ 'New Orders Created' | translate }}</p>
                                    <span>{{ 'Created Orders Codes' | translate }}</span>
                                    <p class="text-success">
                                        <span style="font-size: 24px; margin-top: 20px;">
                                            <strong *ngFor="let order_data of success; index as orderIndex;">{{ orderIndex > 0 ? ' - ' : '' }}{{ order_data.code }}</strong>
                                        </span>
                                    </p>
                                    <a [routerLink]="['/', helpers.localeObject.prefix]" style="margin-left: 10px; margin-right: 10px; margin-top: 20px;">
                                        {{ 'Home' | translate }}
                                    </a>
                                    <a [routerLink]="['/', helpers.localeObject.prefix, 'my-orders']" style="margin-left: 10px; margin-right: 10px; margin-top: 20px;">
                                        {{ 'My Orders' | translate }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" id="meal-cart-modal">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ 'Meal Details' | translate }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="padding: 2rem;">
                    <app-meal-popup (onAddToCart)="refreshData($event)"></app-meal-popup>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-warning" data-dismiss="modal">{{ 'Close' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
    

    <app-footer></app-footer>
</div>
