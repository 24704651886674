import { MessagingService } from './../../messaging.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  public order_id: number;

  public order   : any;

  constructor(private messagingService: MessagingService, public helpers: Helpers, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router, private Activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.Activatedroute.queryParamMap.subscribe(params => {
      this.order_id = Number(params.get('order_id')) || 0;
      this.loadOrder();
    });
    var vm = this;
    this.messagingService.addListener('ORDER_LIST', function(msg) {
      vm.loadOrder();
    });

    // this.messagingService.currentMessage.subscribe((data) => {
    //   console.log('Order Detail', data);
    // });
  }

  loadOrder() {
    let params = {
      order_id : this.order_id,
    };
    this.profileApi.orderDetails(params).subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'fail') {
        alert('No Internet');
      }
      else if (res['message'] == 'success') {
        this.order = res['data']['orders'][0] || null;
      }
    });
  }
}
