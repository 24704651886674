<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a [routerLink]="['/', helpers.localeObject.prefix]" class="logo">
            <img src="assets/img/logo-two.png" alt="Logo">
        </a>
    </div>

    <!-- <div class="main-nav {{nav_type == 'one' ? '' : nav_type}}"> -->
    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" [routerLink]="['/', helpers.localeObject.prefix]">
                    <!-- <img class="nav-two-logo-one" src="assets/img/logo-two.png" alt="Logo" *ngIf="nav_type != 'one'"> -->
                    <img class="nav-two-logo-two" src="assets/img/logo.png" alt="Logo" >
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a [routerLink]="['/', helpers.localeObject.prefix]" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">
                                {{ 'Home' | translate }}
                            </a>
                        </li>

                        <!-- <li class="nav-item">
                            <a [routerLink]="['/', helpers.localeObject.prefix, 'suggest-resturant']" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                {{ 'Suggest Resturant' | translate }}
                            </a>
                        </li> -->

                        <li class="nav-item">
                            <a [routerLink]="['/', helpers.localeObject.prefix, 'resturants']" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                {{ 'Resturants' | translate }}
                            </a>
                        </li>

                        <!-- <li class="nav-item">
                            <a [routerLink]="['/', helpers.localeObject.prefix, 'categories']" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                {{ 'Categories' | translate }}
                            </a>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a [routerLink]="['/', helpers.localeObject.prefix, 'meals']" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                {{ 'Meals' | translate }}
                            </a>
                        </li> -->


                        <li class="nav-item">
                            <ng-container *ngIf="nav_data">
                                <span class="offer_notfication" *ngIf="nav_data.offers_count != 0">
                                    {{ nav_data.offers_count }}
                                </span>
                            </ng-container>
                            <a [routerLink]="['/', helpers.localeObject.prefix, 'meals-offer']" [queryParams]="{ special_offers: 1 }" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                {{ 'Special Offers' | translate }}
                            </a>
                        </li>

                        <li class="nav-item">
                            <a [routerLink]="['/', helpers.localeObject.prefix, 'blogs']" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                {{ 'Blog' | translate }}
                            </a>
                        </li>

                        <li class="nav-item">
                            <a [routerLink]="['/', helpers.localeObject.prefix, 'page', 'about-us']" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                {{ 'About Us' | translate }}
                            </a>
                        </li>

                        <li class="nav-item">
                            <a [routerLink]="['/', helpers.localeObject.prefix, 'contact-us']" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                {{ 'Contact Us' | translate }}
                            </a>
                        </li>
                        <li class="nav-item">
                          <a href="https://blog.joaan.app/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                              {{ 'Joaan Blog' | translate }}
                          </a>
                        </li>
                        <!--
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>

                                <li class="nav-item"><a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/food-collection" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Food Collection</a></li>

                                <li class="nav-item"><a routerLink="/online-order" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Order</a></li>

                                <li class="nav-item"><a routerLink="/chefs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Chefs</a></li>

                                <li class="nav-item"><a routerLink="/book-table" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Book A Table</a></li>

                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                        -->

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">{{ 'Locale' | translate }} <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item last-items">
                                    <a href="javascript:void(0)" (click)="helpers.useLanguage('en')">{{ 'languages.en' | translate }}</a>
                                </li>
                                <li class="nav-item last-items">
                                    <a href="javascript:void(0)" (click)="helpers.useLanguage('ar')">{{ 'languages.ar' | translate }}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <div class="side-nav">
                        <a class="nav-cart" [routerLink]="['/', helpers.localeObject.prefix, 'cart']">
                            <ng-container *ngIf="nav_data">
                                <span class="offer_notfication" *ngIf="nav_data.cart_items_count != 0">
                                    {{ nav_data.cart_items_count }}
                                </span>
                            </ng-container>
                            <i class='bx bxs-cart'></i>
                            <span *ngIf="cart.cart.length > 0">{{ cart.cart.length }}</span>
                        </a>
                        <a class="nav-location" href="javascript:void(0)" data-toggle="modal" data-target="#top-nav-modal">
                            <i class='bx bxs-location-plus'></i>
                        </a>
                        <a class="nav-user" [routerLink]="['/', helpers.localeObject.prefix, 'login']" *ngIf="!isLoggedIn">
                            <i class='bx bxs-user'></i>
                        </a>
                        <a class="nav-user" href="javascript:void(0)" *ngIf="isLoggedIn" data-toggle="modal" data-target="#myModalRight">
                            <i class='bx bxs-edit'></i>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>

<div id="myModalRight" class="modal fade modal-right" tabindex="-1" role="dialog" *ngIf="isLoggedIn">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <img src="assets/img/logo.png" alt="Logo">
                <button type="button" class="close sidebar-nav-close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <ul class="sidebar-nav">
                    <li class="sidebar-nav-item">
                        <a [routerLink]="['/', helpers.localeObject.prefix, 'my-orders']" class="sidebar-nav-link">
                            <i class='bx bx-spreadsheet nav-icon-left'></i>
                            {{ 'My Orders' | translate }}
                        </a>
                    </li>
                    <li class="sidebar-nav-item">
                        <a [routerLink]="['/', helpers.localeObject.prefix, 'suggest-resturant']" class="sidebar-nav-link">
                            <i class='bx bx-plus nav-icon-left'></i>
                            {{ 'Suggest Resturant' | translate }}
                        </a>
                    </li>
                    <li class="sidebar-nav-item">
                        <a [routerLink]="['/', helpers.localeObject.prefix, 'coupons']" class="sidebar-nav-link">
                            <i class='bx bx-money nav-icon-left'></i>
                            {{ 'Coupons' | translate }}
                        </a>
                    </li>
                    <li class="sidebar-nav-item">
                        <a [routerLink]="['/', helpers.localeObject.prefix, 'favorites']" class="sidebar-nav-link">
                            <i class='bx bx-heart-circle nav-icon-left'></i>
                            {{ 'Favorites' | translate }}
                        </a>
                    </li>
                    <li class="sidebar-nav-item">
                        <a [routerLink]="['/', helpers.localeObject.prefix, 'addresses']" class="sidebar-nav-link">
                            <i class='bx bx-location-plus nav-icon-left'></i>
                            {{ 'My Addresses' | translate }}
                        </a>
                    </li>
                    <li class="sidebar-nav-item">
                        <a [routerLink]="['/', helpers.localeObject.prefix, 'account-settings']" class="sidebar-nav-link">
                            <i class='bx bx-edit nav-icon-left'></i>
                            {{ 'Account Settings' | translate }}
                        </a>
                    </li>
                    <li class="sidebar-nav-item">
                        <a href="javascript:void(0)" class="sidebar-nav-link text-danger" (click)="logout()">
                            <i class='bx bx-log-out nav-icon-left'></i>
                            {{ 'Logout' | translate }}
                        </a>
                    </li>
                </ul>
                <!-- <h2>About Us</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic aliquid quas qui minus! Dolor, ad. Odit, ullam perspiciatis nesciunt numquam explicabo, sunt ipsa libero ipsum maiores officia eius reprehenderit exercitationem.</p>
                <div class="image-area">
                    <h2>Instagram</h2>

                    <div class="row">
                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog1.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog2.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog3.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog4.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog5.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog6.jpg" alt="Instagram"></a>
                        </div>
                    </div>
                </div>
                <div class="social-area">
                    <h3>Our Social Contact</h3>

                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" id="top-nav-modal">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'Your Current Location' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="isLoggedIn">
                <div class="form-group search" *ngIf="addresses">
                    <label for="">{{ 'Address' | translate }}</label>
                    <select name="address" (change)="markerControll($event)" class="form-controll" style="width: 100%;">
                        <option [value]="address.id" *ngFor="let address of addresses">
                            {{ address.address }}
                        </option>
                    </select>
                    <!-- <app-select2-transformer
                        [data]="addresses"
                        [options]="{}"
                        [disabled]="false"
                        (onValueChangeHandler)="markerControll($event)">
                    </app-select2-transformer> -->
                </div>
            </div>
            <div class="modal-body" style="padding: 0;">
                <ng-container *ngIf="hasLatLng">
                    <app-google-map *ngIf="GPS.lat && GPS.lng"
                        [marginBottom]="0"
                        [elemId]="'top_nav_address_map_div'"
                        [zoom]="14"
                        [lat]="GPS.lng"
                        [lng]="GPS.lat"
                        [DefaultMarkerLat]="GPS.lng"
                        [DefaultMarkerLng]="GPS.lat"
                        (onAddMarker)="markerControll($event)">
                    </app-google-map>
                </ng-container>
                <ng-container *ngIf="!hasLatLng">
                    <app-location-required></app-location-required>
                </ng-container>
            </div>
            <div class="modal-footer">
                <ng-container *ngIf="hasLatLng">
                    <button type="button" class="btn btn-outline-success" (click)="setAutoLocation()" style="display: flex;">
                        <span style="margin-left: 5px; margin-right: 5px;">{{ 'Set Automatically' | translate }}</span>
                        <mat-progress-spinner *ngIf="auto_location_loading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                    <button type="button" class="btn btn-outline-danger" (click)="okClicked()" >{{ 'Confirm' | translate }}</button>
                </ng-container>
                <button type="button" class="btn btn-outline-warning" data-dismiss="modal">{{ 'Close' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<button data-toggle="modal" href="javascript:;" data-target="#please-complete-your-address-modal" style="display: none;" id="openAddressCompletionModal">
    MODAL
</button>
<div class="modal fade" tabindex="-1" role="dialog" id="please-complete-your-address-modal">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h5 class="modal-title">{{ 'Please Complete Your Address' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->
            <div class="modal-body" style="padding: 2rem;">
                <div class="text-center">
                    <h3 class="text-danger">{{ 'Please Complete Your Address' | translate }}</h3>
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <a [routerLink]="['/', helpers.localeObject.prefix, 'addresses']" class="btn btn-outline-danger btn-block" data-dismiss="modal">{{ 'My Addresses' | translate }}</a>
                            <!-- <a [routerLink]="['/', helpers.localeObject.prefix, 'addresses']" class="sidebar-nav-link">
                                <i class='bx bx-location-plus nav-icon-left'></i>
                                {{ 'My Addresses' | translate }}
                            </a> -->
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="display: none;">
                <button type="button" class="btn btn-outline-warning" data-dismiss="modal" id="close-navbar-address-completion-modal">{{ 'Close' | translate }}</button>
            </div>
        </div>
    </div>
</div>



<div class="row footer_button">
    <div class="box_nav">
        <a [routerLink]="['/', helpers.localeObject.prefix]"  routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" >
            <i class='bx bxs-home icon_nav'></i>
            {{ 'Home' | translate }}
        </a>
    </div>
    <div class="box_nav">
        <a class="nav-user" href="javascript:void(0)" *ngIf="isLoggedIn" data-toggle="modal" data-target="#myModalRight">
            <i class='bx bxs-user icon_nav'></i>
            حسابي
        </a>
    </div>
    <div class="box_nav">
        <a class="nav-cart" [routerLink]="['/', helpers.localeObject.prefix, 'cart']">
            <i class='bx bxs-cart icon_nav'></i>
            السلة
        </a>
    </div>
    <div class="box_nav">
        <a [routerLink]="['/', helpers.localeObject.prefix, 'meals-offer']" [queryParams]="{ special_offers: 1 }"  routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
            <i class='bx bxs-gift icon_nav'></i>
            {{ 'Special Offers' | translate }}
        </a>
    </div>
    <div class="box_nav">
        <a class="nav-user" href="javascript:void(0)" *ngIf="isLoggedIn" data-toggle="modal" data-target="#myModalRight">
        <i class='bx bxs-chat icon_nav'></i>
        محادثة مباشرة
        </a>
    </div>
</div>
