import { IndexService } from './../../services/index.service';
import { ResturantsService } from './../../services/resturants.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/models/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-resturants',
  templateUrl: './resturants.component.html',
  styleUrls: ['./resturants.component.scss']
})

export class ResturantsComponent implements OnInit {

  public listType         : 'DELIVERY' | 'TAKEAWAY' = 'DELIVERY';

  public sort_by          : string = '';

  public ratings          : string = '';

  public delivery_time_from   : string = '';

  public delivery_time_to     : string = '';

  public min_order_from   : string = '';

  public min_order_to     : string = '';

  public page       : number = 1;

  public has_more   : string = '0';

  public hasLatLng  : boolean = true;

  public resturants : any;
  
  public index      : any;

  public keyword    : string = '';

  public category_id: any = {id: 0, text: '--------'};

  public city_id    : any = {id: 0, text: '--------'};

  public takeaway   : boolean = false;

  constructor(
    public helpers: Helpers, 
    private resturantsApi: ResturantsService, 
    private indexApi: IndexService, 
    private UserServiceStorage: UserService,
    private Activatedroute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    let self = this;
    this.UserServiceStorage.setDeliveryType(this.listType);
    this.Activatedroute.queryParamMap.subscribe(params => {
      this.keyword     = params.get('keyword')        || null;
      this.category_id = {
        id: params.get('category')       || 0,
        text: params.get('category')     || '--------',
      };
    });
    Helpers.getLocation({
      success: function(lat, lng) {
        self.hasLatLng = true;
        self.loadIndex();
        self.loadRestaurants();
      },
      fail: function(err) {
        self.hasLatLng = false;
      },
      denied: function(err) {
        self.hasLatLng = false;
      },
    });
  }

  keywordSearch() {
    this.page = 1;
    this.loadRestaurants();
  }

  search() {
    this.page = 1;
    this.loadRestaurants();
  }

  onValueChangeHandlerFun(event) {
    this.category_id = event;
  }

  reset() {
    this.category_id = {id: 0, text: '--------'};
    this.city_id     = {id: 0, text: '--------'};
    this.keyword     = '';
    this.page        = 1;
    this.sort_by          = '';
    this.ratings           = '';
    this.delivery_time_from   = '';
    this.delivery_time_to     = '';
    this.min_order_from   = '';
    this.min_order_to     = '';
    this.loadRestaurants();
  }

  nextPage() {
    this.page++;
    this.loadRestaurants();
  }

  previousPage() {
    this.page--;
    this.loadRestaurants();
  }

  toggleTakeaway() {
    this.takeaway = this.takeaway ? false : true;
    this.listType = this.takeaway ? 'TAKEAWAY' : 'DELIVERY';
    this.UserServiceStorage.setDeliveryType(this.listType);
    this.resturants = null;
    this.page       = 1;
    this.loadRestaurants();
  }

  loadRestaurants() {
    let address_id = null;
    if (this.UserServiceStorage.isLoggedIn()) {
      address_id = this.UserServiceStorage.user.address.id;
    }
    let data = {
      address_id : address_id || null,
      search     : this.keyword,
      latitude   : Helpers.locationObject.lng,
      longitude  : Helpers.locationObject.lat,
      category_id: [this.category_id['id'] || 0],
      city_id    : [this.city_id['id']     || 0],

      sort_by          : this.sort_by,
      rating           : this.ratings,
      delivery_time_from   : this.delivery_time_from,
      delivery_time_to     : this.delivery_time_to,
      minimun_order_from   : this.min_order_from,
      minimun_order_to     : this.min_order_to,
    };
    if (this.takeaway) {
      data['takeaway_service'] = '1';
    }
    this.resturantsApi.loadDataV2(this.page, data)
    .subscribe((data) => { 
      this.resturants = data;
      this.has_more   = data['data']['more_available'];
    });
  }

  loadIndex() {
    this.indexApi.loadData({
      latitude  : Helpers.locationObject.lng,
      longitude : Helpers.locationObject.lat,
    })
    .subscribe((data) => { 
      data['data']['categories'].unshift({
        id: 0,
        text: '--------'
      });
      data['data']['cities'].unshift({
        id: 0,
        text: '--------'
      });
      this.index = data;
    });
  }
}
