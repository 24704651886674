<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Favorites' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Favorites' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <div class="header-filter">
                    <div class="header-filter-item">
                        <div class="header-filter-title">
                            {{ 'Keyword' | translate }}
                        </div>
                        <div class="header-filter-body">
                            <div class="form-group search">
                                <input type="text" [(ngModel)]="keyword" placeholder="{{ 'Keyword' | translate }}" class="form-control" (change)="keyword=$event.target.value">
                                <button type="button" class="btn search-btn" (click)="keywordSearch()"><i class='bx bxs-search'></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="header-filter-item">
                        <div class="header-filter-title">
                            {{ 'Filter' | translate }}
                        </div>
                        <div class="header-filter-body">
                            <div class="btn-actions row">
                                <div class="col-md-6">
                                    <button class="btn btn-sm btn-block btn-outline-warning" [ngClass]="{'active': type == 'DISH'}" (click)="opneDishs()">
                                        <strong>{{ 'Meals' | translate }}</strong>
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-sm btn-block btn-outline-warning" [ngClass]="{'active': type == 'BRANCH'}" (click)="opneBranchs()">
                                        <strong>{{ 'Resturants' | translate }}</strong>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="services-details-area pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-12" *ngIf="type == 'DISH' && favorites">
                    <div class="row">
                        <div class="col-sm-12 col-lg-3 mix web ui" *ngFor="let dish of favorites">
                            <app-product-item [item]="dish"></app-product-item>
                        </div>
                    </div>
                    <div class="collection-area">
                        <div class="more-collection">
                            <a href="javascript:void(0)" (click)="previousPage()" *ngIf="page > 1" class="text-danger">{{ 'Previous' | translate }}</a>

                            <a href="javascript:void(0)" (click)="nextPage()" *ngIf="has_more == '1'">{{ 'Load More' | translate }}</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="type == 'BRANCH' && favorites">
                    <div class="row">
                        <div class="col-sm-12 col-lg-4 mix web ui" *ngFor="let restaurant of favorites">
                            <app-resturant-card [restaurant]="restaurant"></app-resturant-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>