<div class="modal" style="display: flex !important;">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="header">
            <div class="title">{{ 'Meal Details' | translate }}</div>
            <button class="close-button"
                    mat-icon-button
                    (click)="closeEdit()"
                    [tabIndex]="-1"
                    matDialogClose>
                <!-- <mat-icon [svgIcon]="'close'"></mat-icon> -->
                <mat-icon>close</mat-icon>
        
            </button>
        </div>
        <ng-container *ngIf="item">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="meal-detail-card">
                                <div class="meal-image">
                                    <img [src]="item.dish_image || item.image" alt="" class="meal-img">
                                </div>
                                <!-- <div class="add-to-favorite">
                                    <span class="add-to-favorite-icon">
                                        <fa-icon [icon]="faHeart"></fa-icon>
                                    </span>
                                </div> -->
                    
                                <app-favorite-toggle [entity_type]="'DISH'" [entity_id]="item.dish_id" [branch_id]="item.branch_id" [active]="helper.number(item.is_favorite)" (onToggle)="favoriteAction($event)"></app-favorite-toggle>
                    
                                <div class="meal-info">
                                    
                                    <h2 class="meal-name">
                                        {{ item.title }}
                                    </h2>
                                    <div class="meal-rating">
                                        <app-rating [rating]="item.rating"></app-rating>
                                    </div>
                    
                                    <h2 class="meal-price">
                                        <strong>{{ item.restaurant.currency }} {{ item.cost }}</strong>
                                    </h2>
                    
                                    <div class="meal-categories">
                                        <div class="blog-details-tags" *ngIf="item.options.length > 0">
                                            <ul>
                                                <li *ngFor="let option of item.options">
                                                    <a href="javascript:;" (click)="toggleOption(option)" [ngClass]="{'active' : (options[option.id] ? true : false)}">
                                                        {{ option.text }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                    </div>
                    <div class="row meal-attributes-row">
                        <div class="col-sm-12" [ngClass]="{'col-lg-4': item.side_dishes.length >= 3, 'col-lg-6': item.side_dishes.length == 2, 'col-lg-12': item.side_dishes.length < 2}" *ngFor="let attribute of item.side_dishes">
                            <div class="form-group meal-attributes">
                                <label for="attr_{{ attribute.type }}">
                                    {{ attribute.title }}
                                </label>
                                <app-select2-transformer id="attr_{{ attribute.type }}" [data]="attribute.values" [currency]="item.restaurant.currency" [options]="{multiple: attribute.select_type != 'single_choice'}" [disabled]="false" (onValueChangeHandler)="onValueChangeHandlerFun($event)"></app-select2-transformer>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group meal-attributes">
                                <label for="attr_notes">
                                    {{ 'Notes' | translate }}
                                </label>
                                <textarea id="attr_notes" class="form-control" name="notes" rows="3" [(ngModel)]="note"></textarea>
                            </div>
                        </div>
                    
                    </div>
                    <div class="row" style="margin-top: 30px">
                        <div class="col-md-8">
                            <!-- <div class="meal-quantity number">
                                <div class="block">
                                    <span class="minus" (click)="decreseQuantity()">-</span>
                                </div>
                                <div class="block">
                                    <input type="text" class="form-control" [(ngModel)]="quantity"/>
                                </div>
                                <div class="block">
                                    <span class="plus" (click)="increaseQuantity()">+</span>
                                </div>
                                
                            </div> -->
                            <div class="btn-group meal-quantity number btn-group-sm" role="group">
                                <button type="button" class="btn btn-outline-warning" (click)="decreseQuantity()">
                                    <span>-</span>
                                </button>
                                <button type="button" class="btn btn-outline-warning btn-center">
                                    <input type="text" class="form-control" [(ngModel)]="quantity" readonly="" />
                                </button>
                                <button type="button" class="btn btn-outline-warning" (click)="increaseQuantity()">
                                    <span>+</span>
                                </button>
                            </div>
                            <div class="meal-total">
                                <h4>
                                    {{ 'Total' | translate }}: <span class="text-warning">{{item.restaurant.currency}} {{ mealObject ? helper.number(mealObject.total_price || '0.00').toFixed(2) : '0.00' }}</span>
                                </h4>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <!-- <button type="button" class="btn btn-warning btn-block btn-lg" (click)="addToCart()" data-dismiss="modal" aria-label="Close">
                                <strong>{{ 'Add To Cart' | translate }}</strong>
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
            
        </ng-container>
        
    </div>
</div>

