import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { MealDetailsService } from './../../services/meal-details.service';
import { Helpers } from './../../Helpers';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from './../../models/cart.service';
import { Component, OnInit, Input, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { UserService } from 'src/app/models/user.service';
import { ProfileService } from 'src/app/services/profile.service';
declare let $: any;

@Component({
  selector: 'app-product-modal-only',
  templateUrl: './product-modal-only.component.html',
  styleUrls: ['./product-modal-only.component.scss']
})
export class ProductModalOnlyComponent implements OnInit {

  @Input() item: any;

  @Input() addModal: boolean = true;

  // quantity: number = 1;

  faHeart = faHeart;

  public mealObject: any = {};

  @Input() meal: any;

  public note: string;

  public branch_id: number;

  public dish_id: number;

  public quantity: number = 1;

  public options = {};

  public attributes = [];

  public is_logged_in: boolean;

  constructor(
    private cart: CartService, 
    private router: Router, 
    public helper: Helpers, 
    private Activatedroute: ActivatedRoute, 
    private mealApi: MealDetailsService, 
    private UserServiceStorage: UserService,
    private _profileApi: ProfileService,
    private renderer: Renderer2
  ) { 
    
  }

  ngOnInit(): void {
    this.is_logged_in = this.UserServiceStorage.isLoggedIn();
    this.branch_id = this.item.branch_id || this.item.restaurant.id;
    this.dish_id   = this.item.dish_id   || this.item.id;
  }

  loggedInSuccessfully(event) {
    window.location.reload();
  }
  // increaseQuantity() {
  //   this.quantity++;
  // }

  // decreseQuantity() {
  //   if (this.quantity == 1) { return; }
  //   this.quantity--;
  // }

  // addToCart() {
  //   this.router.navigate(['/', this.helper.localeObject.prefix, 'meal', this.item.dish_id, this.item.branch_id]);
  //   return;
  // }

  favoriteAction(event) {
    if (event['message'] == 'success') {
      if (event['data']['result'] == 'removed_from_favorite_successfully') {
        this.meal['data']['dish']['is_favorite'] = '0';
      }
      else {
        this.meal['data']['dish']['is_favorite'] = '1';
      }
    }
  }

  onValueChangeHandlerFun(attributes, att_id, att_title) {
    delete this.attributes[ att_id ];
    if (attributes.id) {
      attributes.base_title     = att_title;
      this.attributes[ attributes.id ] = [attributes];
    }
    else {
      attributes.forEach(element => {
        element.base_title = att_title;
      });
      this.attributes[ att_id ] = attributes;
    }
    this.calculateTotal();
  }

  toggleOption(option) {
    if (this.options[ option.id ]) {
      delete this.options[ option.id ];
      return;
    }
    this.options[ option.id ] = option;
    this.calculateTotal();
  }

  increaseQuantity() {
    this.quantity++;
    this.calculateTotal();
  }

  decreseQuantity() {
    if (this.quantity == 1) { return; }
    this.quantity--;
    this.calculateTotal();
  }

  prepareObject() {
    let options = [];
    let attr    = [];
    Object.keys(this.options).forEach((key) => {
      if (this.options[ key ]) {
        options.push(this.options[ key ]);
      }
    });
    Object.keys(this.attributes).forEach((key) => {
      if (this.attributes[ key ]) {
        attr.push(this.attributes[ key ]);
      }
    });
    let mealObject = {
      note             : this.note || null,
      restaurant_id    : this.meal.data.dish.restaurant.id,
      restaurant_title : this.meal.data.dish.restaurant.title,
      restaurant_logo  : this.meal.data.dish.restaurant.logo,
      restaurant_rating: this.meal.data.dish.restaurant.rating,

      //Restaurnat Delivery Conditions
      delivery_service : this.meal.data.dish.restaurant.delivery_service,
      delivery_fee     : this.meal.data.dish.restaurant.delivery_fee,
      minimun_order    : Number(Number(this.meal.data.dish.restaurant.minimun_order || 0).toFixed(2)),
      
      //App Delivery Conditions
      app_delivery_customer_appearing: this.meal.data.dish.restaurant.app_delivery_customer_appearing,
      app_delivery_service : this.meal.data.dish.restaurant.app_delivery_service,
      app_delivery_fee     :  Number(Number(this.meal.data.dish.restaurant.app_delivery_fee  || 0).toFixed(2)),
      app_minimun_order    : this.meal.data.dish.restaurant.app_minimun_order,

      branch_id        : this.branch_id,
      branch_title     : this.meal.data.dish.branch_title || null,
      dish_id          : this.meal.data.dish.id,
      dish_image       : this.meal.data.dish.image,
      dish_title       : this.meal.data.dish.title,
      dish_type        : this.meal.data.dish.type,
      dish_rating      : this.meal.data.dish.rating,
      dish_rating_count: this.meal.data.dish.rating_count,
      dish_category    : this.meal.data.dish.dish_category,
      currency         : this.meal.data.dish.restaurant.currency,
      single_price     : Number(this.meal.data.dish.cost),
      attributes_price : 0,
      total_price      : 0,
      quantity         : this.quantity,
      options          : options.length == 0         ? [] : options,
      attributes       : attr,
    };
    mealObject.total_price = Helpers.calculateTotalMealPrice(mealObject);
    this.mealObject = mealObject;
    // console.log(this.mealObject);
  }

  calculateTotal() {
    this.prepareObject();
  }

  public addToFavorite() {
    this.mealApi.addToFavorite({
      branch_id: this.branch_id,
      dish_id  : this.dish_id,
    }).subscribe((data) => {
    });
  }

  public loadData(modal: string = null) {
    if(!this.is_logged_in) {
      this.router.navigate(['/', this.helper.localeObject.prefix, 'login']);
      return;
    }

    this.quantity   = 1;
    this.options    = {};
    this.attributes = [];

    this.mealApi.loadData({
      branch_id: this.branch_id,
      dish_id  : this.dish_id,
    })
    .subscribe((data) => {
      this.meal = data;
      this.calculateTotal();
    });
  }

  addToCart() {
    let data        = {
      dish_id       : this.mealObject.dish_id,
      branch_id     : this.mealObject.branch_id,
      delivery_type : "DELIVERY",
      quantity      : this.mealObject.quantity,
      options       : Object.keys(this.options)    || [],
      side_dishes   : Object.keys(this.attributes) || []
    };
    this.prepareObject();
    this._profileApi.addToCart(data)
    .subscribe((data) => {

    });
    // this.cart.add(this.mealObject);
  }
}
