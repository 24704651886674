import { ActivatedRoute } from '@angular/router';
import { IndexService } from './../../services/index.service';
import { DishesService } from './../../services/dishes.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/models/user.service';

@Component({
  selector: 'app-special-offers',
  templateUrl: './special-offers.component.html',
  styleUrls: ['./special-offers.component.scss']
})
export class SpecialOffersComponent implements OnInit {

  public loadOffers : boolean;

  public category_id: any;

  public keyword    : string;

  public index      : any;

  public meals      : any;

  public hasLatLng  : boolean = true;

  public page       : number = 1;

  public has_more   : string = '0';

  constructor(public helpers: Helpers, private mealsApi: DishesService, private indexApi: IndexService, private Activatedroute:ActivatedRoute, private UserServiceStorage: UserService) {
    this.Activatedroute.queryParamMap.subscribe(params => {
      this.keyword     = params.get('keyword')        || null;
      this.category_id = params.get('category')       || null;
      this.loadOffers  = params.get('special_offers') ? true : false;
    });
  }

  ngOnInit(): void {
    let self = this;
    Helpers.getLocation({
      success: function(lat, lng) {
        self.hasLatLng = true;
        self.loadIndex();
        self.loadMeals();
      },
      fail: function(err) {
        self.hasLatLng = false;
      },
      denied: function(err) {
        self.hasLatLng = false;
      },
    });
  }

  onValueChangeHandlerFun(event) {
    this.category_id = event;
  }

  keywordSearch() {
    if (! this.hasLatLng) {
      return;
    }
    this.page = 1;
    this.loadMeals();
  }

  search() {
    if (! this.hasLatLng) {
      return;
    }
    this.page = 1;
    this.loadMeals();
  }

  reset() {
    this.keyword     = '';
    this.page        = 1;
    this.category_id = {id: 0, text: '--------'};
    this.loadMeals();
  }

  nextPage() {
    this.page++;
    this.loadMeals();
  }

  previousPage() {
    this.page--;
    this.loadMeals();
  }

  loadMeals() {
    let params = {
      page           : this.page,
      search         : this.keyword || null,
      latitude       : Helpers.locationObject.lng,
      longitude      : Helpers.locationObject.lat,
      category_id    : [],
      special_offers : this.loadOffers
    };
    if (this.UserServiceStorage.isLoggedIn()) {
      params['address_id'] = this.UserServiceStorage.user.address.id;
    }
    if (typeof this.category_id == 'object' && this.category_id !== null) {
      params.category_id = [this.category_id.id];
    }
    else if(this.category_id) {
      params.category_id = [this.category_id];
    }
    this.mealsApi.loadDataV2(params).subscribe((data) => {
      this.meals    = data;
      this.has_more = data['data']['more_available'];
    });
  }

  loadIndex() {
    this.indexApi.loadData({
      latitude  : Helpers.locationObject.lng,
      longitude : Helpers.locationObject.lat,
    })
    .subscribe((data) => { 
      data['data']['categories'].unshift({
        id: 0,
        text: '--------'
      });
      data['data']['cities'].unshift({
        id: 0,
        text: '--------'
      });
      this.index = data;
    });
  }

}
