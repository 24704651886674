<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Blog Details' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Blog Details' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="services-details-area pt-70 pb-70">
        <div class="container">
            <div class="row" *ngIf="page">
                <div class="col-lg-12">
                    <div class="services-details-item">
                        <div class="services-details-fresh" style="text-align: right;">
                            <h2>{{ page.title }}</h2>
                            <img [src]="page.image" alt="Blog">
                            <div [innerHTML]="page.body"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>