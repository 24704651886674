import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Helpers } from 'src/app/Helpers';
import { UserService } from 'src/app/models/user.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-v2-auth',
  templateUrl: './v2-auth.component.html',
  styleUrls: ['./v2-auth.component.scss']
})
export class V2AuthComponent implements OnInit {

  public full_number: string  = '';

  public code: string = '';

  public country_code = 90;

  public phone_number = '';

  public step: number;

  public error: any;

  public errors: string[] = [];

  public full_name = '';
  
  public email     = '';

  public password  = '';

  private latitude;

  private longitude;

  @Output() onSuccessfullLogin : EventEmitter<any> = new EventEmitter();

  constructor(public helpers: Helpers, private profileApi: ProfileService, private UserServiceStorage: UserService) { }

  ngOnInit(): void {
    let self = this;
    Helpers.getLocation({
      success: function(lat, lng) {
        self.latitude  = Helpers.locationObject ? Helpers.locationObject.lat : '0';
        self.longitude = Helpers.locationObject ? Helpers.locationObject.lng : '0';
      }
    });
    this.step  = 1;
  }

  sendSuccessfullLogin(user) {
    this.onSuccessfullLogin.emit({user: user});
  }

  stepOne() {
    this.error  = null;
    this.errors = [];
    this.profileApi.loginByNumberV2({
      phone_number: this.parseNumber()
    })
    .subscribe((res) => {
      if (res['message'] == 'fail') {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      else if (res['message'] == 'success') {
        if (res['data']['result'] == 'code_sent_successfully') {
          this.step = 2;
        }
        // else if(res['data']['result'] == 'user_can_login_now_call_login_service') {
        //   this.step = 4;
        // }
        else {
          this.step = 2;
        }
      }
    });
  }

  stepTwo() {
    this.error  = null;
    this.errors = [];
    this.profileApi.loginByNumberAndCodeV2({
      phone_number: this.parseNumber(), 
      code        : this.code,
      latitude    : this.latitude,
      longitude   : this.longitude,
    })
    .subscribe((res) => {
      if (res['message'] == 'fail') {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      else if (res['data']['result'] == 'logged_in_successfully') {
        this.saveUserData(res['data']['user']);
        this.sendSuccessfullLogin(res['data']['user']);
      }
    });
  }

  saveUserData(ApiUser) {
    this.error  = null;
    this.errors = [];
    this.UserServiceStorage.save(ApiUser);
  }

  parseNumber() : string {
    this.full_number = `(+${this.country_code}) ${this.phone_number}`;
    return this.full_number;
  }

  returnBack(step?) {
    this.error  = null;
    this.errors = [];
    if (step) {
      this.step = step;
      return;
    }
    this.step--;
  }
}
