<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">
    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Suggest Resturant' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Suggest Resturant' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <section class="error-area pb-70 pt-70">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="book-table-area">
                        <div class="book-table-wrap">
                            <div class="section-title" style="margin-bottom: 20px;">
                                <h2 class="text-warning">{{ 'Suggest Resturant' | translate }}</h2>
                                <p>{{ 'Enter Address Details' | translate }}</p>
                                <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
                                <app-alert [type]="'success'" [title]="success"></app-alert>
                            </div>
                            <form (ngSubmit)="postData()">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Restaurant Name' | translate }}" name="title" [(ngModel)]="restaurant.title" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input dir="ltr" type="text" class="form-control" placeholder="{{ 'Phone Number' | translate }}" name="phone_number" [(ngModel)]="restaurant.phone_number">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Notes' | translate }}" name="notes" [(ngModel)]="restaurant.notes">
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>
                                                {{ 'Address' | translate }} <small class="text-danger">{{ 'You Can Select From The Map To Get Tha Address Automatically' | translate }}</small>
                                            </label>
                                            <textarea type="text" class="form-control" placeholder="{{ 'Address' | translate }}" name="full_addrss" [(ngModel)]="restaurant.address"></textarea>
                                        </div>
                                    </div>
        
                                    <div class="col-lg-12">
                                        <app-google-map [elemId]="'address_map_div'" [zoom]="14" [lat]="GPS.lng" [lng]="GPS.lat" [DefaultMarkerLat]="GPS.lng" [DefaultMarkerLng]="GPS.lat" (onAddMarker)="markerControll($event)"></app-google-map>
                                    </div>
                                    
                                </div>
                                <div class="text-center">
                                    <div class="row" style="margin-top: 30px;">
                                        <div class="col-md-12">
                                            <button type="submit" class="btn btn-warning btn-block">
                                                <strong>{{ 'Save' | translate }}</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
    
    
    
    <!-- <app-mobile-app></app-mobile-app> -->

    <app-footer></app-footer>
</div>
