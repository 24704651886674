<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Contact Us' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Contact Us' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="contact-location-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <img src="assets/img/home-one/services-shape.png" alt="Location">
                        <i class='bx bxs-time-five'></i>
                        <ul>
                            <li>9:00 AM to 12:00 PM</li>
                            <li>(Saturday to Thursday)</li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="location-item active">
                        <img src="assets/img/home-one/services-shape.png" alt="Location">
                        <i class='bx bxs-location-plus'></i>
                        <ul>
                            <li>Büyükdere cad. (Akabe iş merkezi) Likör yanı sk. No:1 K:1 - Şişli / İstanbul </li>
                    
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="location-item">
                        <img src="assets/img/home-one/services-shape.png" alt="Location">
                        <i class='bx bxs-phone-call'></i>
                        <ul>
                            <li><a href="tel:+908508889806" dir="ltr">+90 8508 8898 06</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contact-form-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="contact-item">
                        <form id="contactForm" (ngSubmit)="sendContactRequest()">
                            <div class="row">
                                <div class="col-sm-6 col-lg-12">
                                    <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
                                    <app-alert [type]="'success'" [title]="success"></app-alert>
                                </div>
                                <div class="col-sm-6 col-lg-12" *ngIf="!isLoggedIn">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="{{ 'Name' | translate }}" [(ngModel)]="contactData.name">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-12" *ngIf="!isLoggedIn">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="{{ 'Email' | translate }}" [(ngModel)]="contactData.email" dir="ltr">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-12" *ngIf="!isLoggedIn">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="{{ 'Phone Number' | translate }}" [(ngModel)]="contactData.phone_number" dir="ltr">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-12" *ngIf="isLoggedIn">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="{{ 'Name' | translate }}" [(ngModel)]="contactData.name" disabled="">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-12" *ngIf="isLoggedIn">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="{{ 'Email' | translate }}" [(ngModel)]="contactData.email" disabled="" dir="ltr">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-12" *ngIf="isLoggedIn">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="{{ 'Phone Number' | translate }}" [(ngModel)]="contactData.phone_number" disabled="" dir="ltr">
                                    </div>
                                </div>
        
                                <div class="col-sm-6 col-lg-12">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="{{ 'Subject' | translate }}" [(ngModel)]="contactData.subject">
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="{{ 'Message' | translate }}" [(ngModel)]="contactData.message"></textarea>
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-12">
                                    <button type="submit" class="cmn-btn btn">{{ 'Send' | translate }}</button>
                                </div>
                            </div>
                        </form>

                        <div class="contact-social" *ngIf="socialProfiles.FACEBOOK || socialProfiles.TWITTER || socialProfiles.INSTAGRAM || socialProfiles.YOUTUBE">
                            <span>{{ 'Follow Us on' | translate }}</span>

                            <ul>
                                <li><a href="{{ socialProfiles.FACEBOOK }}" *ngIf="socialProfiles.FACEBOOK" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="{{ socialProfiles.TWITTER }}" *ngIf="socialProfiles.TWITTER" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="{{ socialProfiles.INSTAGRAM }}" *ngIf="socialProfiles.INSTAGRAM" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="{{ socialProfiles.YOUTUBE }}" *ngIf="socialProfiles.YOUTUBE" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-6">
                    <div class="contact-img">
                        <img src="assets/img/contact-man.png" alt="Contact">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>