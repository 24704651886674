import { environment } from './../../environments/environment';
import { Router, UrlSerializer } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient, private router: Router, private serializer: UrlSerializer) { }

  private buildUrlString(data?: any) {
    data           = ! data      ? {} : data;
    data.page      = ! data.page ? 1  : data.page;

    let params = this.router.createUrlTree([''], { queryParams: data });
    return this.serializer.serialize(params).substring(1);
  }

  loginByNumberV2(Data) {
    return this.http.post( environment.API_BASE_URL + '/auth/v2/send_code', Data);
  }

  loginByNumberAndCodeV2(Data) {
    return this.http.post( environment.API_BASE_URL + '/auth/v2/verify_code', Data);
  }

  loginByNumber(MobileNumber) {
    return this.http.post( environment.API_BASE_URL + '/auth/enter_phone_number', {
      phone_number: MobileNumber
    });
  }

  loginByNumberAndCode(MobileNumber, Code, confirmation_type) {
    return this.http.post( environment.API_BASE_URL + '/auth/verify_code', {
      phone_number: MobileNumber,
      code        : Code,
      device_type : 'web',
      confirmation_type: confirmation_type,
    });
  }

  sendUserPersonalDetails(User) {
    return this.http.post( environment.API_BASE_URL + '/auth/create_new_user', User);
  }

  loginByPhoneAndPassword(Data) {
    return this.http.post( environment.API_BASE_URL + '/auth/login', Data);
  }

  saveUserNewData(Data) {
    return this.http.post( environment.API_BASE_URL + '/auth/update_account', Data);
  }

  addressesList(data?: any) {
    return this.http.get( environment.API_BASE_URL + '/addresses' + this.buildUrlString(data));
  }

  addNewAddress(Data) {
    return this.http.post( environment.API_BASE_URL + '/addresses/create', Data);
  }

  updateAddress(Data) {
    return this.http.post( environment.API_BASE_URL + '/addresses/update', Data);
  }

  updateAddressV2(Data) {
    return this.http.post( environment.API_BASE_URL + '/v2/addresses/update', Data);
  }

  setAddressAsDefault(Data) {
    return this.http.post( environment.API_BASE_URL + '/addresses/make_default', Data);
  }

  deleteAddress(Data) {
    return this.http.post( environment.API_BASE_URL + '/addresses/delete', Data);
  }

  suggestRestaurant(Data) {
    return this.http.post( environment.API_BASE_URL + '/restaurants/suggest_restaurant', Data);
  } 

  listCoupons(data) {
    return this.http.get( environment.API_BASE_URL + '/coupons' + this.buildUrlString(data));
  } 

  addCoupon(Data) {
    return this.http.post( environment.API_BASE_URL + '/coupons/get_coupon', Data);
  }

  // entity_type (required) (DISH,BRANCH)
  // entity_id (required)
  // branch_id (required if entity_type = DISH)
  toggleFavorite(Data) {
    return this.http.post( environment.API_BASE_URL + '/favorites/store', Data);
  }

  // type(required) (DISH, BRANCH)
  listFavorites(Data) {
    return this.http.get( environment.API_BASE_URL + '/favorites' + this.buildUrlString(Data));
  }

  // order_id (optional)
  // basket_id (optional)
  // customer_id (optional)
  // driver_id (optional) NOTE: filter (optional, must be: 'CURRENT_TRIP', 'CURRENT_ORDERS', or 'PREVIOUS_ORDERS')
  // branch_id (optional)
  // payment_method (optional, must be:'CASH' or 'BANKCARD')
  // type (optional: 'scheduled', 'not_scheduled', or don't send for all orders)
  listOrders(Data) {
    return this.http.get( environment.API_BASE_URL + '/orders' + this.buildUrlString(Data));
  }

  orderDetails(Data) {
    return this.http.get( environment.API_BASE_URL + '/orders' + this.buildUrlString(Data));
  }

  listBlogs(Data) {
    return this.http.get( environment.API_BASE_URL + '/contents/index/blog' + this.buildUrlString(Data));
  }

  blogDetails(Data) {
    return this.http.get( environment.API_BASE_URL + '/contents/show/' + Data['page_id']);
  }
  
  createOrder(Data) {
    return this.http.post( environment.API_BASE_URL + '/orders/create', Data);
  }

  sendContactFromGuest(Data) {
    return this.http.post( environment.API_BASE_URL + '/contact/guest', Data);
  }

  sendContactFromLoggedUser(Data) {
    return this.http.post( environment.API_BASE_URL + '/contact/user', Data);
  }

  // token (required)
  // device_type (required, must be: 'IOS' or 'ANDROID')
  // device_id (required)
  // locale (optional, must be: 'ar', 'en', or 'tr') if not provided, 'ar' will be set.
  sendFirebaseToken(Data) {
    return this.http.post( environment.API_BASE_URL + '/tokens/set_token', Data);
  }

  // delivery_type=DELIVERY&discount_method_type=NONE&discount_method_id=471
  getCart(data) {
    return this.http.get( environment.API_BASE_URL + '/v2/cart' + this.buildUrlString(data));
  }

  addToCart(data) {
    return this.http.post( environment.API_BASE_URL + '/v2/cart/add', data);
  }

  getCartItem(data) {
    return this.http.get( environment.API_BASE_URL + '/v2/cart/get_item' + this.buildUrlString(data));
  }

  updateItemFromCart(data) {
    return this.http.post( environment.API_BASE_URL + '/v2/cart/update_item', data);
  }
  
  removeItemFromCart(data) {
    return this.http.post( environment.API_BASE_URL + '/v2/cart/delete', data);
  }

  getCartCoupons(data) {
    return this.http.get( environment.API_BASE_URL + '/v2/cart/suitable_coupons' + this.buildUrlString(data));
  }

  createOrderV2(data) {
    return this.http.post( environment.API_BASE_URL + '/v2/orders/create', data);
  }

  getOffersV2(data) {
    return this.http.get( environment.API_BASE_URL + '/v2/offers' + this.buildUrlString(data));
  }

  getAreaPolygon(data?: any) : Observable<any> {
    return this.http.get(environment.API_BASE_URL + '/restaurants/get_area_information' +  this.buildUrlString(data));
  }

  loadAreaSelector(data?: any) : Observable<any> {
    return this.http.get( environment.API_BASE_URL + '/restaurants/get_all_neighborhoods_by_municipality' + this.buildUrlString(data));
  }

  getNavData(data?: any) : Observable<any> {
    return this.http.get( environment.API_BASE_URL + '/v2/index/nav' + this.buildUrlString(data));
  }
}
