<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Special Offers' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Special Offers' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <!-- <div class="header-filter">
                    <div class="header-filter-item">
                        <div class="header-filter-title">
                            {{ 'Keyword' | translate }}
                        </div>
                        <div class="header-filter-body">
                            <div class="form-group search">
                                <input type="text" [(ngModel)]="keyword" placeholder="{{ 'Keyword' | translate }}" class="form-control" (change)="keyword=$event.target.value">
                                <button type="button" class="btn search-btn" (click)="keywordSearch()"><i class='bx bxs-search'></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="header-filter-item">
                        <div class="header-filter-title">
                            {{ 'Categories' | translate }}
                        </div>
                        <div class="header-filter-body">
                            <div class="form-group search" *ngIf="index">
                                <app-select2-transformer [data]="index.data.categories" [options]="{}" [disabled]="false" (onValueChangeHandler)="onValueChangeHandlerFun($event)"></app-select2-transformer>
                            </div>
                        </div>
                    </div>

                    <div class="header-filter-item">
                        <div class="header-filter-title">
                            {{ 'Filter' | translate }}
                        </div>
                        <div class="header-filter-body">
                            <div class="btn-actions row">
                                <div class="col-md-6">
                                    <button class="btn btn-sm btn-block btn-outline-success" (click)="search()">{{ 'Search' | translate }}</button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-sm btn-block btn-outline-danger" (click)="reset()">{{ 'Reset' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                
            </div>
        </div>
    </div>

    <!-- <div class="services-details-area pb-70">
        <div class="container">
            <div class="row">

                <div class="col-md-12" *ngIf="!hasLatLng">
                    <app-location-permission-alert></app-location-permission-alert>
                </div>

                <div class="col-lg-12">
                    <div class="row" *ngIf="meals">
                        <div class="col-sm-12 col-lg-3 mix web ui" *ngFor="let dish of meals.data.dishes">
                            <app-product-item [item]="dish"></app-product-item>
                        </div>
                    </div>
                    <div class="collection-area">
                        <div class="more-collection">
                            <a href="javascript:void(0)" (click)="previousPage()" *ngIf="page > 1" class="text-danger">{{ 'Previous' | translate }}</a>

                            <a href="javascript:void(0)" (click)="nextPage()" *ngIf="has_more == '1'">{{ 'Load More' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <section class="blog-area pb-70">
        <div class="container">
            <div class="row" *ngIf="meals && hasLatLng" style="margin-top: 10px;">
                <div class="col-sm-12 col-lg-4" *ngFor="let blog of meals.data.offers">
                    <app-blog-item [blog]="blog"></app-blog-item>
                </div>  
                
               

                <div class="col-md-12">
                    <div class="collection-area" style="margin-top: 35px;">
                        <div class="more-collection">
                            <a href="javascript:void(0)" (click)="previousPage()" *ngIf="page > 1" class="text-danger">{{ 'Previous' | translate }}</a>
    
                            <a href="javascript:void(0)" (click)="nextPage()" *ngIf="has_more == '1'">{{ 'Load More' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!meals && hasLatLng">
                <div class="col-md-12 pt-70 pb-70">
                    <mat-progress-spinner mode="indeterminate" style="margin: auto;"></mat-progress-spinner>
                </div>
            </div>

            <div class="row" *ngIf="!hasLatLng">
                <div class="col-md-12">
                    <app-location-required></app-location-required>
                </div>
            </div>
        </div>
    </section>

    <app-footer></app-footer>

</div>