<div *ngIf="meal">
    <div class="row">
        <div class="col-md-12">
    
            <div class="meal-detail-card">
                <div class="meal-image">
                    <img [src]="meal.data.dish.image" alt="" class="meal-img">
                </div>
                <!-- <div class="add-to-favorite">
                    <span class="add-to-favorite-icon">
                        <fa-icon [icon]="faHeart"></fa-icon>
                    </span>
                </div> -->
    
                <app-favorite-toggle [entity_type]="'DISH'" [entity_id]="meal.data.dish.id" [branch_id]="branch_id" [active]="helpers.number(meal.data.dish.is_favorite)" (onToggle)="favoriteAction($event)"></app-favorite-toggle>
    
                <div class="meal-info">
                    
                    <h2 class="meal-name">
                        {{ meal.data.dish.title }}
                    </h2>
                    <div class="meal-rating">
                        <app-rating [rating]="meal.data.dish.rating"></app-rating>
                    </div>
    
                    <h2 class="meal-price">
                        <strong>{{ meal.data.dish.restaurant.currency }} {{ meal.data.dish.cost }}</strong>
                    </h2>
    
                    <div class="meal-categories">
                        <div class="blog-details-tags" *ngIf="meal.data.dish.options.length > 0">
                            <ul>
                                <li *ngFor="let option of meal.data.dish.options">
                                    <a href="javascript:;" (click)="toggleOption(option)" [ngClass]="{'active' : (options[option.id] ? true : false)}">
                                        {{ option.text }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div class="row meal-attributes-row">
        <div class="col-sm-12" [ngClass]="{'col-lg-4': meal.data.dish.side_dishes.length >= 3, 'col-lg-6': meal.data.dish.side_dishes.length == 2, 'col-lg-12': meal.data.dish.side_dishes.length < 2}" *ngFor="let attribute of meal.data.dish.side_dishes">
            <div class="form-group meal-attributes">
                <label for="attr_{{ attribute.type }}">
                    {{ attribute.title }}
                </label>
                <app-select2-transformer 
                    id="attr_{{ attribute.type }}" 
                    [data]="attribute.values" 
                    [currency]="meal.data.dish.restaurant.currency" 
                    [options]="{multiple: attribute.select_type != 'single_choice'}" 
                    [disabled]="false" (onValueChangeHandler)="onValueChangeHandlerFun($event, attribute.type, attribute.title)"
                ></app-select2-transformer>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group meal-attributes">
                <label for="attr_notes">
                    {{ 'Notes' | translate }}
                </label>
                <textarea id="attr_notes" class="form-control" name="notes" rows="3" [(ngModel)]="note"></textarea>
            </div>
        </div>
    
    </div>
    <div class="row" style="margin-top: 30px">
        <div class="col-md-8">
            <!-- <div class="meal-quantity number">
                <div class="block">
                    <span class="minus" (click)="decreseQuantity()">-</span>
                </div>
                <div class="block">
                    <input type="text" class="form-control" [(ngModel)]="quantity"/>
                </div>
                <div class="block">
                    <span class="plus" (click)="increaseQuantity()">+</span>
                </div>
                
            </div> -->
            <div class="btn-group meal-quantity number btn-group-sm" role="group">
                <button type="button" class="btn btn-outline-warning" (click)="decreseQuantity()">
                    <span>-</span>
                </button>
                <button type="button" class="btn btn-outline-warning btn-center">
                    <input type="text" class="form-control" [(ngModel)]="quantity" readonly="" />
                </button>
                <button type="button" class="btn btn-outline-warning" (click)="increaseQuantity()">
                    <span>+</span>
                </button>
            </div>
            <div class="meal-total">
                <h4>
                    {{ 'Total' | translate }}: <span class="text-warning">{{meal.data.dish.restaurant.currency}} {{ helpers.number(mealObject.total_price || '0.00').toFixed(2) }}</span>
                </h4>
            </div>
        </div>
        <div class="col-md-4">
            <button type="button" class="btn btn-warning btn-block btn-lg" (click)="addToCart()" data-dismiss="modal" aria-label="Close">
                <strong>{{ 'Add To Cart' | translate }}</strong>
            </button>
        </div>
    </div>
</div>
