<ng-container *ngIf="is_logged_in && addModal">
    <a (click)="loadData()" [attr.id]="'meal-modal-trigger-' + item.dish_id" style="display: none !important;"></a>
    <div class="modal fade" tabindex="-1" role="dialog" [attr.id]="'meal-modal-' + item.dish_id">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ 'Meal Details' | translate }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="padding: 2rem;" *ngIf="meal">
                    <div>
                        <div class="row">
                            <div class="col-md-12">
                        
                                <div class="meal-detail-card">
                                    <div class="meal-image">
                                        <img [src]="meal.data.dish.image" alt="" class="meal-img">
                                    </div>
                                    <!-- <div class="add-to-favorite">
                                        <span class="add-to-favorite-icon">
                                            <fa-icon [icon]="faHeart"></fa-icon>
                                        </span>
                                    </div> -->
                        
                                    <app-favorite-toggle [entity_type]="'DISH'" [entity_id]="meal.data.dish.id" [branch_id]="branch_id" [active]="helper.number(meal.data.dish.is_favorite)" (onToggle)="favoriteAction($event)"></app-favorite-toggle>
                        
                                    <div class="meal-info"> 
                                        

                                        <h2 class="meal-name">
                                            {{ meal.data.dish.title }}
                                        </h2>

                                        <p class="meal-name">
                                            {{ meal.data.dish.description }}
                                        </p>
                                        
                                        <h2 class="meal-price" *ngIf="item.discount == '0'">
                                            <strong> {{ helper.number(mealObject.total_price || '0.00').toFixed(2) }} {{meal.data.dish.restaurant.currency}}</strong>
                                        </h2>
                                        
                                        <h2 class="meal-price" *ngIf="item.discount != '0'">
                                            <strong> {{ helper.number(mealObject.total_price || '0.00').toFixed(2) }} {{meal.data.dish.restaurant.currency}}</strong>
                                            <span class="old_price2">{{ meal.data.dish.discount }} {{ meal.data.dish.restaurant.currency }}</span>
                                        </h2>
                        
                                        <div class="meal-categories">
                                            <div class="blog-details-tags" *ngIf="meal.data.dish.options.length > 0">
                                                <ul>
                                                    <li *ngFor="let option of meal.data.dish.options">
                                                        <a href="javascript:;" (click)="toggleOption(option)" [ngClass]="{'active' : (options[option.id] ? true : false)}">
                                                            {{ option.text }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                        
                            </div>
                        </div>

                        <div class="row" style="margin-top: 30px">
                            <div class="col-md-4">
                               
                            </div>
                            <div class="col-md-4">
                                <div class="btn-group meal-quantity number btn-group-sm" role="group">
                                    <button type="button" class="btn btn-outline-warning" (click)="decreseQuantity()">
                                        <span>-</span>
                                    </button>
                                    <button type="button" class="btn btn-outline-warning btn-center">
                                        <input type="text" class="form-control" [(ngModel)]="quantity" readonly="" />
                                    </button>
                                    <button type="button" class="btn btn-outline-warning" (click)="increaseQuantity()">
                                        <span>+</span>
                                    </button>
                                </div>
                               
                            </div>
                            <div class="col-md-4" *ngIf="meal.data.dish.restaurant.online == 1">
                                <button type="button" class="btn btn-warning btn-block btn-lg" (click)="addToCart()" data-dismiss="modal" aria-label="Close">
                                    <strong>{{ 'Add To Cart' | translate }}</strong>
                                </button>
                            </div>

                            <div class="col-md-4" *ngIf="meal.data.dish.restaurant.online == 0">
                                <button type="button" class="btn btn-warning_2 btn-block btn-lg"  >
                                    <strong>{{ 'restaurant_close' | translate }}</strong>
                                </button> 
                            </div> 
                        </div>

                        
                        <div class="row meal-attributes-row">
                            <div class="col-sm-12" [ngClass]="{'col-lg-4': meal.data.dish.side_dishes.length >= 3, 'col-lg-6': meal.data.dish.side_dishes.length == 2, 'col-lg-12': meal.data.dish.side_dishes.length < 2}" *ngFor="let attribute of meal.data.dish.side_dishes">
                                <div class="form-group meal-attributes">
                                    <label for="attr_{{ attribute.type }}">
                                        {{ attribute.title }}
                                    </label>
                                    <app-select2-transformer id="attr_{{ attribute.type }}" [data]="attribute.values" [currency]="meal.data.dish.restaurant.currency" [options]="{multiple: attribute.select_type != 'single_choice'}" [disabled]="false" (onValueChangeHandler)="onValueChangeHandlerFun($event)"></app-select2-transformer>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group meal-attributes">
                                    <label for="attr_notes">
                                        {{ 'Notes' | translate }}
                                    </label>
                                    <textarea id="attr_notes" class="form-control" name="notes" rows="3" [(ngModel)]="note"></textarea>
                                </div>
                            </div>
                        
                        </div>
                        
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-warning" data-dismiss="modal">{{ 'Close' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>