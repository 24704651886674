import { UserService } from './../../models/user.service';
import { ProfileService } from './../../services/profile.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public full_number: string  = '';

  public code: string = '';

  public country_code = 90;

  public phone_number = '';

  public step: number;

  public error: any;

  public errors: string[] = [];

  public full_name = '';
  
  public email     = '';

  public password  = '';

  private latitude;

  private longitude;

  @Output() onSuccessfullLogin : EventEmitter<any> = new EventEmitter();
  
  constructor(public helpers: Helpers, private profileApi: ProfileService, private UserServiceStorage: UserService) { }

  ngOnInit(): void {
    let self = this;
    Helpers.getLocation({
      success: function(lat, lng) {
        self.latitude  = Helpers.locationObject ? Helpers.locationObject.lat : '0';
        self.longitude = Helpers.locationObject ? Helpers.locationObject.lng : '0';
      }
    });
    this.step  = 1;
  }

  sendSuccessfullLogin(user) {
    this.onSuccessfullLogin.emit({user: user});
  }

  stepOne() {
    this.error  = null;
    this.errors = [];
    this.profileApi.loginByNumber(this.parseNumber()).subscribe((res) => {
      if (res['message'] == 'fail') {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      else if (res['message'] == 'success') {
        if (res['data']['result'] == 'code_sent_successfully') {
          this.step = 2;
        }
        else if(res['data']['result'] == 'user_can_login_now_call_login_service') {
          this.step = 4;
        }
        else {
          this.step = 4;
        }
      }
    });
  }

  stepTwo() {
    this.error  = null;
    this.errors = [];
    this.profileApi.loginByNumberAndCode(this.parseNumber(), this.code, 'account_verification').subscribe((res) => {
      if (res['message'] == 'fail') {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      else if (res['data']['result'] == 'account_is_now_verified_go_to_register_view') {
        this.step = 3;
      }
    });
  }

  stepThree() {
    this.error  = null;
    this.errors = [];
    this.profileApi.sendUserPersonalDetails({
      account_type          : 'CUSTOMER',
      registration_type     : 'NORMAL',
      device_type           : 'web',
      latitude              : this.latitude,
      longitude             : this.longitude,
      phone_number          : this.full_number,
      name                  : this.full_name,
      email                 : this.email,
      password              : this.password,
      password_confirmation : this.password,
    })
    .subscribe((res) => {
      if (res['message'] == 'fail') {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      else if (res['data']['result'] == 'logged_in_successfully') {
        this.saveUserData(res['data']['user']);
        this.sendSuccessfullLogin(res['data']['user']);
      }
    });
  }

  stepFour() {
    this.error  = null;
    this.errors = [];
    this.profileApi.loginByPhoneAndPassword({
      device_type           : 'web',
      phone_number          : this.full_number,
      password              : this.password,
    })
    .subscribe((res) => {
      if (res['message'] == 'fail') {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
      else if (res['data']['result'] == 'logged_in_successfully') {
        this.saveUserData(res['data']['user']);
        this.sendSuccessfullLogin(res['data']['user']);
      }
    });
  }

  saveUserData(ApiUser) {
    this.error  = null;
    this.errors = [];
    this.UserServiceStorage.save(ApiUser);
  }

  parseNumber() : string {
    this.full_number = `(+${this.country_code}) ${this.phone_number}`;
    return this.full_number;
  }

  returnBack(step?) {
    this.error  = null;
    this.errors = [];
    if (step) {
      this.step = step;
      return;
    }
    this.step--;
  }
}
