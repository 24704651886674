import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { MessagingService } from './../../messaging.service';
import { Helpers } from './../../Helpers';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-item',
  templateUrl: './blog-item.component.html',
  styleUrls: ['./blog-item.component.scss']
})
export class BlogItemComponent implements OnInit {

  @Input() blog;

  constructor(private messagingService: MessagingService, public helpers: Helpers, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router, private readonly translateService: TranslateService) { }

  ngOnInit(): void {
  }

  trim(string, length) {
    return string.substring(0, length);
  }
}
