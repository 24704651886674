import { environment } from './../../environments/environment';
import { Router, UrlSerializer } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DishesService {

  constructor(private http: HttpClient, private router: Router, private serializer: UrlSerializer) { }

  loadData(filter?: any) {
    filter           = ! filter      ? {} : filter;
    filter.page      = ! filter.page ? 1  : filter.page;

    let params = this.router.createUrlTree([''], { queryParams: filter });
    let urlString = this.serializer.serialize(params).substring(1);

    return this.http.get( environment.API_BASE_URL + '/dishes' + urlString);
  }

  loadDataV2(filter?: any) {
    filter           = ! filter      ? {} : filter;
    filter.page      = ! filter.page ? 1  : filter.page;

    let params = this.router.createUrlTree([''], { queryParams: filter });
    let urlString = this.serializer.serialize(params).substring(1);

    return this.http.get( environment.API_BASE_URL + '/v2/offers' + urlString);
  }
}
