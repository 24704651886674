import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-alert',
  templateUrl: './validation-alert.component.html',
  styleUrls: ['./validation-alert.component.scss']
})
export class ValidationAlertComponent implements OnInit {

  @Input() error: string;

  @Input() errors: string[];

  constructor() { }

  ngOnInit(): void {
  }
}
