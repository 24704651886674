import { Helpers } from './Helpers';
import { UserService } from './models/user.service';
import { LoadingService } from './services/loading.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

import { MessagingService } from './messaging.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, AfterViewInit {
    location: any;
    routerSubscription: any;
    isGoogleLoaded: boolean = false;

    constructor(private router: Router, private loadingService: LoadingService, private messagingService: MessagingService, private UserServiceStorage: UserService) {
    //    this.router.events
    //     .pipe(filter(event => event instanceof NavigationEnd))
    //     .subscribe(event => {
    //         if (this.isGoogleLoaded) {
    //             return;
    //         }
    //         $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyCiBmR5kDnIiGGfDp6CHfIcnLsCSdu8w2c');
    //         this.isGoogleLoaded = true;
    //     });
    }

    ngOnInit() {
        this.recallJsFuntions();
        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        // console.log('AppComponent Messaging');
        // console.log(this.messagingService.currentMessage);
    }

    ngAfterViewInit(): void {
        
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('#close-navbar-address-completion-modal').click();
                $('.sidebar-nav-close').click();
                this.loadingService.setLoading(true);
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $('#close-navbar-address-completion-modal').click();
            // console.log(this.router.url);
            $.getScript('../assets/js/custom.js');
            // $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyCiBmR5kDnIiGGfDp6CHfIcnLsCSdu8w2c');
            setTimeout(() => {
                this.loadingService.setLoading(false);
            }, 500);
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}