import { Router } from '@angular/router';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suggest-resturant',
  templateUrl: './suggest-resturant.component.html',
  styleUrls: ['./suggest-resturant.component.scss']
})
export class SuggestResturantComponent implements OnInit {

  public restaurant : any = {};

  public error      : any;

  public errors     : any = [];
  
  public success    : any;

  public geoCoder   : any;

  public GPS        : any = {lat: '0', lng: '0'};

  constructor(public helpers: Helpers, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router) { }

  ngOnInit(): void {
    if (! this.UserServiceStorage.isLoggedIn()) {
      this.router.navigate(['/', this.helpers.localeObject.prefix]);
      return;
    }

    let self = this;
    Helpers.getLocation({
      success: function(lat, lng) {
        self.GPS = Helpers.locationObject || {lng: '0', lat: '0'};
      }
    });
  }

  getGeoCoder() {
    if (! this.geoCoder) {
      this.geoCoder = new google.maps.Geocoder();
    }
    return this.geoCoder;
  }

  postData() {
    this.profileApi.suggestRestaurant(this.restaurant)
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        this.success = ParsedResponse['result'];
      }
      else {
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
    });
  }

  markerControll(data) {
    let self = this;
    Helpers.getAddressFromLatLng(this.getGeoCoder(), {lat: data.event.latLng.lat(), lng: data.event.latLng.lng()}, {
      ready: function(status, results) {
        self.restaurant['country']      = null;
        self.restaurant['city']         = null;
        self.restaurant['municipality'] = null;
        self.restaurant['neighborhood'] = null;
        self.restaurant['postal_code']  = null;
        self.restaurant['street_no']    = null;
        self.restaurant['street']       = null;
        self.restaurant['latitude']     = null;
        self.restaurant['longitude']    = null;
        self.restaurant['address']      = null;
      },
      success: function(parsedAddress) {
        self.restaurant['country']      = parsedAddress.country;
        self.restaurant['city']         = parsedAddress.city;
        self.restaurant['municipality'] = parsedAddress.municipality;
        self.restaurant['neighborhood'] = parsedAddress.neighborhood;
        self.restaurant['postal_code']  = parsedAddress.postal;
        self.restaurant['street_no']    = parsedAddress.street_no;
        self.restaurant['street']       = parsedAddress.street;
        self.restaurant['latitude']     = Number(parsedAddress.lat).toFixed(16);
        self.restaurant['longitude']    = Number(parsedAddress.lng).toFixed(16);
        self.restaurant['address']      = parsedAddress.formated;
      },
      noResults: function(reason) {
        console.log(reason);
      },
      geocoderFailed: function(status) {
        console.log(status);
      }
    });
  }
}
