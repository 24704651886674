import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public date: Date = new Date();

  public year: any;

  constructor(public helpers: Helpers) {
    this.year = this.date.getFullYear();
  }

  ngOnInit(): void {
  }
}
