import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-select2-transformer',
  templateUrl: './select2-transformer.component.html',
  styleUrls: ['./select2-transformer.component.scss']
})
export class Select2TransformerComponent implements OnInit {

  @Input() selected = null;

  @Input() data     = [];

  @Input() options  = {};

  @Input() disabled = false;

  @Input() id       = null;

  @Input() currency = '';

  @Input() useName  : string;

  @Output() onValueChangeHandler: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.data.forEach((item, key) => {
      if (! this.useName) {
        if (item.key) {
          this.data[key]['value'] = item.key;
        }
        this.data[key]['text'] = (item.text || item.title || item.name) 
        + ((Number(item.price) > 0) ? ' [' + this.currency + item.price + ']' : '')
        + (item.address ? ' - [' + item.address + ']' : '');
      }
      else {
        if (item.key) {
          this.data[key]['value'] = item.key;
        }
        this.data[key]['text'] = (item[this.useName]) + ((Number(item.price) > 0) ? ' [' + this.currency + item.price + ']' : '');
      }
    });
  }

  sendToParent(event) {
    this.onValueChangeHandler.emit(event);
  }
}
