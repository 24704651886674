import { Router } from '@angular/router';
import { MealPopupComponent } from './../../includes/meal-popup/meal-popup.component';
import { IndexService } from './../../services/index.service';
import { Helpers } from './../../Helpers';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { BehaviorSubject, Subject } from 'rxjs';
declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  private _unsubscribeAll : Subject<any | null>;

  public meal_keyword: string;

  public hasLatLng: boolean = false;

  public index: any;

  public addresses: any[];

  public isLoggedIn: boolean;

  @ViewChild(MealPopupComponent) MealPopup: MealPopupComponent;
  
  constructor(public helpers: Helpers, private indexApi: IndexService, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router) { 
    if (this.UserServiceStorage.isLoggedIn()) {
      this.isLoggedIn = this.UserServiceStorage.isLoggedIn();
      if (this.isLoggedIn) {
        this.loadAddresses();
      }
    }
    this.UserServiceStorage.UserData().subscribe((User) => {
      this.isLoggedIn = this.UserServiceStorage.isLoggedIn();
      if (this.isLoggedIn) {
        this.loadAddresses();
      }
    });

    this._unsubscribeAll = new Subject();
  }

  markerControll(data) {
    Helpers.setPostion(data.latitude, data.longitude);
    // this.loadIndex();
  }

  ngOnDestroy() : void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    let self = this;
    Helpers.getLocation({
      success: function(lat, lng) {
        self.hasLatLng = true;
        self.loadIndex();
      },
      fail: function(err) {
        self.hasLatLng = false;
      },
      denied: function(err) {
        self.hasLatLng = false;
      },
    });    
  }
  loadAddresses() {
    this.profileApi.addressesList({
      page: 1
    })
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        var lang_text = '';
        if(this.helpers.localeObject.prefix == 'en'){
          lang_text = 'Select Location';
        }else{
          lang_text = 'اختر الموقع';
        }
        res['data']['addresses'].unshift({
          id  : 0,
          text: lang_text
        });
        // this.has_more  = res['data']['more_available'];
        this.addresses = res['data']['addresses'];
      }
    });
  }
  loadIndex() {
    this.indexApi.loadData({
      latitude  : Helpers.locationObject.lng,
      longitude : Helpers.locationObject.lat,
    })
    .subscribe((data) => { 
      this.index = data;
      setTimeout(() => {
        $.getScript('../assets/js/custom.js').then(() => {
          // console.log('Script Loaded');
        });
      }, 500);
    });
  }

  openDishSlider(dish_id, branch_id) {
    this.MealPopup.startLoading({
      branch_id  : branch_id,
      dish_id    : dish_id,
      quantity   : 1,
      options    : {},
      attributes : [],
      cartKey    : null,
    });
  }

  openRestaurantSlider(resturant_id) {
    this.router.navigate(['/', this.helpers.localeObject.prefix, 'resturant', resturant_id]);
  }
}
