import { environment } from './../environments/environment';
import { LoadingService } from './services/loading.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgxWebstorageModule } from 'ngx-webstorage';
import { LSelect2Module } from 'ngx-select2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { LoadingHttpInterceptor } from './LoadingHttpInterceptor';
import { AppComponent } from './app.component';
import { NavbarComponent } from './includes/navbar/navbar.component';
import { FooterComponent } from './includes/footer/footer.component';
import { PreloaderComponent } from './includes/preloader/preloader.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { CustomWebPageComponent } from './pages/custom-web-page/custom-web-page.component';
import { ResturantsComponent } from './pages/resturants/resturants.component';
import { ResturantDetailsComponent } from './pages/resturant-details/resturant-details.component';
import { MealsComponent } from './pages/meals/meals.component';
import { MealDetailsComponent } from './pages/meal-details/meal-details.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RatingComponent } from './includes/rating/rating.component';
import { ProductRatingComponent } from './includes/product-rating/product-rating.component';
import { ProductItemComponent } from './includes/product-item/product-item.component';
import { ProductItemV2Component } from './includes/product-item-v2/product-item.component';
import { CartListComponent } from './pages/cart-list/cart-list.component';
import { LocationPermissionAlertComponent } from './includes/location-permission-alert/location-permission-alert.component';
import { ResturantCardComponent } from './includes/resturant-card/resturant-card.component';
import { MobileAppComponent } from './includes/mobile-app/mobile-app.component';
import { SuggestResturantComponent } from './pages/suggest-resturant/suggest-resturant.component';
import { Select2TransformerComponent } from './includes/select2-transformer/select2-transformer.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { ValidationAlertComponent } from './includes/validation-alert/validation-alert.component';
import { AlertComponent } from './includes/alert/alert.component';
import { AddressesComponent } from './pages/addresses/addresses.component';
import { GoogleMapComponent } from './includes/google-map/google-map.component';
import { CouponsComponent } from './pages/coupons/coupons.component';
import { FavoritesComponent } from './pages/favorites/favorites.component';
import { FavoriteToggleComponent } from './includes/favorite-toggle/favorite-toggle.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { AuthComponent } from './includes/auth/auth.component';
import { OrdersListComponent } from './pages/orders-list/orders-list.component';
import { SpecialOffersComponent } from './pages/special-offers/special-offers.component';
import { MealPopupComponent } from './includes/meal-popup/meal-popup.component';
import { SliderMealPopupComponent } from './includes/slider-meal-popup/slider-meal-popup.component';

import { MessagingService } from './messaging.service';
import{AngularFireMessagingModule}from'@angular/fire/messaging';
import { AsyncPipe } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { BlogItemComponent } from './includes/blog-item/blog-item.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogSingleComponent } from './pages/blog-single/blog-single.component';
import { OrderItemComponent } from './includes/order-item/order-item.component';
import { V2AuthComponent } from './includes/v2-auth/v2-auth.component';
import { V2CartComponent } from './pages/v2-cart/v2-cart.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CartItemUpdateComponent } from './includes/cart-item-update/cart-item-update.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { V2HomeComponent } from './pages/v2-home/v2-home.component';
import { ProductModalOnlyComponent } from './includes/product-modal-only/product-modal-only.component';
import { LocationRequiredComponent } from './includes/location-required/location-required.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    PreloaderComponent,
    HomeComponent,
    ContactUsComponent,
    CustomWebPageComponent,
    ResturantsComponent,
    ResturantDetailsComponent,
    MealsComponent,
    MealDetailsComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    RatingComponent,
    ProductRatingComponent,
    ProductItemComponent,
    ProductItemV2Component,
    CartListComponent,
    LocationPermissionAlertComponent,
    ResturantCardComponent,
    MobileAppComponent,
    SuggestResturantComponent,
    Select2TransformerComponent,
    AccountSettingsComponent,
    ValidationAlertComponent,
    AlertComponent,
    AddressesComponent,
    GoogleMapComponent,
    CouponsComponent,
    FavoritesComponent,
    FavoriteToggleComponent,
    CheckoutComponent,
    AuthComponent,
    OrdersListComponent,
    SpecialOffersComponent,
    MealPopupComponent,
    SliderMealPopupComponent,
    OrderDetailsComponent,
    BlogItemComponent,
    BlogComponent,
    BlogSingleComponent,
    OrderItemComponent,
    V2AuthComponent,
    V2CartComponent,
    CartItemUpdateComponent,
    V2HomeComponent,
    ProductModalOnlyComponent,
    LocationRequiredComponent,
  ],
  imports: [
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    BrowserModule,
    NgxWebstorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    LSelect2Module,
    FormsModule, 
    ReactiveFormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatDialogModule,
    NgSelectModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingHttpInterceptor, multi: true },
    { provide: MatDialogRef, useValue: {} },
    MessagingService,
    AsyncPipe
    // LoadingService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
