<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Blog' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Blog' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <section class="blog-area pt-100 pb-70">
        <div class="container">
            <div class="row" *ngIf="blogs">
                <div class="col-sm-12 col-lg-4" *ngFor="let blog of blogs">
                    <app-blog-item [blog]="blog"></app-blog-item>
                </div>  

                <div class="col-md-12">
                    <div class="collection-area" style="margin-top: 35px;">
                        <div class="more-collection">
                            <a href="javascript:void(0)" (click)="previousPage()" *ngIf="page > 1" class="text-danger">{{ 'Previous' | translate }}</a>
    
                            <a href="javascript:void(0)" (click)="nextPage()" *ngIf="has_more == '1'">{{ 'Load More' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!blogs">
                <div class="col-md-12 pt-70 pb-70">
                    <mat-progress-spinner mode="indeterminate" style="margin: auto;"></mat-progress-spinner>
                </div>
            </div>
        </div>
    </section>

    <app-footer></app-footer>

</div>