import { GoogleMapComponent } from './../../includes/google-map/google-map.component';
import { Router } from '@angular/router';
import { BaseDataService } from './../../services/base-data.service';
import { ProfileService } from './../../services/profile.service';
import { Helpers } from './../../Helpers';
import { UserService } from './../../models/user.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Address } from 'src/app/types/all.types';
declare let $ : any;

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit, AfterViewInit {

  public types : any[] = [
    {
      id  : 'HOME',
      text: 'HOME',
    },
    {
      id  : 'WORK',
      text: 'WORK',
    },
    {
      id  : 'UNIVERSITY',
      text: 'UNIVERSITY',
    },
    {
      id  : 'OTHER',
      text: 'OTHER',
    }
  ];

  public page       : number = 1;

  public addresses  : Address[];

  public has_more   : number = 0;

  public address    : Address | any = {};

  public action_type: string = 'LIST';

  public error      : any;

  public errors     : any = [];
  
  public success    : any;

  public geoCoder   : any;

  public GPS        : any = {lat: '0', lng: '0'};

  public areas_page : number = 1;

  public areas      : any[] = [];

  public areas_loading: boolean = false;

  public has_more_areas : string = '0';

  public municipality   : string;

  public default_address_id: number;

  @ViewChild(GoogleMapComponent) ChildMap: GoogleMapComponent;

  constructor(public helpers: Helpers, private UserServiceStorage: UserService, private profileApi: ProfileService, private baseDataApi: BaseDataService, private router: Router) { }

  ngOnInit(): void {
    if (! this.UserServiceStorage.isLoggedIn()) {
      this.router.navigate(['/', this.helpers.localeObject.prefix]);
      return;
    }
    this.loadAddresses();
  }
  
  ngAfterViewInit(): void {
    
  }

  getGeoCoder() {
    if (! this.geoCoder) {
      this.geoCoder = new google.maps.Geocoder();
    }
    return this.geoCoder;
  }

  loadMore() {
    this.page++;
    this.loadAddresses();
  }

  loadAddresses() {
    this.profileApi.addressesList({
      page: this.page
    })
    .subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        this.has_more  = res['data']['more_available'];
        this.addresses = res['data']['addresses'];
        this.addresses.forEach((address) => {
          if (address.default) {
            this.default_address_id = address.id;
          }
        });
      }
    });
  }

  changeDefaultAddress(address) {
    this.profileApi.setAddressAsDefault({
      address_id: address.id
    })
    .pipe(take(1))
    .subscribe((res: any) => {
      if (res.message == 'success') {
        address.default = true;
        this.UserServiceStorage.user.address = address;
        this.UserServiceStorage.save(this.UserServiceStorage.user);
      }
    });
  }

  returnToList() {
    this.action_type = 'LIST';
  }

  addNewAddress(event) {
    // Helpers.getLocation();
    this.address['name']                = null;
    this.address['address_description'] = null;
    this.address['phone_number']        = this.UserServiceStorage.user.phone_number;
    this.address['email']               = this.UserServiceStorage.user.email;
    this.address['type']                = {id  : 'HOME', text: 'Home'};
    let self = this;
    Helpers.make('GoogleMaps', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCiBmR5kDnIiGGfDp6CHfIcnLsCSdu8w2c', {
      success: function() {
        Helpers.getLocation({
          success: function(lat, lng) {
            self.GPS = Helpers.locationObject || {lng: '0', lat: '0'};
            self.markerControll(null, {
              lat: self.GPS.lng,
              lng: self.GPS.lat,
            });
          }
        });
      }
    });
    this.action_type = 'CREATE';
  }

  postAddNewAddress() {
    let data = this.address;
    data['type'] = this.address['type'] ? this.address['type']['id'] : null;
    this.profileApi.addNewAddress(data).subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        this.page = 1;
        this.loadAddresses();
        this.returnToList();
      }
      else {
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
    });
  }

  updateAddress(index, Address) {
    this.address['address_id']          = Address.id;
    this.address['title']               = Address.name;
    this.address['name']                = Address.name;
    this.address['address_description'] = Address.address_description;
    this.address['phone_number']        = Address.phone_number;
    this.address['email']               = Address.email;
    this.address['type']                = {id  : Address.type, text: Address.type};
    this.address['building']            = Address.building;
    this.address['apartment']           = Address.apartment;
    this.address['floor']               = Address.floor;
    this.address['country']      = Address.country;
    this.address['city']         = Address.city;
    this.address['municipality'] = Address.municipality;
    this.address['neighborhood'] = Address.neighborhood;
    this.address['postal_code']  = Address.postal;
    this.address['street_no']    = Address.street_no;
    this.address['street']       = Address.street;
    this.address['latitude']     = Number(Address.latitude).toFixed(20);
    this.address['longitude']    = Number(Address.longitude).toFixed(20);
    this.address['address']      = Address.address;
    var self = this;
    this.action_type = 'UPDATE';
  }

  postUpdateAddress() {
    let data = this.address;
    data['type']  = this.address['type'] ? this.address['type']['id'] : null;
    data['title'] = this.address['title'];
    data['name']  = this.address['title'];
    this.profileApi.updateAddressV2(data).subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'success') {
        if (res['data']['address']['default']) {
          this.UserServiceStorage.user.address = res['data']['address'];
          this.UserServiceStorage.save(this.UserServiceStorage.user);
        }
        this.page = 1;
        this.loadAddresses();
        this.returnToList();
      }
      else {
        this.error  = ParsedResponse['global'];
        this.errors = ParsedResponse['validations'];
      }
    });
  }

  deleteAddress(index, Address) {
    if(confirm('Are You Sure You Want To Delete this Address?')) {
      this.profileApi.deleteAddress({
        address_id: Address.id
      })
      .subscribe((res) => {
        let ParsedResponse = Helpers.parseHttpResponse(res);
        if (res['message'] == 'success') {
          this.addresses.splice(index, 1);
        }
        else {
          this.error  = ParsedResponse['global'];
          this.errors = ParsedResponse['validations'];
        }
      });
    }
  }

  setType(type) {
    this.address['type'] = type;
  }

  private resetData() {
    this.error          = null;
    this.errors         = [];
    this.success        = null;
    this.action_type    = 'LIST';
    this.address        = {};
  }

  markerControll(data, latlng = null) {
    let self         = this;
    let latlngObject = (data && !latlng) ? { lat: data.event.latLng.lat(), lng: data.event.latLng.lng() } : { lat: latlng.lat, lng: latlng.lng };
    Helpers.getAddressFromLatLng(this.getGeoCoder(), {lat: latlngObject.lat, lng: latlngObject.lng}, {
      ready: function(status, results) {
        self.address['country']      = null;
        self.address['city']         = null;
        self.address['municipality'] = null;
        self.address['neighborhood'] = null;
        self.address['postal_code']  = null;
        self.address['street_no']    = null;
        self.address['street']       = null;
        self.address['latitude']     = null;
        self.address['longitude']    = null;
        self.address['address']      = null;
      },
      success: function(parsedAddress) {
        self.address['country']      = parsedAddress.country;
        self.address['city']         = parsedAddress.city;
        self.address['municipality'] = parsedAddress.municipality;
        self.address['neighborhood'] = parsedAddress.neighborhood;
        self.address['postal_code']  = parsedAddress.postal;
        self.address['street_no']    = parsedAddress.street_no;
        self.address['street']       = parsedAddress.street;
        self.address['latitude']     = Number(parsedAddress.lat).toFixed(20);
        self.address['longitude']    = Number(parsedAddress.lng).toFixed(20);
        self.address['address']      = parsedAddress.formated;
        self.checkArea(parsedAddress.city, parsedAddress.municipality, parsedAddress.neighborhood);
      },
      noResults: function(reason) {
        self.loadAreas();
        $('#openAreaSelector').click();
      },
      geocoderFailed: function(status) {
        console.log(status);
      }
    });
  }

  checkArea(city, municipality, neighborhood) {
    this.profileApi.getAreaPolygon({
      city_name         : city         || '--',
      municipality_name : municipality || '--',
      neighborhood_name : neighborhood || '--',
    })
    .pipe(take(1))
    .subscribe((res: any) => {
      if (res.message == 'fail') {
        this.loadAreas();
        $('#openAreaSelector').click();
      }
    });
  }

  onScrollToEnd() {
    if (this.has_more_areas == '1') {
      this.areas_page++;
      this.loadAreas(false);
    }
  }

  onSearch(event) {
    setTimeout(() => {
      this.loadAreas(true, event.term);
    }, 750);
  }

  selectArea(event) {
    this.address['municipality'] = event.municipality_name;
    this.address['neighborhood'] = event.neighborhood_name;
  }

  loadAreas(reset: boolean = true, search = '') {
    if (reset) {
      this.areas          = [];
      this.areas_page     = 1;
      this.has_more_areas = '0';
    }
    this.areas_loading = true;
    this.profileApi.loadAreaSelector({
      search           : search || '',
      page             : this.areas_page,
      city_name        : this.address.city,
      municipality_name: this.address.municipality
    })
    .pipe(take(1))
    .subscribe((res: any) => {
      this.areas_loading = false;
      if (res.message == 'success') {
        this.areas          = res.data.area_information;
        this.has_more_areas = res.data.more_available;
      }
    });
  }
}
