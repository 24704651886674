<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'Meals' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'Meals' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <div class="header-filter">
                    <div class="header-filter-item">
                        <div class="header-filter-title">
                            {{ 'Keyword' | translate }}
                        </div>
                        <div class="header-filter-body">
                            <div class="form-group search">
                                <input type="text" [(ngModel)]="keyword" placeholder="{{ 'Keyword' | translate }}" class="form-control" (change)="keyword=$event.target.value">
                                <button type="button" class="btn search-btn" (click)="keywordSearch()"><i class='bx bxs-search'></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="header-filter-item">
                        <div class="header-filter-title">
                            {{ 'Categories' | translate }}
                        </div>
                        <div class="header-filter-body">
                            <div class="form-group search" *ngIf="index">
                                <app-select2-transformer [data]="index.data.categories" [options]="{}" [disabled]="false" (onValueChangeHandler)="onValueChangeHandlerFun($event)"></app-select2-transformer>
                            </div>
                        </div>
                    </div>

                    <div class="header-filter-item">
                        <div class="header-filter-title">
                            {{ 'Filter' | translate }}
                        </div>
                        <div class="header-filter-body">
                            <div class="btn-actions row">
                                <div class="col-md-6">
                                    <button class="btn btn-sm btn-block btn-outline-success" (click)="search()">{{ 'Search' | translate }}</button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-sm btn-block btn-outline-danger" (click)="reset()">{{ 'Reset' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="services-details-area pb-70">
        <div class="container">
            <div class="row">

                <div class="col-md-12" *ngIf="!hasLatLng">
                    <app-location-permission-alert></app-location-permission-alert>
                </div>

                <!-- <div class="col-lg-3">
                    <div class="services-details-item">
                        <div class="services-details-more">
                            
                            <h3>{{ 'Filter' | translate }}</h3>
                            
                            <div class="form-group search">
                                <input type="text" placeholder="{{ 'Keyword' | translate }}" class="form-control" (change)="keyword=$event.target.value">
                                <button type="button" class="btn search-btn" (click)="keywordSearch()"><i class='bx bxs-search bx-flashing'></i></button>
                            </div>

                            <div class="sidebar-card">
                                <div class="sidebar-card-head">{{ 'Categories' | translate }}</div>
                                <div class="sidebar-card-body" *ngIf="index">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="category_all" value="">
                                        <label class="form-check-label" for="category_all">ALL</label>
                                    </div>
                                    <div class="form-check form-check-inline" *ngFor="let category of index.data.categories">
                                        <input class="form-check-input" type="checkbox" id="category_{{category.id}}" value="{{category.id}}">
                                        <label class="form-check-label" for="category_{{category.id}}">{{category.title}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="sidebar-card">
                                <div class="sidebar-card-head">{{ 'City' | translate }}</div>
                                <div class="sidebar-card-body">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                        <label class="form-check-label" for="inlineCheckbox1">1</label>
                                    </div>
                                    <br>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                        <label class="form-check-label" for="inlineCheckbox2">2</label>
                                    </div>
                                    <br>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" disabled>
                                        <label class="form-check-label" for="inlineCheckbox3">3 (disabled)</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
    
                <div class="col-lg-12" *ngIf="meals">
                    <div class="row">
                        <div class="col-sm-12 col-lg-3 mix web ui" *ngFor="let dish of meals.data.dishes">
                            <app-product-item [item]="dish"></app-product-item>
                        </div>
                        <div class="col-lg-12" *ngIf="meals.data.dishes.length == 0">
                            <h3 class="text-center">{{ 'No Results' | translate }}</h3>
                        </div>
                    </div>
                    <div class="collection-area">
                        <div class="more-collection">
                            <a href="javascript:void(0)" (click)="previousPage()" *ngIf="page > 1" class="text-danger">{{ 'Previous' | translate }}</a>

                            <a href="javascript:void(0)" (click)="nextPage()" *ngIf="has_more == '1'">{{ 'Load More' | translate }}</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="!meals">
                    <mat-progress-spinner mode="indeterminate" style="margin: auto;"></mat-progress-spinner>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>