import { AfterViewInit } from '@angular/core';
// import { Helpers } from './Helpers';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorage } from 'ngx-webstorage';
import { Location, LocationStrategy } from '@angular/common';
declare let $: any;

@Injectable({
    providedIn: 'root'
})

export class Helpers implements AfterViewInit {

    static scriptsStacks : any = {};

    static scripts   : any = {};

    static geocoder  : any;

    private baseUrl  : string = '';

    private locale   : string;

    public static location;

    @LocalStorage('location') static locationObject;

    public static locationAttempts = 0;

    private defaultLocale: string = 'ar';

    private supportedLocales = {
        ar: { prefix: 'ar', dir: 'rtl' },
        en: { prefix: 'en', dir: 'ltr' },
    };

    public localeObject = {
        prefix: 'ar', dir: 'rtl'
    };

    public static staticLocaleObject = {
        prefix: 'ar', dir: 'rtl'
    };

    @LocalStorage('latlng') latlng;

    constructor(private router: Router, private translate: TranslateService, private http: HttpClient, private location: Location, private activatedRoute: ActivatedRoute, private locationStrategy: LocationStrategy) {

        Helpers.location = new Subject<any>();

        if (!Helpers.locationObject) {
            Helpers.locationObject = {
                lat: 0,
                lng: 0,
            };
        }

        // if (! this.latlng) {
        //     Helpers.getLocation();
        //     this.latlng = {
        //         lat: Helpers.locationObject.lat,
        //         lng: Helpers.locationObject.lng,
        //     };
        // }
        
        var segments = this.router.parseUrl(this.router.url).root.children['primary'].segments;
        this.locale = segments[0].path;
        if (! this.supportedLocales[this.locale]) {
            this.locale = this.defaultLocale;
        }
        this.translate.setDefaultLang(this.locale);
        this.localeObject = this.supportedLocales[this.locale];
        Helpers.staticLocaleObject = this.supportedLocales[this.locale];
    }

    ngAfterViewInit(): void {
        
    }

    getLocale(){
        return this.locale || this.defaultLocale;
    }

    getDir(){
        console.log(this.supportedLocales[this.locale].dir);
        return this.supportedLocales[this.locale].dir;
    }

    useLanguage(language: string) {
        this.localeObject = this.supportedLocales[language];
        Helpers.staticLocaleObject = this.supportedLocales[language];
        this.translate.use(language);
        var segments = this.locationStrategy.path().split('/');
        segments.splice(0, 1);
        var newSegments = [];
        segments.forEach((item, key) => {
            if (key == 0) {
                newSegments.push( language );
            }
            else {
                newSegments.push( item );
            }
        });
        var url = this.router.createUrlTree(newSegments, {relativeTo: this.activatedRoute, queryParams: {}}).toString();
        this.location.go(url);
        window.location.reload();
    }

    number(int: number) {
        return Number(int);
    }

    public static getLocation(handeler?, auto?): void
    {
        handeler            = handeler            ? handeler             : {};
        handeler['ready']   = handeler['ready']   ? handeler['ready']    : function()         {};
        handeler['success'] = handeler['success'] ? handeler['success']  : function(lat, lng) {};
        handeler['fail']    = handeler['fail']    ? handeler['fail']     : function(error)    {};
        handeler['denied']  = handeler['denied']  ? handeler['denied']   : function()         {};
        handeler['loading'] = handeler['loading'] ? handeler['loading']  : function()         {};
        handeler['loading'].apply();

        if (!auto && Helpers.locationObject) {
            if (Helpers.locationObject.lat && Helpers.locationObject.lng) {
                handeler['ready'].apply(null);
                // Helpers.setPostion(Helpers.locationObject.lat, Helpers.locationObject.lng, handeler);
                handeler['success'].apply(null, [Helpers.locationObject.lat, Helpers.locationObject.lng]);
                return;
            }
        }

        navigator.permissions.query({
            name: 'geolocation'
        })
        .then(function(result) {
            handeler['ready'].apply(result);
            Helpers.getCurrentPositionPromise(result, handeler);
            result.onchange = function() {
                Helpers.getCurrentPositionPromise(result, handeler);
            }
        });
    }

    public static getCurrentPositionPromise(result, handeler?)
    {
        if (result.state == 'granted') {
            navigator.geolocation.getCurrentPosition(
                (resp) => {
                    Helpers.setPostion(resp.coords.latitude, resp.coords.longitude);
                    if (handeler) { handeler['success'].apply(resp, [resp.coords.latitude, resp.coords.longitude]); }
                },
                (err) => {
                    if (handeler) { handeler['fail'].apply(result, [err]); }
                }
            );
        }
        else if (result.state == 'prompt') {
            navigator.geolocation.getCurrentPosition(
                (resp) => {
                    Helpers.setPostion(resp.coords.latitude, resp.coords.longitude);
                    if (handeler) { handeler['success'].apply(result, [resp.coords.latitude, resp.coords.longitude]); }
                },
                (err) => {
                    Helpers.setPostion(null, null);
                    if (handeler) { handeler['fail'].apply(result, [err]); }
                }
            );
        } 
        else if (result.state == 'denied') {
            Helpers.setPostion(null, null);
            if (handeler) { handeler['denied'].apply(result); }
        }
    }

    public static setPostion(lat, lng, handeler?)
    {
        // if (Helpers.locationAttempts > 0) {
        //     return;
        // }
        Helpers.locationAttempts++;
        Helpers.location.next({
            lat: lng,
            lng: lat,
        });
        Helpers.locationAttempts--;

        Helpers.locationObject = {
            lat: lng,
            lng: lat,
        };
    }

    public static calculateTotalMealPrice(MealWithAttributes, Coupon?) : number
    {
        var total : number = 0;
        if (!MealWithAttributes.attributes) {
            MealWithAttributes.attributes = [];
        }
        MealWithAttributes.attributes.forEach((attribute, key) => {
            if(! attribute) {
                delete MealWithAttributes.attributes[key];
            }
            else {
                attribute.forEach(element => {
                    total = total + (! element.status && element.price != null ? Number(element.price) : 0)
                });
            }
        });
        MealWithAttributes.attributes_price = total;
        total = total + Number(MealWithAttributes.single_price);
        total = total * Number(MealWithAttributes.quantity);
        return total;
    }

    public static parseHttpResponse(res)
    {
        let response = {
            success     : false,
            result      : '',
            global      : '',
            validations : [],
        };
        if (res['message'] == 'fail') {
            response['global'] = res['errors']['global'] || 'validation_error';
            if (response['global'] == 'validation_error' || response['global'] == '') {
                Object.keys(res['errors']).forEach((key) => {
                    if (key != 'global') {
                        response['validations'].push({
                            name  : key,
                            error : res['errors'][key]
                        });
                    }
                });
            }
        }
        else if (res['message'] == 'success') {
            response['success'] = true;
            response['result']  = res['data']['result'];
        }
        return response;
    }

    public static loadAddressFromApiByLatLng(GeoCoder, GPS, actions: any) {
        GeoCoder.geocode({location: {lat: parseFloat(GPS.lat), lng: parseFloat(GPS.lng)}}, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
            actions['ready'].apply({GeoCoder, GPS}, [status, results]);
            if (status === "OK") {
                if (results[0]) {
                    actions['success'].apply({GeoCoder, GPS}, [ Helpers.parseGoogleAddressObject(GPS, results[0]) ]);
                } 
                else {
                    actions['noResults'].apply({GeoCoder, GPS});
                    actions['fail'].apply({GeoCoder, GPS}, ['NO_RESULTS']);
                }
            } 
            else {
                actions['geocoderFailed'].apply({GeoCoder, GPS}, [status]);
                actions['fail'].apply({GeoCoder, GPS}, ['GEO_CODER_ERROR']);
            }
        });
    }

    public static getAddressFromLatLng(GeoCoder, GPS, actions = {}) {
        actions['ready']          = actions['ready']     || function(status, results){};
        actions['success']        = actions['success']   || function(parsedObject){};
        actions['fail']           = actions['fail']      || function(reason) {};
        actions['noResults']      = actions['noResults'] || function() {};
        actions['geocoderFailed'] = actions['noResults'] || function(status) {};
        Helpers.loadAddressFromApiByLatLng(GeoCoder, GPS, actions);
    }

    public static parseGoogleAddressObject(GPS, data) {
        let parsedData = {
            country      : null,
            city         : null,
            municipality : null,
            neighborhood : null,
            postal       : null,
            street_no    : null,
            street       : null,
            building     : null,
            floor        : null,
            apartment    : null,
            lat          : parseFloat(GPS.lat),
            lng          : parseFloat(GPS.lng),
            formated     : '',
        };
        data['address_components'].forEach((element, key) => {
            if (element['types'][0] == 'street_number') {
                parsedData['street_no'] = element['long_name'];
            }
            if (element['types'][0] == 'route') {
                parsedData['street'] = element['long_name'];
            }
            if (element['types'][0] == 'postal_code') {
                parsedData['postal'] = element['long_name'];
            }
            if (element['types'][0] == 'administrative_area_level_4') {
                parsedData['neighborhood'] = element['long_name'];
            }
            if (element['types'][0] == 'administrative_area_level_2') {
                parsedData['municipality'] = element['long_name'];
            }
            if (element['types'][0] == 'administrative_area_level_1') {
                parsedData['city'] = element['long_name'];
            }
            if (element['types'][0] == 'country') {
                parsedData['country'] = element['long_name'];
            }
        });
        parsedData['formated'] = data['formatted_address'];
        return parsedData;
    }

    public static make(script: string, path: string, handeler = {}) : void {
        if (! Helpers.scriptsStacks[ script ]) {
            Helpers.scriptsStacks[ script ] = [];
        }
        handeler['success'] = handeler['success'] ? handeler['success']  : function() {};
        handeler['fail']    = handeler['fail']    ? handeler['fail']     : function() {};
        handeler['loading'] = handeler['loading'] ? handeler['loading']  : function() {};
        Helpers.scriptsStacks[ script ].push(handeler);
        Helpers.scriptsStacks[ script ].forEach((element) => {
            element['loading'].apply();
        });
        if (Helpers.scripts[ script ] == 'LOADED') {
            Helpers.scriptsStacks[ script ].forEach((element, key) => {
                element['success'].apply();

                delete Helpers.scriptsStacks[ script ][key];
            });
            return;
        }
        if (Helpers.scripts[ script ] == 'LOADING') {
            return;
        }
        // console.log(script + ' Loading.......');
        Helpers.scripts[ script ] = 'LOADING';
        $.getScript(path).then(() => {
            Helpers.scripts[ script ] = 'LOADED';
            // console.log(script + ' Loaded First Time');
            Helpers.scriptsStacks[ script ].forEach((element, key) => {
                element['success'].apply();

                delete Helpers.scriptsStacks[ script ][key];
            });
        });
    }

    firstElemFromArray(array) {
        var dd = array.slice(0,1).shift();
        return dd;
    }
}