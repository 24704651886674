<div class="{{ helpers.localeObject.dir }} dir-{{ helpers.localeObject.dir }}" dir="{{ helpers.localeObject.dir }}">

    <app-navbar></app-navbar>

    <div class="page-title-area page-title-img-two">
        <div class="container">
            <div class="page-title-item">
                <h2>{{ 'My Addresses' | translate }}</h2>
                <ul>
                    <li>
                        <a [routerLink]="['/', helpers.localeObject.prefix]">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <i class='bx bx-chevron-right'></i>
                    </li>
                    <li>
                        {{ 'My Addresses' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>  

    <section class="cart-area pb-70 pt-70">
        <div class="container">
            
            <div class="cart-wrap addresses" *ngIf="action_type == 'LIST'">
                <table class="table">
                    <thead class="thead">
                        <tr>
                            <th>#</th>
                            <th class="table-head" scope="col">{{ 'Contact' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Name' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Type' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Address' | translate }}</th>
                            <th class="table-head" scope="col">{{ 'Actions' | translate }}</th>
                        </tr>
                    </thead>
    
                    <tbody *ngIf="addresses">
                        <tr *ngFor="let item of addresses;index as itemIndex;">
                            <td>
                                <input type="radio" name="default_address" value="{{item.id}}" [(ngModel)]="default_address_id" (change)="changeDefaultAddress(item)">
                            </td>
                            <td>
                                <span dir="ltr">{{ item.phone_number }}</span>
                                <span><br> {{ item.email }}</span> 
                            </td>
                            <td>{{ item.name  || '---------' }}</td>
                            <td>{{ 'addresses.types.' + (item.type || 'HOME') | translate }}</td>
                            <td>{{ item.address || '---------' }}</td>
                            <td>
                                <a href="javascript:;" (click)="updateAddress(itemIndex, item)"><i class='bx bx-edit'></i></a>
                                <a href="javascript:;" (click)="deleteAddress(itemIndex, item)"><i class='bx bx-x'></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>  
            </div>
            <div class="row" style="padding-top: 20px; padding-bottom: 20px" *ngIf="action_type == 'LIST'">
                <div class="col-md-4">
                    <button class="btn btn-block btn-warning" style="color: #fff;" (click)="addNewAddress($event)">
                        <strong>{{ 'Add New' | translate }}</strong>
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12" *ngIf="action_type == 'CREATE'">
                    <div class="book-table-area">
                        <div class="book-table-wrap">
                            <div class="section-title" style="margin-bottom: 20px;">
                                <h2 class="text-warning">{{ 'Add New' | translate }}</h2>
                                <p>{{ 'Enter Address Details' | translate }}</p>
                                <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
                                <app-alert [type]="'success'" [title]="success"></app-alert>
                            </div>
                            <form (ngSubmit)="postAddNewAddress()">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Name' | translate }}" name="name" [(ngModel)]="address.name" required="">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <app-select2-transformer id="address_type_select" [data]="types" [options]="{multiple: false}" [disabled]="false" (onValueChangeHandler)="setType($event)"></app-select2-transformer>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input dir="ltr" type="text" class="form-control" placeholder="{{ 'Phone Number' | translate }}" name="phone_number" [(ngModel)]="address.phone_number">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Email' | translate }}" name="email" [(ngModel)]="address.email">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Description' | translate }}" name="description" [(ngModel)]="address.address_description">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <app-google-map [elemId]="'address_map_div'" [zoom]="14" [lat]="GPS.lng" [lng]="GPS.lat" [DefaultMarkerLat]="GPS.lng" [DefaultMarkerLng]="GPS.lat" (onAddMarker)="markerControll($event)"></app-google-map>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>
                                                {{ 'Building' | translate }}
                                            </label>
                                            <input type="text" class="form-control" placeholder="{{ 'Building' | translate }}" name="building" [(ngModel)]="address.building">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>
                                                {{ 'Floor' | translate }}
                                            </label>
                                            <input type="text" class="form-control" placeholder="{{ 'Floor' | translate }}" name="floor" [(ngModel)]="address.floor">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>
                                                {{ 'Apartment' | translate }}
                                            </label>
                                            <input type="text" class="form-control" placeholder="{{ 'Apartment' | translate }}" name="apartment" [(ngModel)]="address.apartment">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>
                                                {{ 'Address' | translate }} <small class="text-danger">{{ 'You Can Select From The Map To Get Tha Address Automatically' | translate }}</small>
                                            </label>
                                            <textarea type="text" class="form-control" placeholder="{{ 'Address' | translate }}" name="full_addrss" [(ngModel)]="address.address"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button type="submit" class="btn btn-warning btn-block">
                                                <strong>{{ 'Save' | translate }}</strong>
                                            </button>
                                        </div>
                                        <div class="col-md-6">
                                            <button type="button" class="btn btn-outline-danger btn-block" (click)="returnToList()">
                                                <strong>{{ 'Back' | translate }}</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" *ngIf="action_type == 'UPDATE'">
                    <div class="book-table-area">
                        <div class="book-table-wrap">
                            <div class="section-title" style="margin-bottom: 20px;">
                                <h2 class="text-warning">{{ 'Update' | translate }}</h2>
                                <p>{{ 'Enter Address Details' | translate }}</p>
                                <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
                                <app-alert [type]="'success'" [title]="success"></app-alert>
                            </div>
                            <form (ngSubmit)="postUpdateAddress()">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Name' | translate }}" name="title" [(ngModel)]="address.title" required="">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <app-select2-transformer id="address_type_select" [data]="types" [options]="{multiple: false}" [disabled]="false" (onValueChangeHandler)="setType($event)"></app-select2-transformer>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input dir="ltr" type="text" class="form-control" placeholder="{{ 'Phone Number' | translate }}" name="phone_number" [(ngModel)]="address.phone_number">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Email' | translate }}" name="email" [(ngModel)]="address.email">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="{{ 'Description' | translate }}" name="description" [(ngModel)]="address.address_description">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <app-google-map [elemId]="'address_update_map_div'" [zoom]="14" [lat]="address.latitude" [lng]="address.longitude" [DefaultMarkerLat]="address.latitude" [DefaultMarkerLng]="address.longitude" (onAddMarker)="markerControll($event)"></app-google-map>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>
                                                {{ 'Building' | translate }}
                                            </label>
                                            <input type="text" class="form-control" placeholder="{{ 'Building' | translate }}" name="building" [(ngModel)]="address.building">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>
                                                {{ 'Floor' | translate }}
                                            </label>
                                            <input type="text" class="form-control" placeholder="{{ 'Floor' | translate }}" name="floor" [(ngModel)]="address.floor">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>
                                                {{ 'Apartment' | translate }}
                                            </label>
                                            <input type="text" class="form-control" placeholder="{{ 'Apartment' | translate }}" name="apartment" [(ngModel)]="address.apartment">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>
                                                {{ 'Address' | translate }} <small class="text-danger">{{ 'You Can Select From The Map To Get Tha Address Automatically' | translate }}</small>
                                            </label>
                                            <textarea type="text" class="form-control" placeholder="{{ 'Address' | translate }}" name="full_addrss" [(ngModel)]="address.address"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button type="submit" class="btn btn-warning btn-block">
                                                <strong>{{ 'Save' | translate }}</strong>
                                            </button>
                                        </div>
                                        <div class="col-md-6">
                                            <button type="button" class="btn btn-outline-danger btn-block" (click)="returnToList()">
                                                <strong>{{ 'Back' | translate }}</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <button data-toggle="modal" href="javascript:;" [attr.data-target]="'#address-area-selector-modal'" style="display: none;" id="openAreaSelector">
                    MODAL
                </button>
                <div class="modal fade" tabindex="-1" role="dialog" [attr.id]="'address-area-selector-modal'">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">{{ 'Address Details' | translate }}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" style="padding: 2rem;">
                                <div class="form-group">
                                    <label>{{ 'Areas' | translate }}</label>
                                    <ng-select 
                                        class="form-controll"
                                        [items]="areas"
                                        [virtualScroll]="true"
                                        [loading]="areas_loading"
                                        bindLabel="text"
                                        bindValue="neighborhood_id"
                                        placeholder="{{ 'Select Area' | translate }}"
                                        appendTo="body"
                                        (scrollToEnd)="onScrollToEnd()" 
                                        (search)="onSearch($event)" 
                                        matInput 
                                        (change)="selectArea($event)">
                                        <ng-template ng-header-tmp>
                                            <small class="form-text text-muted">Loaded {{areas.length}}</small>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            {{item.text}}
                                        </ng-template>
                                    </ng-select>
                        
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-warning" data-dismiss="modal">{{ 'Close' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <app-footer></app-footer>

</div>