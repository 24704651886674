<div class="book-table-area pt-70 pb-70">
    <div class="container">
        <div class="book-table-wrap" *ngIf="step == 1">
            <div class="section-title" style="margin-bottom: 20px;">
                <h2 class="text-warning">{{ 'Login' | translate }}</h2>
                <p>{{ 'Please Enter Your Mobile Number' | translate }}</p>
                
                <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
            </div>
            <form (ngSubmit)="stepOne()">
                <div class="row" dir="ltr">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <input type="number" class="form-control align-left" placeholder="{{ 'Country Code' | translate }}" name="country_code" [(ngModel)]="country_code" readonly="">
                        </div>
                    </div>

                    <div class="col-sm-9">
                        <div class="form-group">
                            <input type="text" class="form-control align-left" placeholder="{{ 'Phone Number' | translate }}" name="phone_number" [(ngModel)]="phone_number" maxlength="10">
                        </div>
                    </div>            
                </div>

                <div class="text-center">
                    <div class="row">
                        <div class="col-md-12">
                            <button type="submit" class="btn btn-warning btn-block">
                                <strong>{{ 'Next' | translate }}</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="book-table-wrap" *ngIf="step == 2">
            <div class="section-title" style="margin-bottom: 20px;">
                <h2 class="text-warning">{{ 'Verification Code' | translate }}</h2>
                <p>{{ 'Please Enter Verification Code Sent To Your Number' | translate }}</p>
                <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
            </div>
            <form (ngSubmit)="stepTwo()">
                <div class="row" dir="ltr">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="{{ 'Code' | translate }}" name="code" [(ngModel)]="code" maxlength="4">
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <div class="row">
                        <div class="col-md-6">
                            <button type="submit" class="btn btn-warning btn-block">
                                <strong>{{ 'Next' | translate }}</strong>
                            </button>
                        </div>
                        <div class="col-md-6">
                            <button type="button" class="btn btn-outline-danger btn-block" (click)="returnBack()">
                                <strong>{{ 'Back' | translate }}</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="book-table-wrap" *ngIf="step == 3">
            <div class="section-title" style="margin-bottom: 20px;">
                <h2 class="text-warning">{{ 'Welcome' | translate }}</h2>
                <p>{{ 'Enter Your Personal Details' | translate }}</p>
                <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
            </div>
            <form (ngSubmit)="stepThree()">
                <div class="row" dir="ltr">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="{{ 'Full Name' | translate }}" name="full_name" [(ngModel)]="full_name">
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="{{ 'Email' | translate }}" name="email" [(ngModel)]="email">
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="{{ 'Password' | translate }}" name="password" [(ngModel)]="password">
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <div class="row">
                        <div class="col-md-6">
                            <button type="submit" class="btn btn-warning btn-block">
                                <strong>{{ 'Next' | translate }}</strong>
                            </button>
                        </div>
                        <div class="col-md-6">
                            <button type="button" class="btn btn-outline-danger btn-block" (click)="returnBack()">
                                <strong>{{ 'Back' | translate }}</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="book-table-wrap" *ngIf="step == 4">
            <div class="section-title" style="margin-bottom: 20px;">
                <h2 class="text-warning">{{ 'Welcome' | translate }}</h2>
                <p>{{ 'Enter Your Password' | translate }}</p>
                <app-validation-alert [error]="error" [errors]="errors"></app-validation-alert>
            </div>
            <form (ngSubmit)="stepFour()">
                <div class="row" dir="ltr">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="{{ 'Password' | translate }}" name="password" [(ngModel)]="password">
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <div class="row">
                        <div class="col-md-6">
                            <button type="submit" class="btn btn-warning btn-block">
                                <strong>{{ 'Next' | translate }}</strong>
                            </button>
                        </div>
                        <div class="col-md-6">
                            <button type="button" class="btn btn-outline-danger btn-block" (click)="returnBack(1)">
                                <strong>{{ 'Back' | translate }}</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>