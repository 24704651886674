import { environment } from './../../environments/environment';
import { Router, UrlSerializer } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MealDetailsService {

  constructor(private http: HttpClient, private router: Router, private serializer: UrlSerializer) { }

  loadData(data: any = {}) {
    data = ! data ? {} : data;
    let params = this.router.createUrlTree([''], { queryParams: data });
    let urlString = this.serializer.serialize(params).substring(1);
    return this.http.get( environment.API_BASE_URL + '/dishes/show' + urlString);
  }

  addToFavorite(data: any) {
    data = ! data ? {} : data;
    let params = this.router.createUrlTree([''], { queryParams: data });
    let urlString = this.serializer.serialize(params).substring(1);
    return this.http.get( environment.API_BASE_URL + '/dishes/show' + urlString);
  }
}
