import { MessagingService } from './../../messaging.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ProfileService } from './../../services/profile.service';
import { UserService } from './../../models/user.service';
import { CartService } from './../../models/cart.service';
import { Helpers } from './../../Helpers';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit {

  public orders     : any;

  public page       : number = 1;

  public has_more   : string = '0';

  constructor(private messagingService: MessagingService, public helpers: Helpers, private UserServiceStorage: UserService, private profileApi: ProfileService, private router: Router, private readonly translateService: TranslateService) { }

  ngOnInit(): void {
    this.loadOrders();
    var vm = this;
    this.messagingService.addListener('ORDER_LIST', function(msg) {
      vm.loadOrders();
    });
  }

  nextPage() {
    this.page++;
    this.loadOrders();
  }

  previousPage() {
    this.page--;
    this.loadOrders();
  }

  loadOrders() {
    let params = {
      page      : this.page,
    };
    this.profileApi.listOrders(params).subscribe((res) => {
      let ParsedResponse = Helpers.parseHttpResponse(res);
      if (res['message'] == 'fail') {
        alert('No Internet');
      }
      else if (res['message'] == 'success') {
        this.orders   = res['data']['orders'];
        this.has_more = res['data']['more_available'];
      }
    });
  }
}
